import { makeAutoObservable, runInAction } from 'mobx'
import isEmail from 'validator/lib/isEmail'
import { REGISTER } from '../../util/routes'
import { subscribe, dispatch } from '../../services/pubsub'
import Actions from '../../util/actions'

class RegisterStore {
  doesUserRegistered = false
  token = ''
  username = ''
  displayName = ''
  displayNameErrorMessage = ''
  password = ''
  message = ''
  whenRegisterRouteUrl = ''
  registerOnProcess = false
  showPassword = false
  confirmPassword = ''
  passwordErrorMessage = ''
  credentialsError = ''
  usernameErrorMessage = ''
  confirmPasswordErrorMessage = ''
  emailFromInvitation = false

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)

    subscribe(REGISTER, ({ email }) => {
      runInAction(() => {
        if (email && isEmail(email)) {
          this.username = email
          this.emailFromInvitation = true
        }
      })
    })
  }

  doesCredentialsValid = () => {
    const { username, password, confirmPassword, displayName } = this
    if (!username || !isEmail(username)) {
      return {
        valid: false,
        usernameErrorMessage: 'Invalid email.',
      }
    }

    if (!displayName) {
      return {
        valid: false,
        displayNameErrorMessage: 'Missing Full Name',
      }
    } else if (!password || !`${password}`.trim()) {
      return {
        passwordErrorMessage: 'Invalid password.',
        valid: false,
      }
    } else if (password !== confirmPassword) {
      return {
        confirmPasswordErrorMessage: `Confirm password doesn't equal to password.`,
        valid: false,
      }
    }

    return { valid: true }
  }

  onSignUp = () => {
    this.confirmPasswordErrorMessage = ''
    this.passwordErrorMessage = ''
    this.usernameErrorMessage = ''
    this.displayNameErrorMessage = ''

    const {
      valid,
      confirmPasswordErrorMessage,
      passwordErrorMessage,
      usernameErrorMessage,
      displayNameErrorMessage,
    } = this.doesCredentialsValid()
    if (!valid) {
      runInAction(() => {
        this.confirmPasswordErrorMessage = confirmPasswordErrorMessage
        this.passwordErrorMessage = passwordErrorMessage
        this.usernameErrorMessage = usernameErrorMessage
        this.displayNameErrorMessage = displayNameErrorMessage
      })
      return
    }
    this.register()
  }

  register = () => {
    const { username, password, displayName } = this
    runInAction(() => {
      this.registerOnProcess = true
    })
    this.rootStore.apis
      .register({ username, password, displayName })
      .then(async (user) => {
        if (!user) {
          throw new Error('Register failed!')
        }
        const token = await user.getIdToken(false)
        this.rootStore.authenticationStore.storeUserDetails({
          username: user.email,
          token,
          refreshToken: user.refreshToken,
          isAnonymous: false,
        })

        this.rootStore.apis.registerUser({ token }).then((res) => {
          this.rootStore.authenticationStore.getUserState()
          runInAction(() => {
            this.username = ''
            this.displayName = ''
            this.password = ''
            this.confirmPassword = ''
            this.registerOnProcess = false
            this.doesUserRegistered = true
            this.emailFromInvitation = false
            dispatch(Actions.GET_CURRENT_USER)
          })
        })
      })
      .catch((error) => {
        console.error(error)
        runInAction(() => {
          console.log('register store register')
          console.log({ error })
          if (error.code === 'auth/email-already-in-use') {
            this.message =
              "There's already a user under this email address. Try logging in instead using the button below."
          } else {
            this.message = error.message || 'Login failed!'
          }
          this.registerOnProcess = false
        })
      })
  }

  credentialChange = ({ username, password, confirmPassword, displayName }) => {
    runInAction(() => {
      this.username = username
      this.password = password
      this.confirmPassword = confirmPassword
      this.displayName = displayName
    })
  }

  toggleShowPassword = () => {
    runInAction(() => {
      this.showPassword = !this.showPassword
    })
  }
}

export default RegisterStore
