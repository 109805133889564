import React, { useState, useEffect } from 'react'
import './upload-wizard.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans, Plural } from '@lingui/macro'

import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Upload from './Upload/Upload.js'
import UploadTagging from './UploadTagging/UploadTagging.js'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CircularProgress from '@mui/material/CircularProgress'
import success from '../../assets/success-check-circled.svg'
import { MyTabs, MyTab, MyButton } from '../_MyComponents'
import ActionDialog from '../ActionDialog/ActionDialog.js'
import { useDropzone } from 'react-dropzone'
import { useHistory, useLocation } from 'react-router-dom'
import { update } from '@intercom/messenger-js-sdk'

const UploadWizard = observer(
  ({
    // from parent
    type,
    isOpen,
    setDialog,
    currentCollection,
  }) => {
    const { filesStore, orgsWorkspacesStore } = useStore()

    const {
      filesInQ,
      isUpload,
      closeUpload,
      getAllTags,
      getAllPersons,
      uploadQIndex,
      analysisQIndex,
      isUploadFinished,
      bulkAddCollections,
      recentlyUploadedFilesIds,
      updateFilters,
      getAllFilesUsers,
      getAllFilesExtensions,
      uploadToS3,
      bulkTag,
    } = filesStore

    const { getOrgLimits } = orgsWorkspacesStore

    const [menuState, setMenuState] = useState(0)
    const [showCloseDialog, setShowCloseDialog] = useState(false)
    const [appliedTags, setAppliedTags] = useState([])
    const [appliedCollections, setAppliedCollections] = useState([])
    const location = useLocation()
    const historyDOM = useHistory()

    useEffect(() => {
      // add uploader query param
      const queryParams = new URLSearchParams(location.search)
      if (!queryParams.has('uploader')) {
        queryParams.append('uploader', 'open')
        historyDOM.replace({
          search: queryParams.toString(),
        })
        update('last_request_at', Date.now())
      }

      // check if current collection is set

      if (!!currentCollection) {
        setAppliedCollections([currentCollection])
      }
    }, [])

    // if files are being uploaded, show tagging screen, else show empty upload screen
    useEffect(() => {
      if (filesInQ.length) {
        setMenuState(1)
      } else {
        setMenuState(0)
      }
    }, [filesInQ.length])

    const onDrop = (acceptedFiles) => {
      uploadToS3(acceptedFiles)
      setMenuState(1)
    }

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
      onDrop,
    })

    const handleClose = async () => {
      // remove uploader query param
      const queryParams = new URLSearchParams(location.search)
      if (queryParams.has('uploader')) {
        queryParams.delete('uploader')
        historyDOM.replace({
          search: queryParams.toString(),
        })
        update('last_request_at', Date.now())
      }

      closeUpload()
      setDialog(false)

      const filesContainer = document.querySelector('.files-container')
      if (filesContainer) {
        filesContainer.scrollTop = 0
      }

      updateFilters()
      getAllTags({})
      getAllFilesUsers({})
      getAllFilesExtensions({})
      getAllPersons({})
      getOrgLimits()
    }

    const tagAllFiles = () => {
      bulkTag({
        fileIds: recentlyUploadedFilesIds,
        tags: appliedTags,
        clearExisting: false,
      })
    }

    const addCollectionsToAllFiles = () => {
      bulkAddCollections({
        fileIds: recentlyUploadedFilesIds,
        collectionsIds: appliedCollections.map((col) => col._id),
      })
    }

    const nextStep = async () => {
      switch (menuState) {
        case 0:
          await getAllTags({})
          setMenuState(menuState + 1)
          break
        case 1:
          handleClose()
          tagAllFiles()
          addCollectionsToAllFiles()
          break
        default:
          break
      }
    }

    return (
      <Dialog
        onClose={handleClose}
        fullScreen
        //maxWidth={"lg"}
        aria-labelledby="simple-dialog-title"
        open={!!isOpen}
        className="upload-wizard-dialog"
      >
        <div className="wizard-header">
          <MyTabs
            indicatorColor="primary"
            variant="scrollable"
            value={menuState}
          >
            <MyTab label={t`1. Upload`} /* onClick={() => setMenuState(0)} */ />
            <MyTab label={t`2. Tag`} /* onClick={() => setMenuState(1)} */ />
          </MyTabs>
          <IconButton
            className="close-wizard"
            onClick={() => setShowCloseDialog(true)}
            component="span"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div
          className="wizard-container"
          {...getRootProps({ onClick: (event) => event.stopPropagation() })}
        >
          <input {...getInputProps()} />
          <div className="wizard-content-container">
            {menuState === 0 && (
              <Upload type={type} setMenuState={setMenuState} />
            )}
            {menuState > 0 && (
              <UploadTagging
                appliedTags={appliedTags}
                setAppliedTags={setAppliedTags}
                appliedCollections={appliedCollections}
                setAppliedCollections={setAppliedCollections}
              />
            )}
          </div>
          <div className="button-container">
            {!!filesInQ.length && (
              <div className="upload-indicator">
                {!isUpload &&
                  !!filesInQ.filter(
                    (file) => file.status === 'complete' && !file.failed
                  ).length && <img src={success} alt="success" />}
                {isUpload && (
                  <CircularProgress style={{ color: '#FBC02D' }} size={20} />
                )}
                {!isUpload &&
                  (!!filesInQ.filter(
                    (file) =>
                      (file.status === 'complete' ||
                        file.status === 'duplicate') &&
                      !file.failed
                  ).length ? (
                    (() => {
                      const completedFiles = filesInQ.filter(
                        (file) => file.status === 'complete' && !file.failed
                      ).length
                      const duplicateFiles = filesInQ.filter(
                        (file) => file.status === 'duplicate' && !file.failed
                      ).length

                      if (duplicateFiles > 0) {
                        return (
                          <p className="p">
                            <Trans>
                              {`Successfully uploaded and analyzed ${completedFiles} of ${filesInQ.length} files. `}
                            </Trans>
                            {` `}
                            <Plural
                              value={duplicateFiles}
                              one={`1 duplicate file was found.`}
                              other={`# duplicate files were found.`}
                            />
                          </p>
                        )
                      } else {
                        return (
                          <p className="p">
                            <Trans>
                              {`Successfully uploaded and analyzed ${completedFiles} of ${filesInQ.length} files.`}
                            </Trans>
                          </p>
                        )
                      }
                    })()
                  ) : (
                    <p className="p">
                      <Trans>
                        {`Failed uploading all ${filesInQ.length} files`}
                      </Trans>
                    </p>
                  ))}
                {isUpload &&
                  (isUploadFinished ? (
                    <p className="p">
                      <Trans>
                        {`analyzing ${analysisQIndex}/${filesInQ.length} files`}
                      </Trans>
                    </p>
                  ) : (
                    <p className="p">
                      <Trans>
                        {`Uploading ${uploadQIndex}/${filesInQ.length} files`}
                      </Trans>
                    </p>
                  ))}
              </div>
            )}
            <MyButton
              endIcon={<ArrowForwardIcon />}
              variant="contained"
              color="primary"
              onClick={() => nextStep()}
              disabled={filesInQ.length < 1 || !isUploadFinished}
            >
              {menuState === 1 ? t`Finish upload` : t`Continue`}
            </MyButton>
          </div>
        </div>
        <ActionDialog
          actionName={t`Cancel`}
          cancelName={t`Close uploader`}
          cancel={() => handleClose()}
          action={() => setShowCloseDialog(false)}
          question={t`Close Upload?`}
          comments={[
            t`Closing the uploader might prevent some files from completing the upload process.`,
            t`We recommend completing the upload using the button at the bottom of the screen.`,
          ]}
          //color={'primary'}
          isOpen={showCloseDialog}
        />
      </Dialog>
    )
  }
)

export default UploadWizard
