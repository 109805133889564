export const URLS = {
  PRODUCTION_HOST: 'https://tagbox-api-server-backup-eu4fx.ondigitalocean.app',
  STAGING_HOST: 'https://tagbox-api-staging-pqco6.ondigitalocean.app',
  DEVELOPMENT_HOST: 'https://tagbox-api-dev-6x88d.ondigitalocean.app',
  LOCAL_HOST: 'https://tagbox-api-dev-6x88d.ondigitalocean.app',
  //LOCAL_HOST: 'http://localhost:3001',
}

export const SYNC_URLS = {
  PRODUCTION_HOST: 'https://tagbox-api-sync-lwbxg.ondigitalocean.app',
  STAGING_HOST: 'https://tagbox-api-staging-sync-e8gbr.ondigitalocean.app',
  DEVELOPMENT_HOST: 'https://tagbox-api-develop-sync-ebybt.ondigitalocean.app',
  LOCAL_HOST: 'https://tagbox-api-develop-sync-ebybt.ondigitalocean.app',
  //LOCAL_HOST: 'http://localhost:3001',
}

export const SERVERLESS_URLS = {
  PRODUCTION_HOST:
    'https://7wjuvec5u7.execute-api.eu-west-1.amazonaws.com/prod',
  STAGING_HOST:
    'https://iekwyq52n4.execute-api.eu-west-1.amazonaws.com/staging',
  DEVELOPMENT_HOST:
    'https://lrlyw095i0.execute-api.eu-west-1.amazonaws.com/dev',
  LOCAL_HOST: 'https://lrlyw095i0.execute-api.eu-west-1.amazonaws.com/dev',
}

export const CLIENT_URLS = {
  PRODUCTION_HOST: 'https://app.tagbox.io',
  STAGING_HOST: 'https://staging.tagbox.io',
  DEVELOPMENT_HOST: 'https://develop.tagbox.io',
  LOCAL_HOST: 'http://localhost:3000',
}

export const METABASE_URLS = {
  PRODUCTION_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
  STAGING_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
  DEVELOPMENT_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
  LOCAL_HOST:
    'https://metabase.tagbox-internal.com/public/dashboard/e26a9eab-370c-4b38-82a8-0592ec1d7a3d',
}
