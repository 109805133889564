import React, { useState, useEffect } from 'react'
import { t, Trans } from '@lingui/macro'

import { EXT2TYPE } from '../../SearchByFileType/filesExt'
import TagList from '../../TagList/TagList.js'
import history from '../../../services/history'
import { RULES } from '../../../store/OrgsWorkspacesStore/permissions'
import ActionDialog from '../../ActionDialog/ActionDialog.js'
import { MyKeyword } from '../../_MyComponents'
import TextField from '@material-ui/core/TextField'
import { debounce, set } from 'lodash'
import { Menu, MenuItem } from '@szhsin/react-menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import RestoreIcon from '@mui/icons-material/Restore'
import COLLECTION from '../../../assets/collection-icon.svg'
import Person from './../../Person/Person.js'
import AVATAR from '../../../assets/avatar-icon.svg'

import linkIcon from '../../../assets/link-icon-24.svg'
import videoIcon from '../../../assets/video-icon-24.svg'
import imageIcon from '../../../assets/image-icon-24.svg'
import vectorIcon from '../../../assets/vector-icon-24.svg'
import docIcon from '../../../assets/document-icon-24.svg'
import audioIcon from '../../../assets/audio-icon-24.svg'
import cameraIcon from '../../../assets/camera-icon-24.svg'

import { fileSizeFormatter } from './../../../util/fileSizeFormatter'
import Divider from '@material-ui/core/Divider'
import { useStore } from '../../../store/StoresProvider.js'

const DetailsTab = ({
  // from parent
  workspaceId,
  username,
  deleteFile,
  isAllowed,
  onAddTag,
  onRemoveTag,
  addCollectionToFile,
  removeCollectionToFile,
  updateFileDescription,
  restoreVersion,
  file,
  fileId,
  tags,
  setVersionIndex,
  closePreview,
  setView,
}) => {
  const { filesStore, orgsWorkspacesStore, authenticationStore } = useStore()

  const { language } = filesStore

  const arr = EXT2TYPE()

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  }
  const [anchor, setAnchor] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [value, setValue] = React.useState(0)
  const [fileDescription, setFileDescription] = useState('')
  const [rtl, setRtl] = React.useState(false)
  const [pplExpand, setPplExpand] = useState(false)
  const [tagListType, setTagListType] = useState('REGULAR')
  const [controlledDescription, setControlledDescription] = useState('')

  const isPersons =
    file?.persons?.slice().filter((person) => person.removed != true).length > 0

  //////////////////////////////////////
  ///// RTL IN DESCRIPTION
  //////////////////////////////////////

  function isRTL(s) {
    var rtlChars = '\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC',
      rtlDirCheck = new RegExp('^[^' + rtlChars + ']*?[' + rtlChars + ']')

    return rtlDirCheck.test(s)
  }

  useEffect(() => {
    const firstChar = fileDescription[0]
    isRTL(firstChar) ? setRtl(true) : setRtl(false)

    setControlledDescription(fileDescription)
  }, [fileDescription])

  //////////////////////////////////////
  ///// REST OF CODE
  //////////////////////////////////////

  useEffect(() => {
    setFileDescription(file?.description || '')
    //tab value
    if (file?.transcriptionStatus === 'irrelevant' && value === 2) {
      setValue(0)
    }
  }, [file?._id])

  const handleClose = () => {
    setAnchor(null)
  }

  const handlePersonClick = (view) => {
    setView(view)
    closePreview()
  }

  const getDate = (date) => {
    var options = { year: 'numeric', month: 'short', day: 'numeric' }
    var curDate = new Date(date)
    return curDate.toLocaleDateString(language, options).replace(/,/g, '')
  }

  const getTime = (date) => {
    var options = {
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    var curDate = new Date(date)
    return curDate.toLocaleDateString(language, options).replace(/,/g, '')
  }

  const fileDelete = (_id) => {
    deleteFile(_id).then(() => {
      history.push(`/w/${workspaceId}`)
    })
  }

  const handleChangeDescription = (e) => {
    const description = e.target.value
    setFileDescription(description)
    delayedHandleChangeDescription(description)
    //updateFileDescription({fileId: file._id, description: "Oz is nice"})
    //setFileDescription(description);
  }

  const delayedHandleChangeDescription = debounce((val) => {
    const description = val
    updateFileDescription({ fileId: file._id, description })
  }, 1500)

  //////////////////////////////////////
  ///// VERSIONS
  //////////////////////////////////////

  const versionMenu = ({ version, idx }) => {
    return (
      <Menu
        className="version-menu"
        direction="right"
        menuButton={
          <IconButton
            aria-controls={`version-menu-1`}
            className={'popup'}
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        }
      >
        <MenuItem onClick={() => setVersionIndex(idx)}>
          <VisibilityOutlinedIcon fontSize="small" className="ver-menu-icon" />{' '}
          <Trans>Preview</Trans>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await restoreVersion({ fileId: file._id, versionIndex: idx })
            setVersionIndex(null)
          }}
        >
          <RestoreIcon fontSize="small" className="ver-menu-icon" />{' '}
          <Trans>Restore</Trans>
        </MenuItem>
      </Menu>
    )
  }

  const versions = !!file.versions?.length
    ? file.versions?.map((version, idx) => {
        return (
          <>
            <div className="version-wrapper" key={`version${version._id}`}>
              <div className="version-content">
                <div className="version-details">
                  <img
                    src={version?.thumbnail || COLLECTION}
                    alt={getDate(version.updatedAt)}
                  />
                  <div className="version-texts">
                    <p className="p">{getDate(version.updatedAt)}</p>
                    <p className="p">{version.username}</p>
                  </div>
                </div>
                {!idx && (
                  <MyKeyword
                    text={t`Current version`}
                    className={'current-version-keyword'}
                    disabled
                  />
                )}
                {!!idx &&
                  (isAllowed(RULES.UPDATE_VERSIONS) ||
                    file.username == username) &&
                  versionMenu({ version, idx })}
              </div>
            </div>
          </>
        )
      })
    : [
        <div className="version-wrapper" key={`version0`}>
          <div className="version-circle" />
          <div className="version-line" />
          <div className="version-content">
            <div className="version-details">
              <img
                src={file.thumbnail || COLLECTION}
                alt={getDate(file.createdAt)}
              />
              <div className="version-texts">
                <p className="p">{getDate(file.createdAt)}</p>
                <p className="p">{file.username}</p>
              </div>
            </div>
          </div>
        </div>,
      ]

  //////////////////////////////////////
  ///// Persons
  //////////////////////////////////////

  const personsExpanded = !!pplExpand
    ? file?.persons?.slice(0, 20)
    : file?.persons?.slice(0, 3)

  const personComponents = personsExpanded
    ?.slice()
    .filter((person) => person.removed != true)
    .sort((a, b) => b.count - a.count)
    .map((person, idx) => {
      const { name, defaultFaceLocation } = person
      return (
        <Person
          isClickable={true}
          key={`person-${person._id}`}
          person={person}
          itemId={`${person._id}-k`}
          setView={handlePersonClick}
        />
      )
    })

  //////////////////////////////////////
  ///// SECTION COMPONENTS
  //////////////////////////////////////

  const getIcon = (ext) => {
    const type = arr[ext]
    switch (type) {
      case 'videos':
        return videoIcon
      case 'images':
        return imageIcon
      case 'audio':
        return audioIcon
      case 'links':
        return linkIcon
      case 'vectors':
        return vectorIcon
      default:
        return docIcon
    }
  }

  const detailsSection = (
    <div className="section preview-info">
      <h3 className={'h3'}>
        <Trans>Details</Trans>
      </h3>

      {/* UPLOAD */}
      <div className="preview-info-inner">
        <div className="info-item-wrapper">
          <div className="info-avatar">
            <img src={AVATAR} alt="avatar" />
          </div>
          <div className="info-details">
            <div className="main-details">
              {file?.userId?.name || 'Guest'} <Trans>(owner)</Trans>
            </div>
            <div className="sub-details">
              <div className="sub-details-item p">
                {getDate(file.createdAt)}
              </div>
              <div className="sub-details-item p">
                {getTime(file.createdAt)}
              </div>
            </div>
          </div>
        </div>

        {/* FILE */}
        <div className="info-item-wrapper">
          <div className="info-avatar">
            <img src={getIcon(file.ext)} alt="avatar" />
          </div>
          <div className="info-details">
            <div className="main-details">{file?.name}</div>
            <div className="sub-details">
              <div className="sub-details-item p">
                {fileSizeFormatter(file.size)}
              </div>
              {file.metadata?.height && (
                <div className="sub-details-item p">
                  {file.metadata?.width} x {file.metadata?.height}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* CA,ERA */}
        {file?.metadata?.Make && (
          <div className="info-item-wrapper">
            <div className="info-avatar">
              <img src={cameraIcon} alt="avatar" />
            </div>
            <div className="info-details">
              <div className="main-details">
                {file?.metadata?.Make} {file?.metadata?.Model}
              </div>
              {(file.metadata?.ExposureTime ||
                file.metadata?.Aperture ||
                file.metadata?.FocalLength) && (
                <div className="sub-details">
                  <div className="sub-details-item p">
                    {file.metadata?.ExposureTime}
                  </div>
                  <div className="sub-details-item p">
                    {file.metadata?.Aperture}
                  </div>
                  <div className="sub-details-item p">
                    {file.metadata?.FocalLength}
                  </div>
                  <div className="sub-details-item p">
                    ISO{file.metadata?.ISO}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const tagsSection = (
    <div className="section">
      <h3 className={'h3'}>
        <Trans>Tags</Trans>
      </h3>
      <div className="details-tags">
        {file.tags
          .slice()
          .sort((a, b) => b.count - a.count)
          .map((tag, idx) => {
            const { name } = tag
            return (
              <MyKeyword
                disabled={true}
                key={`tag-${tag._id}`}
                text={`${name}`}
              />
            )
          })}
        {isAllowed(RULES.ADD_TAG) && (
          <div
            className="add-tag-button"
            onClick={(e) => {
              //querySelector is a workaround because selecting the ref breaks the popover
              //setAnchor(itemRef.current)
              setTagListType('REGULAR')
              setAnchor(
                document.querySelector('.details-tab .section:nth-of-type(1)')
              )
            }}
          >
            <Trans>+ Edit tags</Trans>
          </div>
        )}
      </div>
      {!isAllowed(RULES.ADD_TAG) && !file.tags.length && (
        <p className="p light">
          <Trans>No tags assigned</Trans>
        </p>
      )}
    </div>
  )

  const collectionsSection = (
    <div className="section">
      <h3 className={'h3'}>
        <Trans>Collections</Trans>
      </h3>
      <div className="details-tags">
        {file.collections
          .slice()
          .sort((a, b) => b.count - a.count)
          .map((col, idx) => {
            const { name } = col
            return (
              <MyKeyword
                disabled={true}
                key={`tag-${col._id}`}
                text={`${name}`}
              />
            )
          })}
        {isAllowed(RULES.ADD_TO_COLLECTION) && (
          <div
            className="add-tag-button"
            onClick={(e) => {
              setTagListType('COLLECTION')
              setAnchor(
                document.querySelector('.details-tab .section:nth-of-type(2)')
              )
            }}
          >
            <Trans>+ Edit collections</Trans>
          </div>
        )}
      </div>
      {!isAllowed(RULES.ADD_TO_COLLECTION) && !file.collections.length && (
        <p className="p light">
          <Trans>Not assigned to collection</Trans>
        </p>
      )}
    </div>
  )

  const peopleSection = isPersons && (
    <div className="section">
      <h3 className={'h3'}>
        <Trans>People in this photo</Trans>
      </h3>
      <div
        className="faces-wrapper"
        //sx={{ "flex-direction": "row" }}
      >
        {personComponents}
      </div>
      {file.persons?.length > 3 && (
        <div
          className="show-more"
          onClick={() => setPplExpand((prev) => !prev)}
        >
          show {pplExpand ? 'less' : 'more'}
        </div>
      )}
    </div>
  )

  const descriptionSection = (
    <div className="section">
      <h3 className={'h3'}>
        <Trans>Description</Trans>
      </h3>
      <TextField
        multiline
        minRows={3}
        variant="outlined"
        onChange={handleChangeDescription}
        size="small"
        value={fileDescription}
        fullWidth
        disabled={!isAllowed(RULES.EDIT_DESCRIPTION)}
        dir={rtl ? 'rtl' : ''}
      />
    </div>
  )

  const versionsSection = file?.storageType === 's3' && !!versions?.length && (
    <div className="section">
      <h3 className={'h3'}>
        <Trans>Version history</Trans>
      </h3>
      <div className="versions-wrapper">{versions}</div>
    </div>
  )

  return (
    <>
      <>
        {tagsSection}

        <Divider className="divider" sx={{ height: 0 }} />

        {collectionsSection}

        <Divider className="divider" sx={{ height: 0 }} />

        {peopleSection}

        {!!personComponents?.length && (
          <Divider className="divider" sx={{ height: 0 }} />
        )}

        {descriptionSection}

        <Divider className="divider" sx={{ height: 0 }} />

        {detailsSection}

        <Divider className="divider" sx={{ height: 0 }} />

        {versionsSection}
      </>

      <TagList
        type={tagListType}
        anchor={anchor}
        origin={anchorOrigin}
        id={`${tagListType}_${fileId}`}
        onClose={handleClose}
        fileId={file._id}
        tags={tags}
        onAddTag={onAddTag}
        onRemoveTag={onRemoveTag}
        collections={file.collections}
        onAddCollection={addCollectionToFile}
        onRemoveCollection={removeCollectionToFile}
      />

      {showDialog === true && (
        <ActionDialog
          actionName={t`Delete`}
          action={() => fileDelete(file._id)}
          cancel={() => setShowDialog(false)}
          question={t`Delete file ${file.name}?`}
          comment={t`You're about to permanently delete this file, its comments and
              related content, and all of its data.`}
          color={'warning'}
          isOpen={showDialog}
        />
      )}
    </>
  )
}

export default DetailsTab
