import React from 'react'
import Button from '@material-ui/core/Button'

const ThirdPartyPermissions = () => {
  const host = window.location.origin

  return (
    <Button variant="outlined" className="dropbox-loginButton">
      <a
        className="dropbox-loginButton"
        href={`https://www.dropbox.com/oauth2/authorize?client_id=hgdkt6h3sp0xe8f&redirect_uri=${host}/oauth&response_type=token`}
      >
        Sign up to Dropbox
      </a>
    </Button>
  )
}

export default ThirdPartyPermissions
