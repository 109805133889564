import 'intro.js/introjs.css'

const steps1 = [
  {
    element: '.es-card-button',
    title: 'Upload files',
    intro:
      'To get started, click the button or drag-and-drop anywhere on the screen.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
]

const steps2 = [
  {
    element: '.autotag-section:nth-of-type(1)',
    title: 'Tag all files at once',
    intro:
      'Uploaded an entire photo session? Tag it with project name, location, photographer name, or other metadata.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
  {
    element: '.persons-content-container',
    title: 'Name people in images',
    intro: 'Identify key people to make them easily searchable.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
  {
    element: '.upload-tags .add-tag-button',
    title: 'Tag individual files',
    intro: 'Refine your tags by adding or removing tags from specific files.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
]

const steps3 = [
  {
    element: '.tags-filter-wrapper',
    title: 'Tag filters',
    intro: 'Use tag filters to find the right asset.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
  {
    element: '.search-container .input-body',
    title: 'Smart search',
    intro: 'Use smart search to find assets by name, tag, or image contents.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
  {
    element: '.filters-wrapper',
    title: 'Enhanced filters',
    intro: 'Filter your assets by file properties, people, or color.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
]

// upload and tagging together - used in templates.

const steps4 = [
  {
    element: '.uploadButton',
    title: 'Upload files',
    intro:
      'To get started, click the button or drag-and-drop anywhere on the screen.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
  {
    element: '.tags-filter-wrapper',
    title: 'Tag filters',
    intro: 'Use tag filters to find the right asset.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
  {
    element: '.search-container .input-body',
    title: 'Smart search',
    intro: 'Use smart search to find assets by name, tag, or image contents.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
  {
    element: '.filters-wrapper',
    title: 'Enhanced filters',
    intro: 'Filter your assets by file properties, people, or color.',
    position: 'right',
    showProgress: 'false',
    //tooltipClass: 'myTooltipClass',
  },
]

export { steps1, steps2, steps3, steps4 }
