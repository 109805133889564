export const LOGIN = '/login'
export const AUTH = '/auth'
export const HOME = '/w/:_id'
export const THIRD_PARTY_RESPONSE = '/oauth'
export const THIRD_PARTY_LOGIN = '/3rd-party-login'
export const ASSIGN_FOLDERS = '/assign'
export const CREATE_ORGANIZATION = '/organizations'
export const CREATE_WORKSPACE = `${CREATE_ORGANIZATION}/:id/workspaces`
export const NEW_ORG = '/survey/:id'
export const REGISTER = '/register'
export const FILE_PREVIEW = '/file-preview'
export const RESET_PASSWORD = '/reset-password'
export const DRIVE = '/drive'
export const ONBOARDING = '/cleanup'
export const PREVIEW = '/w/:_id/d/:_idp'
export const UNSUBSCRIBE = '/unsubscribe/:username'