import moment from 'moment'

export const DATETIME_FORMATS = {
  DATE_SHORT: 'D MMM',
  DATE_LONG: 'D MMM, YYYY',
  TIME: 'HH:mm',
  DATETIME_SHORT: 'D MMM, HH:mm',
  DATETIME_LONG: 'D MMM, YYYY HH:mm',
  DATETIME_LONG_SEC: 'DD.MM.YYYY HH:mm:ss',

  TIME_LONG: 'H:mm:ss',
}

export const SECOND_IN_MILLI = 1000

export const dateShort = (date) =>
  moment(date).format(DATETIME_FORMATS.DATE_SHORT)

export const dateLong = (date) =>
  moment(date).format(DATETIME_FORMATS.DATE_LONG)

export const time = (date) => moment(date).format(DATETIME_FORMATS.TIME)

export const timeLong = (date) => moment(date).format(DATETIME_FORMATS.TIME)

export const dateTimeShort = (date) =>
  moment(date).format(DATETIME_FORMATS.DATETIME_SHORT)

export const dateTimeLong = (date) =>
  moment(date).format(DATETIME_FORMATS.DATETIME_LONG)

export const diffTime = (startDate, endDate) => {
  return moment.utc(
    moment.duration(moment(endDate).diff(moment(startDate))).asMilliseconds()
  )
}

export const diffTimeLong = (startDate, endDate) =>
  diffTime(startDate, endDate).format(DATETIME_FORMATS.TIME_LONG)

export const diffTimeWithPrefix = (initialTimestamp, timestamp) => {
  const diffTimes = diffTimeLong(initialTimestamp, timestamp)
  const t = diffTimes.split(':')
  return `${diffTimes} ${t.length === 3 && t[0] !== '0' ? 'hrs' : 'min'}`
}

moment.updateLocale('en', {
  relativeTime: {
    past(input) {
      return input === 'just now' ? input : `${input} ago`
    },
    s: 'just now',
    future: 'in %s',
    ss: '%d sec',
    m: 'a min',
    mm: '%d min',
    h: 'an hrs',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

export const fromNow = (date) => {
  return moment(date).fromNow()
}

export const ms2Duration = function millisToMinutesAndSeconds(millis) {
  var hours = Math.floor(millis / 3600000)
  var minutes = Math.floor((millis % 3600000) / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  var time =
    (hours > 0 ? hours + ':' : '') +
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  return time
}
