import axios from 'axios'
import { host } from '../../../services/host'
import { getHeaderMerged } from '../util'

export const addComment = async (
  content,
  fileId,
  orgId,
  userId,
  workspaceId,
  mentions
) => {
  const { data } = await axios.post(
    `${host}/comments/${fileId}`,
    {
      content: content,
      parentType: 'file',
      workspaceId: workspaceId,
      orgId: orgId,
      userId: userId,
      mentions: mentions,
    },
    { headers: await getHeaderMerged() }
  )
  getComments(fileId, workspaceId)
  getCommentsCount(fileId, workspaceId)
  return data
}

export const getComments = async (fileId, workspaceId) => {
  const { data } = await axios.get(`${host}/comments/${fileId}`, {
    headers: await getHeaderMerged(),
    params: {
      order: 'desc',
      limit: 100,
      skip: 0,
      workspaceId: workspaceId,
    },
  })
  return data
}

export const getCommentsCount = async (fileId, workspaceId) => {
  const { data } = await axios.get(`${host}/comments/${fileId}/count`, {
    headers: await getHeaderMerged(),
    params: {
      workspaceId: workspaceId,
    },
  })
  return data
}

export const deleteComment = async ({ id }) => {
  const url = `${host}/comments/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export default {
  addComment,
  getComments,
  getCommentsCount,
  deleteComment,
}
