import React, { useEffect, useState } from 'react'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import { makeStyles } from '@material-ui/core/styles'
import './style.scss'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SHARE from '../../assets/share-icon.svg'
import EMBED from '../../assets/embed-icon.svg'
import DownloadIcon from '../../assets/download-icon.svg'
import DELETE_LINK from '../../assets/delete-icon.svg'
import UPLOAD from '../../assets/upload-icon.svg'
import OpenInNewIcon from '../../assets/open-link-icon.svg'
import { MyButton } from '../_MyComponents'
import NewVersionUpload from '../NewVersionUpload/NewVersionUpload.js'
import ActionDialog from '../ActionDialog/ActionDialog.js'
import { debounce, set } from 'lodash'

import ShareFile from './ShareFile'
import EmbedFile from './EmbedFile'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    zIndex: 99,
    width: '100%',
    top: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
  },
  toolBar: {
    background: 'white',
    minHeight: '72px',
  },
}))

const TopBarPreview = ({
  organization,
  file,
  handleClose,
  allowedToEdit,
  allowedToShare,
  downloadFile,
  openDetailBar,
  allowedToUpgradeVersion,
  updateFileType,
  setCDN,
  isSingleFileUser,
  logo,
  isAllowed,
  RULES,
  deleteFile,
  orgPlanLimits,
  setIsPlanPrompt,
  signedLocation,
  handlePrevNext,
}) => {
  const { filesStore } = useStore()

  const { updateFileName } = filesStore
  const classes = useStyles()

  const [newVersionOpen, setNewVersionOpen] = React.useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [cdnDialogOpen, setCdnDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [fileName, setFileName] = useState(file.name)

  const debouncedNameChange = debounce((newName) => {
    updateFileName({ fileId: file._id, name: newName })
  }, 1500)

  const handleNameChange = (e) => {
    const newName = e.target.value
    debouncedNameChange(newName)
    setFileName(newName)
  }

  const fileDelete = async (_id) => {
    setDeleteDialogOpen(false)
    await deleteFile(_id)
    handlePrevNext('next')
  }

  useEffect(() => {
    setFileName(file.name)
  }, [file?.name])

  console.log(file?.ext, isAllowed(RULES.DOWNLOAD_DOC), window.mobileCheck())

  const topbarLeft = (
    <div className="topbar-left">
      {!window.mobileCheck() && (
        <span
          className="flex-row"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (!isSingleFileUser()) {
              handleClose()
            }
          }}
        >
          {!!organization && logo && (
            <img className="logo" src={logo} alt="Logo" />
          )}
        </span>
      )}
      <div className={'preview-tab-title-name'}>
        <TextField
          value={fileName}
          onChange={(e) => handleNameChange(e)}
          fullWidth
          disabled={!allowedToEdit}
        />
        {/* {file.ext === "www" && (
          <a
            style={{ cursor: "pointer", color: "white" }}
            href={file.preview}
            target="_blank"
            rel="noopener noreferrer"
          >
            ({file.preview})
          </a>
        )} */}
      </div>
    </div>
  )

  const topbarButtons = (
    <div className={classes.filename + ' preview-tab-title'}>
      <div className={'preview-tab-title-icons'}>
        {allowedToShare && file.ext !== 'www' && (
          <Tooltip title={t`Embed file`}>
            <IconButton
              className="filter-icon"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setCdnDialogOpen(true)}
            >
              <img src={EMBED} alt="Embed file" />
            </IconButton>
          </Tooltip>
        )}
        {file.ext === 'www' && (
          <Tooltip title={t`Open in a new tab`}>
            <IconButton
              className="filter-icon"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => window.open(signedLocation, '_blank')}
            >
              <img src={OpenInNewIcon} alt="Open link in new tab" />
            </IconButton>
          </Tooltip>
        )}
        {isAllowed(RULES.DELETE_DOC) && (
          <Tooltip title={t`Delete file`}>
            <IconButton
              className="filter-icon"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <img src={DELETE_LINK} alt="Delete icon" />
            </IconButton>
          </Tooltip>
        )}
        {allowedToShare && (
          <Tooltip title={t`Share file`}>
            <IconButton
              className="filter-icon"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setShareDialogOpen(true)}
            >
              <img src={SHARE} alt="share icon" />
            </IconButton>
          </Tooltip>
        )}
        {file?.storageType === 's3' && allowedToUpgradeVersion && (
          <Tooltip title={t`Upload a new version`}>
            <IconButton
              className="filter-icon"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setNewVersionOpen(true)}
            >
              <img src={UPLOAD} alt="share icon" />
            </IconButton>
          </Tooltip>
        )}
        {file?.ext !== 'www' &&
          (isAllowed(RULES.DOWNLOAD_DOC) || isSingleFileUser()) && // this can create a bug for clients with disabled download
          (window.mobileCheck() ? (
            <IconButton
              className="filter-icon"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => downloadFile(file)}
            >
              <img src={DownloadIcon} alt="download" />
            </IconButton>
          ) : (
            <MyButton
              onClick={() => downloadFile(file)}
              variant="contained"
              color="primary"
              startIcon={<img src={DownloadIcon} alt="download" />}
            >
              <Trans>Download</Trans>
            </MyButton>
          ))}
        {window.mobileCheck() && isAllowed(RULES.VIEW_DETAILS_BAR) && (
          <MoreVertIcon
            className="preview-menu-icon preview-icon"
            aria-controls="top-bar-menu"
            aria-haspopup="true"
            onClick={openDetailBar}
          />
        )}
      </div>
    </div>
  )

  const topbarClose = !isSingleFileUser() && (
    <IconButton className="close-wizard" onClick={handleClose} component="span">
      <CloseIcon />
    </IconButton>
  )

  return (
    <div className={classes.root + ' top-bar-preview'}>
      <ShareFile
        open={shareDialogOpen}
        setOpen={setShareDialogOpen}
        fileType={file.type}
        fileId={file._id}
        updateFileType={updateFileType}
      ></ShareFile>
      <EmbedFile
        open={cdnDialogOpen}
        setOpen={setCdnDialogOpen}
        fileCdnURL={file.cdnURL}
        fileId={file._id}
        setCDN={setCDN}
        cdnCount={orgPlanLimits.cdnCount}
        cdnCountLimit={orgPlanLimits.cdnCountLimit}
        isAllowed={isAllowed}
        setIsPlanPrompt={setIsPlanPrompt}
      ></EmbedFile>
      <AppBar position="static">
        <Toolbar className={classes.toolBar + ' header-menu'}>
          {!window.mobileCheck() ? (
            <>
              {topbarLeft}
              {topbarButtons}
              {topbarClose}
            </>
          ) : (
            <div className="topbar-mobile">
              <div className="topbar-mobile-top">
                {topbarLeft}
                {topbarClose}
              </div>
              <div className="topbar-mobile-bottom">{topbarButtons}</div>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {newVersionOpen && (
        <NewVersionUpload
          fileId={file._id}
          close={() => setNewVersionOpen(false)}
          isOpen={newVersionOpen}
        />
      )}
      {deleteDialogOpen === true && (
        <ActionDialog
          actionName={t`Delete`}
          action={() => {
            fileDelete(file._id)
          }}
          cancel={() => setDeleteDialogOpen(false)}
          question={t`Delete file ${file.name}?`}
          comment={t`You're about to permanently delete this file, its comments and related content, and all of its data.`}
          color={'warning'}
          isOpen={deleteDialogOpen}
        />
      )}
    </div>
  )
}

export default TopBarPreview
