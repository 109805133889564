import history from '../history'
import { dispatch } from '../pubsub'
import Actions from '../../util/actions'
import {
  LOGIN,
  HOME,
  THIRD_PARTY_RESPONSE,
  REGISTER,
  PREVIEW,
} from '../../util/routes'
import routerConfig from './routerConfig'

const router = routerConfig({ history })
const extractParams = ({ search }) =>
  search
    .substr(1)
    .split('&')
    .filter((value) => value)
    .reduce((acc, pair) => {
      const [key, value] = pair.split('=')
      return { ...acc, [key]: value }
    }, {})
router
  .setRoute(
    PREVIEW,
    { exact: false, exclude: { url: LOGIN, exact: true } },
    ({ params, path, routeParams }) => {
      dispatch(PREVIEW, routeParams)
    }
  )
  .setRoute(
    THIRD_PARTY_RESPONSE,
    { exact: false, exclude: { url: LOGIN, exact: true } },
    ({ params, path, routeParams }) => {
      dispatch(Actions.THIRD_PARTY_RESPONSE, routeParams)
    }
  )
  .setRoute(
    HOME,
    { exact: false, exclude: { url: LOGIN, exact: true } },
    ({ params, path, routeParams }) => {}
  )
  .setRoute(REGISTER, ({ routeParams: { search } }) => {
    const { email } = extractParams({ search })
    dispatch(REGISTER, { email })
  })
  .setRoute(LOGIN, ({ routeParams: { search } }) => {
    const { email } = extractParams({ search })
    dispatch(LOGIN, { email })
  })

export default router
