const COLORS = {
  doc: '#42A5F5',
  gdoc: '#42A5F5',
  docx: '#42A5F5',
  odt: '#42A5F5',
  pdf: '#EF5350',
  rtf: '#42A5F5',
  tex: '#42A5F5',
  txt: '#42A5F5',
  wpd: '#42A5F5',
  csv: '#26A69A',
  gsheet: '#26A69A',
  dat: '#26A69A',
  db: '#26A69A',
  dbf: '#26A69A',
  log: '#26A69A',
  mdb: '#26A69A',
  sav: '#26A69A',
  sql: '#26A69A',
  tar: '#26A69A',
  xml: '#26A69A',
  xlsx: '#26A69A',
  xlsm: '#26A69A',
  xls: '#26A69A',
  ai: '#7986CD',
  gslide: '#FBC02D',
  pptx: '#FBC02D',
  ppt: '#FBC02D',
  pps: '#FBC02D',
  odp: '#FBC02D',
  key: '#FBC02D',
  bmp: '#7986CD',
  gif: '#7986CD',
  ico: '#7986CD',
  jpeg: '#7986CD',
  jpg: '#7986CD',
  png: '#7986CD',
  ps: '#7986CD',
  psd: '#7986CD',
  svg: '#7986CD',
  tif: '#7986CD',
  '3g2': '#7986CD',
  '3gp': '#7986CD',
  h264: '#7986CD',
  m4v: '#7986CD',
  mkv: '#7986CD',
  mov: '#7986CD',
  mp4: '#7986CD',
  mpg: '#7986CD',
  mpeg: '#7986CD',
  rm: '#7986CD',
  swf: '#7986CD',
  vob: '#7986CD',
  wmv: '#7986CD',
  aif: '#7986CD',
  cda: '#7986CD',
  mid: '#7986CD',
  midi: '#7986CD',
  mp3: '#7986CD',
  mpa: '#7986CD',
  ogg: '#7986CD',
  wav: '#7986CD',
  wma: '#7986CD',
  wpl: '#7986CD',
  www: '#798897',
}

export default COLORS
