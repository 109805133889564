import './services'
import React, { Suspense, useState } from 'react'
import ReactDOM from 'react-dom'
import './services/fetchInterceptService'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { StoresProvider } from './store/StoresProvider.js'
import { subscribe } from './services/pubsub'
import logger from './services/logger'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as messagesEn } from './locales/en/messages'

import { t, Trans } from '@lingui/macro'

subscribe('*', logger.log)

i18n.load('en', messagesEn)
i18n.activate('en')

const PROD = process.env.REACT_APP_NODE_ENV === 'production'
const DEFAULT_LOCALE = 'en'

if (PROD) {
  Sentry.init({
    dsn: 'https://9aa3eca617d34c7e8f22701c8bab0ae8@o4504192883584.ingest.sentry.io/4504197125046272',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Suspense
    fallback={
      <div>
        <Trans>Loading...</Trans>
      </div>
    }
  >
    <StoresProvider>
      <I18nProvider i18n={i18n} language={DEFAULT_LOCALE}>
        <App />
      </I18nProvider>
    </StoresProvider>
  </Suspense>,
  document.getElementById('root')
)

serviceWorker.unregister()
