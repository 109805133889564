import axios from 'axios'
import { getQueryParam } from '../../../util/httpHelper'
import { host, serverlessHost, syncHost } from '../../../services/host'
import { getHeaderMerged, getToken } from '../util'

export const initUpload = async ({
  file,
  userId,
  orgId,
  workspaceId,
  syncJobId,
  retry = 0,
}) => {
  const url = `${host}/files/upload`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      progress: false,
      data: { file, userId, orgId, workspaces: workspaceId, syncJobId },
    })
    return data
  } catch (err) {
    //retry 5 times
    if (retry < 5) {
      //wait 5 seconds * try
      await new Promise((resolve) => setTimeout(resolve, 5000 * (retry + 1)))
      return await initUpload({
        file,
        userId,
        orgId,
        workspaceId,
        syncJobId,
        retry: retry + 1,
      })
    }
    throw err
  }
}

export const simpleUploadFileToS3 = async ({ file, orgId }) => {
  const url = `${host}/files/public/upload`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { file, orgId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const uploadFile = async (file) => {
  const url = `${host}/upload`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: file,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateThumbnail = async (
  thumbnail,
  fileId,
  width = 1,
  height = 1
) => {
  const url = `${host}/files/${fileId}/thumbnail`
  //const thumb = {thumbnail}
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { thumbnail, width, height },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const setToken = async (thirdpartyDetails) => {
  const url = `${host}/files/token`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: thirdpartyDetails,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const preSearch = async ({
  orgId,
  workspaces,
  text,
  collections,
  context,
}) => {
  const url = `${host}/files/omni`
  var data = { orgId, workspaces, text }

  if (context === 'COLLECTION') {
    //
    data = {
      ...data,
      collections: collections.length > 0 ? collections[0] : null,
    }
  }

  try {
    const res = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      progress: false,
      data,
    })
    return res
  } catch (err) {
    throw err
  }
}

export const getFiles = async ({
  orgId,
  tags = [],
  keywords = [],
  persons = [],
  collections = [],
  workspaceId,
  users = [],
  text,
  image,
  imageTreshold = 80,
  isFavorite,
  unTagged,
  limit,
  skip,
  exts = [],
  people,
  duration = {},
  fileSize = {},
  orientationMode,
  colors = [],
  resolution,
  advanceColorFilter,
  /*   updatedStart,
  updatedEnd,
 */ createdStart,
  createdEnd,
  /*   viewedDateStart,
  viewedDateEnd,
 */
  similar,
}) => {
  const query = getQueryParam({
    orgId,
    tags: tags
      .filter((t) => t && `${t}`.trim())
      .map((t) => encodeURIComponent(t))
      .join(','),
    keywords: keywords
      .filter((k) => k && `${k}`.trim())
      .map((k) => encodeURIComponent(k))
      .join(','),
    persons: persons
      .filter((m) => m && `${m}`.trim())
      .map((m) => encodeURIComponent(m))
      .join(','),
    collections: collections
      .filter((c) => c && `${c}`.trim())
      .map((c) => encodeURIComponent(c))
      .join(','),
    exts: exts.filter((t) => t && `${t}`.trim()).join(','),
    workspaces: [workspaceId],
    users: users.join(','),
    text: text && encodeURIComponent(text),
    isFavorite,
    unTagged,
    people_number: people?.number,
    people_gender: people?.gender,
    people_age: people?.age?.join(','),
    people_emotion: people?.emotion,
    people_pose: people?.pose,
    duration: duration?.name ? JSON.stringify(duration) : null,
    fileSize: fileSize?.name ? JSON.stringify(fileSize) : null,
    orientationMode: orientationMode?.key,
    colors: colors?.map((c) => c.key.toLowerCase()).join(','),
    resolution: resolution?.key?.toLowerCase(),
    advanceColorFilter: advanceColorFilter?.key,
    similar,
    limit,
    skip,
    //updatedStart,
    //updatedEnd,
    createdStart,
    createdEnd,
    //viewedDateStart, // '2020-09-09'
    //viewedDateEnd, // '2020-11-11',
    image: image ? encodeURIComponent(image) : image,
    imageTreshold: image ? imageTreshold : image,
  })
  const url = `${host}/files/?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
      progress: false,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getFile = async (id, retry = 0) => {
  const url = `${host}/files/file/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
      progress: false,
    })
    return data
  } catch (err) {
    //try 5 times
    if (retry < 5) {
      //wait 5 seconds * try
      await new Promise((resolve) => setTimeout(resolve, 5000 * (retry + 1)))
      return await getFile(id, retry + 1)
    }
    throw err
  }
}

export const checkIfFileIsPublic = async (fileId) => {
  const url = `${host}/files/public/${fileId}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
      progress: false,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateFile = async ({
  fileId,
  name,
  preview,
  description,
  type,
}) => {
  const url = `${host}/files/${fileId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { name, preview, description, type },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const setCDNFile = async ({ fileId, enable }) => {
  //return { cdnURL: 'https://cdn.tagbox.io/files/5f5b1' }

  const url = `${host}/files/cdn/${fileId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { enable },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getTags = async ({
  orgId,
  workspaceId,
  tags,
  keywords,
  persons,
  collections,
  text,
  exts,
  people,
  duration,
  fileSize,
  orientationMode,
  colors,
  resolution,
  advanceColorFilter,
  createdStart,
  createdEnd,
  users,
  isFavorite,
  unTagged,
}) => {
  const query = getQueryParam({
    orgId,
    workspaces: workspaceId,
    ...(exts?.length && { exts: exts.join(',') }),
    ...(tags?.length && { tags: tags.join(',') }),
    ...(keywords?.length && { keywords: keywords.join(',') }),
    ...(persons?.length && { persons: persons.join(',') }),
    ...(collections?.length && { collections: collections.join(',') }),
    text,
    people_number: people?.number,
    people_gender: people?.gender,
    people_age: people?.age?.join(','),
    people_emotion: people?.emotion,
    people_pose: people?.pose,
    duration: duration?.name ? JSON.stringify(duration) : null,
    fileSize: fileSize?.name ? JSON.stringify(fileSize) : null,
    orientationMode: orientationMode?.key,
    colors: colors?.map((c) => c.key.toLowerCase()).join(','),
    resolution: resolution?.key?.toLowerCase(),
    advanceColorFilter: advanceColorFilter?.key,
    createdStart,
    createdEnd,
    users: users?.join(','),
    isFavorite,
    unTagged,
  })

  const url = `${host}/files/tags?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getPersons = async ({
  orgId,
  workspaceId,
  tags,
  keywords,
  persons,
  collections,
  text,
  exts,
  people,
  duration,
  fileSize,
  orientationMode,
  colors,
  resolution,
  advanceColorFilter,
  createdStart,
  createdEnd,
  users,
  isFavorite,
  unTagged,
  skip,
  limit,
}) => {
  const query = getQueryParam({
    orgId,
    workspaces: workspaceId,
    ...(exts?.length && { exts: exts.join(',') }),
    ...(tags?.length && { tags: tags.join(',') }),
    ...(keywords?.length && { keywords: keywords.join(',') }),
    ...(persons?.length && { persons: persons.join(',') }),
    ...(collections?.length && { collections: collections.join(',') }),
    text,
    people_number: people?.number,
    people_gender: people?.gender,
    people_age: people?.age?.join(','),
    people_emotion: people?.emotion,
    people_pose: people?.pose,
    duration: duration?.name ? JSON.stringify(duration) : null,
    fileSize: fileSize?.name ? JSON.stringify(fileSize) : null,
    orientationMode: orientationMode?.key,
    colors: colors?.map((c) => c.key.toLowerCase()).join(','),
    resolution: resolution?.key?.toLowerCase(),
    advanceColorFilter: advanceColorFilter?.key,
    createdStart,
    createdEnd,
    users: users?.join(','),
    isFavorite,
    unTagged,
    skip,
    limit,
  })

  console.log('getting persons API')
  const url = `${host}/files/persons?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getPerson = async (id) => {
  const url = `${host}/persons/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const renamePerson = async ({ personId, name }) => {
  const url = `${host}/persons/name/${personId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { name },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const checkDuplicate = async ({ orgId, workspaceId, name }) => {
  const url = `${host}/persons/check-duplicate`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { workspaceId, orgId, name },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const mergePersons = async ({ ids, orgId, workspaceId }) => {
  const url = `${host}/persons/merge`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { ids, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const removePerson = async ({ personId }) => {
  const url = `${host}/persons/remove/${personId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const getFilesUsers = async ({
  orgId,
  workspaceId,
  tags,
  keywords,
  persons,
  collections,
  text,
  exts,
  people,
  duration,
  fileSize,
  orientationMode,
  colors,
  resolution,
  advanceColorFilter,
  createdStart,
  createdEnd,
  users,
  isFavorite,
  unTagged,
}) => {
  const query = getQueryParam({
    orgId,
    workspaces: workspaceId,
    ...(exts?.length && { exts: exts.join(',') }),
    ...(tags?.length && { tags: tags.join(',') }),
    ...(keywords?.length && { keywords: keywords.join(',') }),
    ...(persons?.length && { persons: persons.join(',') }),
    ...(collections?.length && { collections: collections.join(',') }),
    text,
    people_number: people?.number,
    people_gender: people?.gender,
    people_age: people?.age?.join(','),
    people_emotion: people?.emotion,
    people_pose: people?.pose,
    duration: duration?.name ? JSON.stringify(duration) : null,
    fileSize: fileSize?.name ? JSON.stringify(fileSize) : null,
    orientationMode: orientationMode?.key,
    colors: colors?.map((c) => c.key.toLowerCase()).join(','),
    resolution: resolution?.key?.toLowerCase(),
    advanceColorFilter: advanceColorFilter?.key,
    createdStart,
    createdEnd,
    users: users?.join(','),
    isFavorite,
    unTagged,
  })

  const url = `${host}/files/users?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const getFilesExtensions = async ({
  orgId,
  workspaceId,
  tags,
  keywords,
  persons,
  collections,
  text,
  exts,
  people,
  duration,
  fileSize,
  orientationMode,
  colors,
  resolution,
  advanceColorFilter,
  createdStart,
  createdEnd,
  users,
  isFavorite,
  unTagged,
}) => {
  const query = getQueryParam({
    orgId,
    workspaces: workspaceId,
    ...(exts?.length && { exts: exts.join(',') }),
    ...(tags?.length && { tags: tags.join(',') }),
    ...(keywords?.length && { keywords: keywords.join(',') }),
    ...(persons?.length && { persons: persons.join(',') }),
    ...(collections?.length && { collections: collections.join(',') }),
    text,
    people_number: people?.number,
    people_gender: people?.gender,
    people_age: people?.age?.join(','),
    people_emotion: people?.emotion,
    people_pose: people?.pose,
    duration: duration?.name ? JSON.stringify(duration) : null,
    fileSize: fileSize?.name ? JSON.stringify(fileSize) : null,
    orientationMode: orientationMode?.key,
    colors: colors?.map((c) => c.key.toLowerCase()).join(','),
    resolution: resolution?.key?.toLowerCase(),
    advanceColorFilter: advanceColorFilter?.key,
    createdStart,
    createdEnd,
    users: users?.join(','),
    isFavorite,
    unTagged,
  })

  const url = `${host}/files/ext?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

//getPopulars
const getPopularsTags = async ({ orgId, workspaceId, limit }) => {
  const query = getQueryParam({
    orgId,
    workspaceId,
    limit: limit || 10,
  })

  const url = `${host}/tags/popular?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

//For remove from favorite sent true in remove
const addToFavorite = async ({ orgId, workspaces, fileId, remove = false }) => {
  const url = `${host}/files/${fileId}/favorite`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { orgId, workspaces, fileId, remove },
    })
    return data
  } catch (err) {
    throw err
  }
}

const addToFileViewed = async ({ orgId, workspaces, fileId, similar }) => {
  const url = `${host}/files/${fileId}/viewFile`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { orgId, workspaces, fileId, similar },
    })
    return data
  } catch (err) {
    throw err
  }
}

const removeFileTag = async ({ fileId, tagsIds }) => {
  const url = `${host}/files/${fileId}/tags`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { tagsIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

const addFileTag = async ({ fileId, tagsIds }) => {
  const url = `${host}/files/${fileId}/tags`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { tagsIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

const bulkTag = async ({ fileIds, tags, clearExisting }) => {
  const url = `${host}/files/bulk/tag`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { fileIds, tags, clearExisting },
    })
    return data
  } catch (err) {
    throw err
  }
}

const bulkRemoveTags = async ({ fileIds, tagIds }) => {
  const url = `${host}/files/bulk/tag`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { fileIds, tagsIds: tagIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

const fileUploaded = async ({ userId, accountId, orgId, workspaces, file }) => {
  const url = `${host}/files/uploaded`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { userId, accountId, orgId, workspaces, file },
    })
    return data
  } catch (err) {
    throw err
  }
}

const getFilesByType = async ({ orgId, workspaces, type }) => {
  const query = getQueryParam({
    orgId,
    workspaces,
    type,
  })

  const url = `${host}/files/exts?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const getFilesByIds = async ({ ids }) => {
  const url = `${host}/files/ids`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      progress: false,
      data: { ids },
    })
    return data
  } catch (err) {
    throw err
  }
}

const getAccounts = async () => {
  const url = `${host}/files/accounts`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const getMyWorkspaces = async () => {
  const url = `${host}/files/workspaces/user`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const getAccountWorkspaces = async () => {
  const url = `${host}/files/workspaces/account`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const get3rdPartyFolderHierarchy = async ({ token }) => {
  const url = `${host}/files//accounts/${token}/hierarchy`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const parseBookmarkUrl = async (link) => {
  const url = `${host}/files/parse-url?url=${link}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    return 'empty response'
  }
}

const createFile = async (from) => {
  const url = `${host}/files/create`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: from,
    })
    return data
  } catch (err) {
    throw err
  }
}

const finishFileUpload = async (id, retry = 0) => {
  const url = `${syncHost}/files/finishupload/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    //try 5 times
    if (retry < 5) {
      //wait 5 seconds * try
      await new Promise((resolve) => setTimeout(resolve, 5000 * (retry + 1)))
      return await finishFileUpload(id, retry + 1)
    }
    throw err
  }
}

const extractFaces = async (id, retry = 0) => {
  const url = `${syncHost}/files/extractFaces/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    //try 5 times
    if (retry < 5) {
      //wait 5 seconds * try
      await new Promise((resolve) => setTimeout(resolve, 5000 * (retry + 1)))
      return await extractFaces(id, retry + 1)
    }
    //throw err;
  }
}

const searchFace = async (preview, orgId, workspaceId) => {
  const url = `${syncHost}/files/searchFace`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { preview, orgId, workspaceId },
    })
    return data
  } catch (err) {
    //throw err;
  }
}

const deleteFile = async (id) => {
  const url = `${host}/files/remove/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const deleteBulkFiles = async (filesId) => {
  const url = `${host}/files/bulk/remove`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { fileIds: filesId },
    })
    return data
  } catch (err) {
    throw err
  }
}

function str2bytes(str) {
  var bytes = new Uint8Array(str.length)
  for (var i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i)
  }
  return bytes
}

const downloadBulkFiles = async ({ fileIds }) => {
  const url = `${host}/files/bulk/download`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { fileIds },
      responseType: 'blob',
    })
    const urldownload = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = urldownload
    link.setAttribute('download', 'tagbox-files.zip') //or any other extension
    document.body.appendChild(link)
    link.click()

    return data
  } catch (err) {
    throw err
  }
}

const streamDownloadBulkFiles = async ({ fileIds }) => {
  try {
    const token = await getToken()
    const url = `${host}/files/bulk/stream-download?fileIds=${fileIds.join(
      ','
    )}&token=${token}`
    window.open(url, '_blank')
  } catch (error) {
    console.error('Error:', error)
  }
}

const getDriveFolders = async (refresh_token) => {
  const url = `${host}/files/drive/top-folders`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { refresh_token },
    })
    return data
  } catch (err) {
    throw err
  }
}

const getDrives = async (refresh_token) => {
  const url = `${host}/files/drive/drives`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { refresh_token },
    })
    return data
  } catch (err) {
    throw err
  }
}

const SyncChosenDirectories = async ({
  refresh_token,
  folderIds,
  userId,
  orgId,
  workspace,
  scope,
}) => {
  const url = `${syncHost}/files/drive/folders`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { refresh_token, folderIds, userId, orgId, workspace, scope },
    })
    return data
  } catch (err) {
    throw err
  }
}

const nameFolders = async ({
  refresh_token,
  folderIds,
  userId,
  orgId,
  workspace,
  scope,
}) => {
  const url = `${syncHost}/files/drive/folders-names`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { refresh_token, folderIds, userId, orgId, workspace, scope },
    })
    return data
  } catch (err) {
    throw err
  }
}

const countDriveFiles = async ({
  refresh_token,
  folderIds,
  orgId,
  workspaceId,
}) => {
  const url = `${syncHost}/files/drive/count`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { refresh_token, folderIds, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

const SyncChosenFiles = async ({
  refresh_token,
  folderIds,
  orgId,
  workspaceId,
  scope,
}) => {
  const url = `${syncHost}/files/drive/files`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { refresh_token, folderIds, orgId, workspaceId, scope },
    })
    return data
  } catch (err) {
    throw err
  }
}

const SyncUploadDrivePendingFiles = async ({
  refresh_token,
  folderIds,
  userId,
  orgId,
  workspaceId,
}) => {
  const url = `${syncHost}/files/drive/upload-pending`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: {
        refresh_token,
        folderIds,
        userId,
        orgId,
        workspaceId,
        atAtime: 50,
      },
    })
    return data
  } catch (err) {
    throw err
  }
}

const SyncEnded = async (syncjobId) => {
  const url = `${host}/files/syncjob/${syncjobId}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      progress: false,
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

const createSyncJob = async ({
  orgId,
  workspaceId,
  type,
  driveType,
  status = 'pending',
  totalFiles = 0,
}) => {
  const url = `${host}/files/syncjob`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      progress: false,
      data: { orgId, workspaceId, type, driveType, status, totalFiles },
    })
    return data
  } catch (err) {
    throw err
  }
}

const updateSyncJob = async ({ syncjobId, status, totalFiles }) => {
  const url = `${host}/files/syncjob/${syncjobId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      progress: false,
      data: { status, totalFiles },
    })
    return data
  } catch (err) {
    throw err
  }
}

const updateSyncJobIncrement = async ({ syncjobId }) => {
  const url = `${host}/files/syncjob-inc/${syncjobId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      progress: false,
      headers: await getHeaderMerged(),
      data: {},
    })
    return data
  } catch (err) {
    throw err
  }
}

const updateSyncJobWithFiles = async ({ syncjobId, fileId }) => {
  const url = `${host}/files/syncjob/${syncjobId}/file/${fileId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

//unused API
/* const GetNewCreatedTags = async ({ orgId, workspace }) => {
  const url = `${host}/orgs/${orgId}/tags`;
  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: await getHeaderMerged(),
    });
    return data;
  } catch (err) {
    throw err;
  }
}; */

const getSplitSuggestions = async ({ orgId, workspace, tags }) => {
  const url = `${serverlessHost}/extract`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(tags),
    })

    return data
  } catch (err) {
    throw err
  }
}

const getMergeSuggestions = async ({ orgId, workspace, tags }) => {
  const url = `${serverlessHost}/similarity`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(tags),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const removeCollections = async ({ fileId, collectionsIds }) => {
  const url = `${host}/files/collections/${fileId}`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { collectionsIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const addCollections = async ({ fileId, collectionsIds }) => {
  const url = `${host}/files/collections/${fileId}`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { collectionsIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const bulkAddCollections = async ({ fileIds, collectionsIds }) => {
  const url = `${host}/files/bulk/collections`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { fileIds, collectionsIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const bulkRemoveCollections = async ({ fileIds, collectionsIds }) => {
  const url = `${host}/files/bulk/collections`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { fileIds, collectionsIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const mergeVersion = async ({ newFileId, oldFileId }) => {
  const url = `${host}/files/mergeVersion`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { newFileId, oldFileId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const restoreVersion = async ({ fileId, versionIndex }) => {
  const url = `${host}/files/restoreVersion`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { fileId, versionIndex },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getTranscription = async ({ id }) => {
  const url = `${host}/files/transcription/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'get',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const downloadFileAPI = async (id) => {
  try {
    const token = await getToken()
    const url = `${host}/files/${id}/download?token=${token}`

    window.open(url, '_blank')
  } catch (error) {
    console.error('Error:', error)
  }
}

export const proxyDownloadFileAPI = async (id) => {
  try {
    const token = await getToken()
    const url = `${host}/files/proxy/${id}?token=${token}`
    return url
  } catch (error) {
    console.error('Error:', error)
  }
}

export default {
  setToken,
  preSearch,
  getFiles,
  getTags,
  getPersons,
  getPerson,
  renamePerson,
  checkDuplicate,
  mergePersons,
  removePerson,
  getFilesUsers,
  getFilesExtensions,
  getPopularsTags,
  addToFavorite,
  uploadFile,
  updateThumbnail,
  addFileTag,
  bulkTag,
  bulkRemoveTags,
  removeFileTag,
  addToFileViewed,
  fileUploaded,
  getFilesByType,
  getFilesByIds,
  getAccounts,
  getMyWorkspaces,
  getAccountWorkspaces,
  get3rdPartyFolderHierarchy,
  parseBookmarkUrl,
  createFile,
  deleteFile,
  finishFileUpload,
  extractFaces,
  searchFace,
  deleteBulkFiles,
  downloadBulkFiles,
  streamDownloadBulkFiles,
  getDriveFolders,
  nameFolders,
  SyncChosenDirectories,
  SyncChosenFiles,
  countDriveFiles,
  SyncUploadDrivePendingFiles,
  SyncEnded,
  createSyncJob,
  updateSyncJob,
  updateSyncJobIncrement,
  updateSyncJobWithFiles,
  getSplitSuggestions,
  getMergeSuggestions,
  getFile,
  updateFile,
  setCDNFile,
  initUpload,
  simpleUploadFileToS3,
  getDrives,
  addCollections,
  removeCollections,
  bulkAddCollections,
  bulkRemoveCollections,
  mergeVersion,
  restoreVersion,
  checkIfFileIsPublic,
  getTranscription,
  downloadFileAPI,
  proxyDownloadFileAPI,
}
