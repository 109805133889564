import React, { useState, useEffect } from 'react'
import { MyButton } from '../../_MyComponents'
import UPLOAD from '../../../assets/upload.svg'
import DRIVE from '../../../assets/google-drive-icon.svg'
import DROPBOX from '../../../assets/dropbox_icon.svg'
import DriveSync from '../../Drive/DriveSync/DriveSync.js'
import { t, Trans } from '@lingui/macro'

const Import = ({ setDialog, setMenuState }) => (
  <div style={{ width: '100%', height: '100%' }}>
    <div className="import-container">
      <h1 className="h1">
        <Trans>Start importing files</Trans>
      </h1>

      <div className="import-buttons">
        <div className="integrations">
          <DriveSync setDialog={setDialog} setMenuState={setMenuState} />
          {false && (
            <MyButton
              variant="contained"
              color="secondary"
              startIcon={<img src={DRIVE} style={{ width: '24px' }} />}
            >
              <Trans>Import from Google Drive</Trans>
            </MyButton>
          )}
          <MyButton
            variant="contained"
            color="secondary"
            className="disabled"
            disabled
            startIcon={<img src={DROPBOX} style={{ width: '24px' }} />}
          >
            <Trans>Import from Dropbox</Trans>
          </MyButton>
        </div>
        <div className={'dropzone-area'}>
          <div className="flex-row">
            <img src={UPLOAD} alt="upload" />
            <div className="dropzone-text">
              <p className="p">
                <Trans>Upload a main folder or drag and drop it here</Trans>
              </p>
              <p className="p light">
                <Trans>Just top-level folders</Trans>
              </p>
            </div>
          </div>
          <label htmlFor="upload-input">
            <MyButton variant="contained" component="span" color="primary">
              <Trans>Upload</Trans>
            </MyButton>
          </label>
        </div>
      </div>
    </div>
  </div>
)

export default Import
