import React from 'react'
import rootStore from './RootStore' // Import the already instantiated rootStore

const stores = {
  authenticationStore: rootStore.authenticationStore,
  registerStore: rootStore.registerStore,
  dropboxImportStore: rootStore.dropboxImportStore,
  filesStore: rootStore.filesStore,
  orgsWorkspacesStore: rootStore.orgsWorkspacesStore,
  rootStore,
  thirdPartyStore: rootStore.thirdPartyStore,
}

const MobXProviderContext = React.createContext(stores)

export const StoresProvider = ({ children }) => {
  return (
    <MobXProviderContext.Provider value={stores}>
      {children}
    </MobXProviderContext.Provider>
  )
}

export const useStore = () => React.useContext(MobXProviderContext)
