import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Adjust the path as needed
import './AssignWorkspace.css'
import { t, Trans } from '@lingui/macro'

import FolderIcon from '@material-ui/icons/Folder'
import { blue } from '@material-ui/core/colors'
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'

const FoldersSelected = ({ folderSelected, removeFolder }) => (
  <div className="folders-selected">
    {[...folderSelected.values()].map((folder, idx) => {
      const { name, path_display } = folder
      return (
        <Tooltip key={`folder-${idx}`} title={path_display}>
          <Chip
            key={name}
            icon={<FolderIcon style={{ fontSize: '1rem' }} />}
            label={name}
            clickable
            color="primary"
            style={{ backgroundColor: blue[600], margin: '.3rem' }}
            onDelete={() => {
              removeFolder(folder, name)
            }}
          />
        </Tooltip>
      )
    })}
  </div>
)

const FoldersBrowser = ({
  folders,
  browseTo,
  toggleAddFolder,
  folderSelected,
}) => (
  <div className="folders">
    {Object.entries(folders).map(([name, folder]) => {
      return (
        <div
          key={name}
          className="folder"
          onDoubleClick={
            Object.keys(folder.children).length
              ? () => {
                  browseTo(folder.children)
                }
              : null
          }
        >
          <div className="folder-icon-wrapper">
            <IconButton
              className="folder-selection"
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                toggleAddFolder(folder, name)
              }}
            >
              {folderSelected.has(folder) ? (
                <CheckCircleOutlineIcon
                  className="folder-checkmark"
                  style={{ fontSize: '1rem', color: blue[50] }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className="folder-checkmark"
                  style={{ fontSize: '1rem', color: blue[50] }}
                />
              )}
            </IconButton>
            <FolderIcon style={{ fontSize: '5rem', color: blue[700] }} />
            <div className="folder-empty">
              <div>{Object.keys(folder.children).length}</div>
              <div>
                <Trans>Folders</Trans>
              </div>
            </div>
          </div>
          <div className="folder-name">{name}</div>
        </div>
      )
    })}
  </div>
)

const AssignWorkspace = observer(() => {
  const { dropboxImportStore } = useStore()

  const {
    breadcrumb,
    folders,
    parent,
    loading,
    browseTo,
    folderSelected,
    toggleAddFolder,
    removeFolder,
  } = dropboxImportStore

  folderSelected &&
    console.log(
      [...folderSelected.values()].map(JSON.stringify).map(JSON.parse)
    )
  return (
    <>
      <AppBar>
        <Toolbar style={{ backgroundColor: blue[700] }}>
          <Typography variant="h6">
            <Trans>Create Workspace</Trans>
          </Typography>
          <div
            style={{ marginLeft: '20%', minWidth: '1rem', marginTop: '.4rem' }}
          >
            <div className="folder-back-wrapper">
              <IconButton
                disabled={!parent}
                className="folder-back"
                onClick={() => {
                  if (parent) {
                    browseTo(parent)
                  }
                }}
              >
                <SubdirectoryArrowLeftIcon
                  style={{ color: blue[parent ? 100 : 600] }}
                  disabled={!parent}
                />
              </IconButton>
            </div>
          </div>
          <Breadcrumbs aria-label="breadcrumb" style={{ padding: '1rem' }}>
            {breadcrumb?.parts?.map((part) => {
              return (
                <Typography style={{ fontSize: '.8rem', color: blue[100] }}>
                  {part}
                </Typography>
              )
            })}
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div className="assign-workspace">
        <Grid container>
          <Grid item xs={3}>
            {folderSelected && (
              <FoldersSelected
                folderSelected={folderSelected}
                removeFolder={removeFolder}
              />
            )}
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ height: '100vh' }}
          />
          <Grid item xs={6}>
            <div>
              {loading ? (
                <div>
                  <Backdrop open={true}>
                    <div>
                      <Typography style={{ color: blue[900] }}>
                        Retrieving folders...
                      </Typography>
                    </div>
                    <CircularProgress style={{ color: blue[900] }} />
                  </Backdrop>
                </div>
              ) : (
                <FoldersBrowser
                  folders={folders}
                  browseTo={browseTo}
                  toggleAddFolder={toggleAddFolder}
                  folderSelected={folderSelected}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </div>
    </>
  )
})

export default AssignWorkspace
