import axios from 'axios'
import { host } from '../../../services/host'
import { getHeaderMerged } from '../util'

const getHeaderMergedLegacy = (headers) => {
  return {
    'Content-Type': 'application/json',
    ...headers,
  }
}

export const registerUser = async (user) => {
  const url = `${host}/users`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMergedLegacy(),
      data: user,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getUserState = async () => {
  const url = `${host}/state`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}
export const updateUserState = async (newState) => {
  const url = `${host}/state`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { newState },
    })
    return data
  } catch (err) {
    throw err
  }
}

export default {
  registerUser,
  getUserState,
  updateUserState,
}
