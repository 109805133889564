import React from 'react'
import { NewLaneSection, AddLaneLink } from './MyGlobalStyle'
import ADD from '../../assets/add.svg'
import './TagsManagment.scss'
import { t, Trans } from '@lingui/macro'

export default ({ t, onClick }) => (
  <NewLaneSection className="new-lane">
    <AddLaneLink t={t} onClick={onClick}>
      <img src={ADD} className="addButton" alt="Add Category" />
      <p>
        <Trans>Add Category</Trans>
      </p>
    </AddLaneLink>
  </NewLaneSection>
)
