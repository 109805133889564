import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { t, Trans } from '@lingui/macro'

import { MyKeyword, MyTabs, MyTab, MyButton } from '../../_MyComponents'
import TextField from '@material-ui/core/TextField'
import Slider from '@mui/material/Slider'
import { debounce } from 'lodash'

import Divider from '@material-ui/core/Divider'
import { fileSizeFormatter } from '../../../util/fileSizeFormatter'

const ExportTab = ({
  // from store

  // from parent
  file,
  c,
  createCroppedImage,
  downloadCroppedImage,
  aspectRatio,
  setAspectRatio,
  exportFormat,
  setExportFormat,
  exportQuality,
  setExportQuality,
  crop,
  setCrop,
  resize,
  setResize,
  ratios,
  cropperRef,
}) => {
  //////////////////////////////////////
  ///// Export
  //////////////////////////////////////

  const formats = [{ name: 'jpeg' }, { name: 'png' }, { name: 'webp' }]
  const [fileSize, setFileSize] = useState(fileSizeFormatter(file?.size))

  // create a function that uses debounce after crop changes and uses createCroppedImage to get the size of the cropped image
  useEffect(() => {
    if (!crop || !createCroppedImage) return
    const debounced = debounce(async () => {
      const blob = await createCroppedImage()
      const tempSize = fileSizeFormatter(blob?.size) || '0 B'
      setFileSize(tempSize)
    }, 100)

    debounced()

    return () => {
      debounced.cancel()
    }
  }, [
    resize,
    exportQuality,
    aspectRatio,
    exportFormat,
    crop?.width,
    crop?.height,
    crop?.top,
    crop?.left,
  ])

  return (
    <div className={'export-menu'}>
      <div className="section add-gap">
        <div>
          <h3 className={'h3'}>
            <Trans>Crop</Trans>
          </h3>
          <div className="details-tags">
            {ratios.map((ratio, idx) => {
              return (
                <MyKeyword
                  key={`ratio-${idx}`}
                  text={`${ratio.name}`}
                  onClick={() => {
                    setAspectRatio(ratio)
                  }}
                  selected={aspectRatio.name === ratio.name}
                />
              )
            })}
          </div>
        </div>
        <div>
          <h3 className={'h3'} style={{ marginBottom: '16px' }}>
            <Trans>Dimensions</Trans>
          </h3>
          <div className="resize-row spaced">
            <TextField
              fullWidth
              label={t`Width`}
              type="number"
              value={crop?.width}
              onChange={(e) => {
                setCrop({ ...crop, width: e.target.value })
                if (cropperRef.current) {
                  cropperRef.current.setCoordinates({
                    width: e.target.value,
                  })
                }
              }}
              InputProps={{
                endAdornment: <span>px</span>,
                inputProps: { min: 0, max: file.metadata?.width },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
            />
            <TextField
              fullWidth
              label={t`Height`}
              type="number"
              value={crop?.height}
              onChange={(e) => {
                setCrop({ ...crop, height: e.target.value })
                if (cropperRef.current) {
                  if (aspectRatio.name !== 'Freeform') {
                    cropperRef.current.setCoordinates({
                      height: e.target.value,
                      width: (e.target.value * aspectRatio).toFixed(0),
                    })
                  } else {
                    cropperRef.current.setCoordinates({
                      height: e.target.value,
                    })
                  }
                }
              }}
              InputProps={{
                endAdornment: <span>px</span>,
                inputProps: { min: 0, max: file.metadata?.height },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
      </div>

      <Divider className="divider" sx={{ height: 0 }} />

      <div className="section add-gap">
        <div>
          <h3 className={'h3'} style={{ marginBottom: '16px' }}>
            <Trans>Resize</Trans>
          </h3>
          <div className="resize-row">
            <TextField
              label={t`Percent`}
              type="number"
              value={resize}
              onChange={(e) => {
                setResize(e.target.value > 100 ? 100 : e.target.value) // limit to 100
              }}
              InputProps={{
                endAdornment: (
                  <span>
                    <Trans>%</Trans>
                  </span>
                ),
                inputProps: { min: 0, max: 100 },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              size="small"
            />
          </div>
        </div>
        <div>
          <h3 className={'h3'}>
            <Trans>Quality</Trans>
          </h3>
          <div className="resize-row">
            <Slider
              size="small"
              value={exportQuality}
              aria-label="Quality slider"
              valueLabelDisplay="auto"
              step={10}
              onChange={(e, val) => {
                setExportQuality(val)
              }}
              // change color to yellow
              sx={{
                color: '#FBC02D',
              }}
            />
          </div>
          <div>
            <div className="resize-row centered">
              <p>
                <Trans>Image size: </Trans>{' '}
              </p>
              <p style={{ fontWeight: 700 }}>{fileSize}</p>
            </div>
          </div>
        </div>
      </div>

      <Divider className="divider" sx={{ height: 0 }} />

      <div className="section">
        <h3 className={'h3'}>
          <Trans>Format</Trans>
        </h3>
        <div className="details-tags">
          {formats.map((format, idx) => {
            return (
              <MyKeyword
                key={`format-${idx}`}
                text={`${format.name}`}
                selected={format.name === exportFormat}
                onClick={() => {
                  setExportFormat(format.name)
                }}
              />
            )
          })}
        </div>
      </div>

      <Divider className="divider" sx={{ height: 0 }} />

      <MyButton
        className="export-button"
        variant="contained"
        color="primary"
        onClick={() => {
          downloadCroppedImage()
        }}
        // make button full width
        sx={{ width: '100%' }}
        size={'medium'}
      >
        <Trans>Export</Trans>
      </MyButton>
    </div>
  )
}

export default ExportTab
