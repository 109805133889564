import React, { useEffect } from 'react'
import { MyKeyword } from '../_MyComponents'
import './SearchByColor.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

const SearchByColor = observer(() => {
  const { filesStore } = useStore()

  const {
    selectedColors,
    onColorSelected,
    selectedAdvanceColorFilter,
    onAdvanceColorFilterSelected,
  } = filesStore

  const onColorClicked = (newColor) => {
    const selected = selectedColors.find((color) => color.key === newColor.key)

    if (selected) {
      let newSelectedColors = selectedColors.filter(
        (color) => color.key != newColor.key
      )
      onColorSelected(newSelectedColors)
    } else {
      let newSelectedColors = selectedColors.concat(newColor)
      onColorSelected(newSelectedColors)
    }
  }

  const onAdvanceColorFilterClicked = (filter) => {
    const selected = selectedAdvanceColorFilter?.name === filter.name

    if (selected) {
      onAdvanceColorFilterSelected({ name: null })
    } else {
      onAdvanceColorFilterSelected(filter)
    }
  }

  return (
    <div className="filter-content-wrapper">
      <div className="filter-section">
        <div className="filter-title">
          <h2 className="h2">
            <Trans>Styles</Trans>
          </h2>
        </div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: t`Warm`, key: 'warm' })
            }
            selected={selectedAdvanceColorFilter?.key === 'warm'}
            text={t`Warm`}
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: t`Cold`, key: 'cold' })
            }
            selected={selectedAdvanceColorFilter?.key === 'cold'}
            text={t`Cold`}
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: t`Bright`, key: 'bright' })
            }
            selected={selectedAdvanceColorFilter?.key === 'bright'}
            text={t`Bright`}
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: t`Dark`, key: 'dark' })
            }
            selected={selectedAdvanceColorFilter?.key === 'dark'}
            text={t`Dark`}
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({
                name: t`Solid BG`,
                key: 'solid_background',
              })
            }
            selected={selectedAdvanceColorFilter?.key === 'solid_background'}
            text={t`Solid BG`}
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({
                name: t`Blurry BG`,
                key: 'blurry_background',
              })
            }
            selected={selectedAdvanceColorFilter?.key === 'blurry_background'}
            text={t`Blurry BG`}
          />
        </div>
      </div>
      <div className="filter-section">
        <div className="filter-title">
          <h2 className="h2">
            <Trans>Colors</Trans>
          </h2>
        </div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Red`,
                key: 'red',
                avatarColor: '#EC4237',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'red')}
            text={t`Red`}
            avatarColor="#EC4237"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Orange`,
                key: 'orange',
                avatarColor: '#F08040',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'orange')}
            text={t`Orange`}
            avatarColor="#F08040"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Brown`,
                key: 'brown',
                avatarColor: '#B78143',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'brown')}
            text={t`Brown`}
            avatarColor="#B78143"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Yellow`,
                key: 'yellow',
                avatarColor: '#F5D153',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'yellow')}
            text={t`Yellow`}
            avatarColor="#F5D153"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Green`,
                key: 'green',
                avatarColor: '#30BA24',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'green')}
            text={t`Green`}
            avatarColor="#30BA24"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Cyan`,
                key: 'cyan',
                avatarColor: '#6CD5B8',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'cyan')}
            text={t`Cyan`}
            avatarColor="#6CD5B8"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Blue`,
                key: 'blue',
                avatarColor: '#4A8BF7',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'blue')}
            text={t`Blue`}
            avatarColor="#4A8BF7"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Purple`,
                key: 'purple',
                avatarColor: '#8B38F0',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'purple')}
            text={t`Purple`}
            avatarColor="#8B38F0"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Pink`,
                key: 'pink',
                avatarColor: '#ED6BC8',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'pink')}
            text={t`Pink`}
            avatarColor="#ED6BC8"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`White`,
                key: 'white',
                avatarColor: '#FFFFFF',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'white')}
            text={t`White`}
            avatarColor="#FFFFFF"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Grey`,
                key: 'grey',
                avatarColor: '#B4BAC0',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'grey')}
            text={t`Grey`}
            avatarColor="#B4BAC0"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: t`Black`,
                key: 'black',
                avatarColor: '#000000',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.key === 'black')}
            text={t`Black`}
            avatarColor="#000000"
            avatar={'Empty'}
          />
        </div>
      </div>
    </div>
  )
})

export default SearchByColor
