import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { host } from '../../services/host'
import './Unsubscribe.scss'
import { observer } from 'mobx-react-lite'

const Unsubscribe = observer(() => {
  const { username } = useParams()

  const unsubscribeAPI = async (username) => {
    const url = `${host}/users/unsubscribe/${username}`
    try {
      const { data } = await axios({
        url,
        method: 'GET',
      })
      return data
    } catch (err) {
      throw err
    }
  }

  unsubscribeAPI(username)

  return <div>{username} is now Unsubscribed</div>
})

export default Unsubscribe
