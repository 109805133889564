import { TOKEN_KEYWORD } from './consts'
import storage from '../services/storage'

const getAuthorization = () => {
  const { thirdPartyDetails } = storage.get(TOKEN_KEYWORD) || {
    thirdPartyDetails: {},
  }
  const { token } = thirdPartyDetails
  return `${token}`
}

export default getAuthorization
