import React, { useState, useEffect, useRef } from 'react'
import './Filters.scss'
import './Filters.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import IconButton from '@material-ui/core/IconButton'
import { MyKeyword } from '../../_MyComponents'

import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import PhotoSearch from '../../../assets/photo-search-icon.svg'
import AssetType from '../../../assets/AssetType.svg'
import ColorFilter from '../../../assets/ColorFilter.svg'
import UploadDetails from '../../../assets/UploadDetails.svg'
import People from '../../../assets/People.svg'

import SearchByColor from '../../SearchByColor/SearchByColor.js'
import SearchByUpload from '../../SearchByUpload/SearchByUpload.js'
import SearchByType from '../../SearchByType/SearchByType.js'
import SearchByPeople from '../../SearchByPeople/SearchByPeople.js'

import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import SimpleUpload from '../../SimpleUpload/SimpleUpload.js'

const Filters = observer(
  ({
    // from parent
    handleShowAll,
  }) => {
    const { filesStore } = useStore()

    const { onImageSearch } = filesStore

    const [filterOpen, setFilterOpen] = useState(false)
    const [uploadOpen, setUploadOpen] = useState(false)
    const anchorRef = useRef(null)

    const handleClose = (event) => {
      if (
        anchorRef.current &&
        (anchorRef.current.contains(event.target) ||
          event.target.className === 'filter-icon')
      ) {
        return
      }
      setFilterOpen(null)
    }

    const showAll = () => {
      handleShowAll()
      setFilterOpen(false)
    }

    const colorsContent = <SearchByColor />

    const uploadContent = <SearchByUpload />

    const peopleContent = <SearchByPeople handleShowAll={showAll} />

    const typeContent = <SearchByType />

    const content = (filterOpen) => {
      switch (filterOpen) {
        case 'color':
          return colorsContent
        case 'people':
          return peopleContent
        case 'type':
          return typeContent
        case 'upload':
          return uploadContent
        default:
          return false
      }
    }

    const popup = (
      <Popper
        open={!!filterOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div className="dropdown-options-container">
                  {content(filterOpen)}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    )

    return (
      <div className="filters-wrapper">
        <Tooltip enterDelay={750} title={t`Image search`}>
          <IconButton
            component="span"
            size="small"
            onClick={() => setUploadOpen(true)}
          >
            <img src={PhotoSearch} className="filter-icon" alt="image search" />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Tooltip enterDelay={750} title={t`File properties filter`}>
          <IconButton
            size="small"
            ref={anchorRef}
            onClick={() => {
              setFilterOpen('type')
            }}
          >
            <img src={AssetType} className="filter-icon" alt="type filter" />
          </IconButton>
        </Tooltip>

        <Tooltip enterDelay={750} title={t`Date filter`}>
          <IconButton
            size="small"
            ref={anchorRef}
            onClick={() => {
              setFilterOpen('upload')
            }}
          >
            <img
              src={UploadDetails}
              className="filter-icon"
              alt="upload details filter"
            />
          </IconButton>
        </Tooltip>

        <Tooltip enterDelay={750} title={t`People filter`}>
          <IconButton
            size="small"
            ref={anchorRef}
            onClick={() => {
              setFilterOpen('people')
            }}
          >
            <img src={People} className="filter-icon" alt="people filter" />
          </IconButton>
        </Tooltip>

        <Tooltip enterDelay={750} title={t`Style filter`}>
          <IconButton
            size="small"
            ref={anchorRef}
            onClick={() => {
              setFilterOpen('color')
            }}
          >
            <img src={ColorFilter} className="filter-icon" alt="color filter" />
          </IconButton>
        </Tooltip>

        {popup}

        {uploadOpen && (
          <SimpleUpload
            title={t`Search by image`}
            buttonText={t`Search Image`}
            fileSpec={t`JPG, PNG, or other`}
            close={() => setUploadOpen(false)}
            isOpen={uploadOpen}
            endFunction={onImageSearch}
          />
        )}
      </div>
    )
  }
)

export default Filters
