import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store/StoresProvider.js'
import { t, Trans, Plural } from '@lingui/macro'

import { MyKeyword } from '../../_MyComponents'
import { uniqBy } from 'lodash'
import TagList from '../../TagList/TagList.js'
import { CSSTransition } from 'react-transition-group'
import Person from '../../Person/Person.js'
import Files from '../../Files/Files.js'

import { Steps } from 'intro.js-react'
import { steps2 } from '../../_intro-js'
import Divider from '@material-ui/core/Divider'
import { RULES } from '../../../store/OrgsWorkspacesStore/permissions'

const UploadTagging = observer(
  ({
    // from parent
    appliedTags,
    setAppliedTags,
    appliedCollections,
    setAppliedCollections,
  }) => {
    const { filesStore, authenticationStore, orgsWorkspacesStore } = useStore()

    const { recentlyUploadedFiles, onRemoveTag } = filesStore

    const { userState, updateUserState, isAnonymous } = authenticationStore

    const { isAllowed } = orgsWorkspacesStore

    const [tagListType, setTagListType] = useState('REGULAR')
    const [newPersons, setNewPersons] = useState([])
    const [anchorAll, setAnchorAll] = useState(null)

    const [introJS, setIntroJS] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setIntroJS(true)
      }, 1000)
    }, [])

    const onCloseIntro = () => {
      setIntroJS(false)
      updateUserState({ 'onboarding.autoTags': true })
    }

    const anchorOrigin = {
      vertical: 'bottom',
      horizontal: 0,
    }

    ///////////////////////////////
    //////  HANDLE PERSONS
    ///////////////////////////////

    const getPersons = () => {
      let tempFilePersons = recentlyUploadedFiles.map((file) => {
        const persons = file.persons ? [...file.persons] : []
        return persons
      })

      tempFilePersons = tempFilePersons
        .flat()
        .filter((p) => p.removed === false)
        .filter(
          (p) =>
            //only show person that have defaultFaceLocation
            p.defaultFaceLocation && p.defaultFaceLocation.length > 0
        )

      tempFilePersons = tempFilePersons.map((person) => {
        var count = tempFilePersons.filter(
          (p) => p._id.toString() === person._id.toString()
        ).length
        return { ...person, count }
      })

      const fullFilePersons = uniqBy(tempFilePersons, '_id')

      console.log({ fullFilePersons })

      const filePersons =
        !!fullFilePersons?.length > 0 &&
        fullFilePersons
          //.sort((a, b) => b.count - a.count)
          .map((person, idx) => (
            <div className="flex-col" key={person._id}>
              <Person
                isClickable={false}
                key={`person-${person._id}`}
                person={person}
                itemId={`${person._id}-k`}
                //setView={setView}
              />
              <p className="p tag-count">
                <Plural
                  value={person.count}
                  one={`Found in 1 file`}
                  other={`Found in # files`}
                />
              </p>
            </div>
          ))

      // get new keywords that don't have existing tags
      setNewPersons(filePersons)

      //remove walkthrough step if no new people
      if (!filePersons.length && steps2.length === 3) {
        steps2.splice(1, 1)
      }
    }

    useEffect(() => {
      getPersons()
    }, [])

    useEffect(() => {
      getPersons()
    }, [recentlyUploadedFiles])

    const fileTags = (file) => {
      const filetags = recentlyUploadedFiles.find(
        (it) => it.fileId === file.fileId
      ).tags
      return filetags.map((tag) => {
        return (
          <CSSTransition key={tag._id} classNames="kw-animation" timeout={3000}>
            <MyKeyword
              text={tag.name}
              key={`key_${tag.name}`}
              onClick={() =>
                onRemoveTag({ fileId: file._id, tagsIds: [tag._id] })
              }
            />
          </CSSTransition>
        )
      })
    }

    const addTagToAll = ({ tags }) => {
      setAppliedTags(tags)
    }

    const removeTagFromAll = ({ tags }) => {
      //ugly workaround so not to change the main functionality of the TagList
      setAppliedTags(appliedTags.filter((tag) => tag !== tags[0]))
    }

    const addCollectionToAll = ({ collections }) => {
      setAppliedCollections(collections)
    }

    const removeCollectionFromAll = ({ collection }) => {
      //ugly workaround so not to change the main functionality of the TagList
      setAppliedCollections(
        appliedCollections.filter((col) => col._id !== collection._id)
      )
    }

    const keywords =
      appliedTags.length > 0
        ? appliedTags
            .slice()
            .sort((a, b) => b.count - a.count)
            .map((tag, idx) => {
              const { name } = tag
              return (
                <MyKeyword
                  key={`tag-${idx}`}
                  text={name}
                  onDelete={() => removeTagFromAll({ tags: [tag] })}
                />
              )
            })
        : ''

    const collections =
      appliedCollections.length > 0
        ? appliedCollections
            .slice()
            .sort((a, b) => b.count - a.count)
            .map((col, idx) => {
              const { name } = col
              return (
                <MyKeyword
                  key={`tag-${idx}`}
                  text={name}
                  onDelete={() => removeCollectionFromAll({ collection: col })}
                />
              )
            })
        : ''

    const previewFiles = <Files uploadMode={true} />

    return (
      <div className="autotags-container">
        <div className="autotags-box">
          <h1 className="h1">
            <Trans>Organize</Trans>
          </h1>

          {isAllowed(RULES.ADD_TAG) && (
            <div className="autotag-section">
              <div className="autotag-header">
                <h2 className="h2">
                  <Trans>Tags</Trans>
                </h2>
                <p className="p">
                  <Trans>Apply tags to all files</Trans>
                </p>
              </div>
              <span className="tag-placeholder">{keywords}</span>
              <div
                className="add-tag-button"
                onClick={() => {
                  setTagListType('REGULAR')
                  //querySelector is a workaround because selecting the ref breaks the popover

                  setAnchorAll(
                    document.querySelector(
                      '.autotag-section:nth-of-type(1) .add-tag-button'
                    )
                  )
                }}
              >
                <Trans>+ Add tags</Trans>
              </div>
            </div>
          )}

          <Divider className="divider" sx={{ height: 0 }} />

          {isAllowed(RULES.ADD_TO_COLLECTION) && (
            <div className="autotag-section">
              <div className="autotag-header">
                <h2 className="h2">
                  <Trans>Collections</Trans>
                </h2>
                <p className="p">
                  <Trans>Add all assets to one or more collections</Trans>
                </p>
              </div>
              <span className="tag-placeholder">{collections}</span>
              <div
                className="add-tag-button"
                onClick={() => {
                  setTagListType('COLLECTION')
                  //querySelector is a workaround because selecting the ref breaks the popover
                  setAnchorAll(
                    document.querySelector(
                      '.autotag-section:nth-of-type(2) .add-tag-button'
                    )
                  )
                }}
              >
                <Trans>+ Add to collections</Trans>
              </div>
            </div>
          )}

          {newPersons.length && (
            <Divider className="divider" sx={{ height: 0 }} />
          )}

          {newPersons.length && (
            <div className="autotag-section">
              <div className="autotag-header">
                <h2 className="h2">
                  <Trans>Name people in images</Trans>
                </h2>
              </div>
              <div className="persons-content-container">
                <div className="persons-content">{newPersons}</div>
              </div>
            </div>
          )}
        </div>
        <div className="upload-files-box">{previewFiles}</div>
        <TagList
          type={tagListType}
          anchor={anchorAll}
          anchorOrigin={anchorOrigin}
          onClose={() => setAnchorAll(null)}
          /* need to change */
          fileId={'000'}
          id={`${tagListType}_tagList`}
          tags={appliedTags}
          onAddTag={addTagToAll}
          onRemoveTag={removeTagFromAll}
          collections={appliedCollections}
          onAddCollection={addCollectionToAll}
          onRemoveCollection={removeCollectionFromAll}
        />
        {!isAnonymous && userState && !userState?.onboarding?.autoTags && (
          <Steps
            enabled={introJS}
            steps={steps2}
            initialStep={0}
            onExit={onCloseIntro}
            options={{
              exitOnOverlayClick: false,
              doneLabel: 'Start tagging',
            }}
          />
        )}
      </div>
    )
  }
)
export default UploadTagging
