import React from 'react'
import { observer } from 'mobx-react-lite'
import { t, Trans } from '@lingui/macro'

import './ActionDialog.scss'
import { MyButton } from '../_MyComponents'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook if needed

const ActionDialog = observer(
  ({
    // from parent
    actionName,
    action,
    question,
    image,
    comment,
    comments,
    cancelName,
    cancel,
    color,
    isOpen,
    list,
    disabled,
    disabledText,
  }) => {
    const { someStore } = useStore() // Use the store if needed

    function handleCancel(e) {
      e.stopPropagation()
      cancel()
    }

    function handleAction(e) {
      e.stopPropagation()
      action()
    }

    return (
      <Dialog
        onClose={cancel}
        //fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <div className="action-dialog-container">
          <div className="action-dialog">
            <div className="action-dialog-title">{question}</div>
            {image && (
              <div className="action-dialog-img">
                <img src={image} alt="popup-image" />
              </div>
            )}
            {comment && <p className="p">{comment}</p>}
            {comments && (
              <div>
                {comments?.map((comment, idx) => (
                  <p className="p" key={idx}>
                    {comment}
                  </p>
                ))}
              </div>
            )}
            {list?.length > 0 && (
              <div className="list-dialog">
                <ul>
                  {list?.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="action-buttons">
              {cancel && (
                <MyButton
                  variant="contained"
                  color="secondary"
                  onClick={(e) => handleCancel(e)}
                >
                  {cancelName ? cancelName : t`Cancel`}
                </MyButton>
              )}
              <Tooltip title={disabledText ? disabledText : ''} placement="top">
                <MyButton
                  variant="contained"
                  color={color ? 'inherit' : 'primary'}
                  onClick={(e) => handleAction(e)}
                  className={color}
                  disabled={disabled}
                >
                  {actionName}
                </MyButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
)

export default ActionDialog
