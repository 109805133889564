import React from 'react'
import PropTypes from 'prop-types'
import InlineInput from 'react-trello/dist/widgets/InlineInput'
import { Title, LaneHeader, RightContent } from '../MyGlobalStyle'
import MyLaneMenu from './MyLaneMenu'

const MyLaneHeader = ({
  sortBy,
  sortOrder,
  resortCategory,
  onDeleteCategory,
  id,
  updateTitle,
  canAddLanes,
  onDelete,
  onDoubleClick,
  editLaneTitle,
  label,
  title,
  titleStyle,
  labelStyle,
  t,
  laneDraggable,
  isRemovable,
  cards,
}) => {
  return (
    <LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle}>
      <Title draggable={laneDraggable} style={titleStyle}>
        {editLaneTitle ? (
          <InlineInput
            value={title}
            border
            placeholder={t('placeholder.title')}
            resize="vertical"
            onSave={updateTitle}
          />
        ) : (
          title
        )}
      </Title>
      {label && (
        <RightContent>
          <span style={labelStyle}>{label}</span>
        </RightContent>
      )}
      {canAddLanes && isRemovable && (
        <MyLaneMenu
          resortCategory={resortCategory}
          onDelete={onDeleteCategory}
          sortBy={sortBy}
          sortOrder={sortOrder}
          catId={id}
          cards={cards}
          t={t}
          //onDelete={onDelete}
        />
      )}
    </LaneHeader>
  )
}

MyLaneHeader.propTypes = {
  updateTitle: PropTypes.func,
  editLaneTitle: PropTypes.bool,
  canAddLanes: PropTypes.bool,
  laneDraggable: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onDoubleClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  isRemovable: PropTypes.bool,
}

MyLaneHeader.defaultProps = {
  updateTitle: () => {},
  editLaneTitle: false,
  canAddLanes: false,
}

export default MyLaneHeader
