export const EXT = {
  docs: ['doc', 'docx', 'odt', 'rtf', 'tex', 'txt', 'wpd', 'gdoc'],
  pdf: ['pdf'],
  sheets: ['csv', 'xml', 'ods', 'xls', 'xlsm', 'xlsx', 'sql', 'db', 'gsheet'],
  slides: ['ppt', 'pptx', 'key', 'odp', 'pps', 'gslide'],
  images: [
    'ai',
    'bmp',
    'gif',
    'ico',
    'jpeg',
    'jpg',
    'png',
    'ps',
    'psd',
    'svg',
    'tif',
    'tiff',
    'heic',
    'heif',
    'jfif',
    'webp',
    'eps',
    'avif',
  ],
  audio: [
    'aif',
    'cda',
    'mid',
    'midi',
    'mp3',
    'mpa',
    'ogg',
    'wav',
    'wma',
    'wpl',
    'm4a',
  ],
  videos: [
    '3g2',
    '3gp',
    'h264',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpg',
    'mpeg',
    'rm',
    'swf',
    'vob',
    'wmv',
    'webm',
  ],
  links: ['www'],
  vectors: ['ai', 'svg', 'eps'],
}

export const EXT2TYPE = () => {
  const res = {}
  Object.keys(EXT).forEach((type) => {
    for (let i = 0; i < EXT[type].length; i++) {
      res[EXT[type][i]] = type
    }
  })
  return res
}
