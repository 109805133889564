import { makeAutoObservable, runInAction } from 'mobx'
import history from '../../services/history'
import { LOGIN } from '../../util/routes'
import { dispatch, subscribe } from '../../services/pubsub'
import Actions from '../../util/actions'
import logger from '../../services/logger'
import { USER_TOKEN } from '../../util/consts'
import isEmail from 'validator/lib/isEmail'
import { createDecipheriv } from 'crypto-browserify'

const algorithm = 'aes-256-ctr'
const CRYPTO_SECRET_KEY = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'

class AuthenticationStore {
  doesUserAuthenticated = false
  token = ''
  username = ''
  password = ''
  message = ''
  whenLoginRouteUrl = ''
  logoutUrl = ''
  loginOnProcess = false
  showPassword = false
  isAnonymous = false
  userState = null
  isSuperAdmin = false
  emailFromInvitation = false

  constructor({
    doesUserAuthenticated,
    token,
    username,
    password,
    message,
    whenLoginRouteUrl,
    loginOnProcess,
    logoutUrl,
    showPassword,
    isAnonymous,
  }) {
    // Initialize properties based on constructor args
    this.doesUserAuthenticated = doesUserAuthenticated || false
    this.token = token || ''
    this.username = username || ''
    this.password = password || ''
    this.message = message || ''
    this.whenLoginRouteUrl = whenLoginRouteUrl || ''
    this.logoutUrl = logoutUrl || ''
    this.loginOnProcess = loginOnProcess || false
    this.showPassword = showPassword || false
    this.isAnonymous = isAnonymous || false
    makeAutoObservable(this)

    this.credentialChange = this.credentialChange.bind(this)
    this.login = this.login.bind(this)
    this.anonymouseLogin = this.anonymouseLogin.bind(this)
    this.sso_login = this.sso_login.bind(this)
    this.googleLogin = this.googleLogin.bind(this)
    this.HITLogin = this.HITLogin.bind(this)
    this.travelcounsellorsLogin = this.travelcounsellorsLogin.bind(this)
    this.bonareaLogin = this.bonareaLogin.bind(this)
    this.bgstrLogin = this.bgstrLogin.bind(this)
    this.brownhavenLogin = this.brownhavenLogin.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
    this.logout = this.logout.bind(this)
    this.verifyUserAuthenticated = this.verifyUserAuthenticated.bind(this)
    this.toggleShowPassword = this.toggleShowPassword.bind(this)
    this.verifyUser = this.verifyUser.bind(this)
    this.storeUserDetails = this.storeUserDetails.bind(this)
    this.getUserState = this.getUserState.bind(this)
    this.updateUserState = this.updateUserState.bind(this)

    subscribe(Actions.UNAUTHORIZED, this.logout)
    subscribe(Actions.GET_CURRENT_USER, this.verifyUser)
    subscribe(LOGIN, ({ email }) => {
      runInAction(() => {
        if (email && isEmail(email)) {
          this.username = email
          this.emailFromInvitation = true
        }
      })
    })
  }

  credentialChange = ({ username, password }) => {
    runInAction(() => {
      this.username = username
      this.password = password
    })
  }

  verifyUser() {
    this.rootStore.apis
      .verifyUser()
      .then((user) => {
        console.log(user)
        runInAction(() => {
          this.doesUserAuthenticated = true
          this.username = user.email
          this.isAnonymous = user.isAnonymous
        })
        this.rootStore.loggedIn()
      })
      .catch((error) => {
        console.log('verifyUser error')
        console.log(error)
        //special case for sso login
        if (window.location && window.location.search != '') {
          let query = new URLSearchParams(window.location.search)
          let iv = query.get('iv')
          if (iv) {
            return
          }
        }

        this.rootStore.loggedIn()
        /*
        runInAction(() => {
          this.doesUserAuthenticated = false;
          logger.error(error);
          const { pathname, search } = window.location;
          history.push(
            `${pathname === "/register" ? pathname : this.logoutUrl}${search}`
          );
        });
        */
      })
  }

  async logout() {
    this.rootStore.apis.logout().then(() => {
      logger.log('User logout')
      runInAction(() => {
        this.doesUserAuthenticated = false
        this.emailFromInvitation = false
        this.rootStore.logout()
        history.push(this.logoutUrl)
      })
    })
  }

  async storeUserDetails({
    token,
    username,
    userId,
    refreshToken,
    isAnonymous,
  }) {
    this.rootStore.storage.set(USER_TOKEN, {
      token,
      username,
      userId,
      refreshToken,
    })
    runInAction(() => {
      this.username = username
      this.isAnonymous = isAnonymous
    })
  }

  getUserState = async () => {
    //console.log('getUserState')
    try {
      const userState = await this.rootStore.apis.getUserState()
      runInAction(() => {
        this.userState = userState
        this.isSuperAdmin = userState?.userId?.superAdmin || false
      })
    } catch (err) {
      console.error(err)
    }
  }

  async updateUserState(newState) {
    //console.log({ newState })
    const userState = await this.rootStore.apis.updateUserState(newState)
    runInAction(() => {
      this.userState = userState
      this.isSuperAdmin = userState?.userId?.superAdmin || false
    })
  }

  resetPassword(email) {
    return this.rootStore.apis.resetPassword(email)
  }

  login() {
    const { username, password } = this
    runInAction(() => {
      this.loginOnProcess = true
    })
    this.rootStore.apis
      .login({ username, password })
      .then(async (user) => {
        if (!user?.refreshToken) {
          throw Error('Login failed!')
        }
        const token = await user.getIdToken(false)
        this.storeUserDetails({
          email: user.email,
          refreshToken: user.refreshToken,
          token,
          userId: user.uid,
          isAnonymous: false,
        })
        await this.getUserState()

        runInAction(() => {
          this.username = user.email
          this.password = ''
          this.loginOnProcess = false
          this.doesUserAuthenticated = true
          this.emailFromInvitation = false
          this.rootStore.apis.registerUser({ token }).then(() => {
            dispatch(Actions.USER_LOGGED_IN, { user })
          })
        })
      })
      .catch((error) => {
        //console.error(error)
        runInAction(() => {
          //console.log({ error })
          if (error.code === 'auth/user-not-found') {
            this.message =
              "We couldn't find this email. Please check for typos or sign up by clicking the button below."
          } else if (error.code === 'auth/wrong-password') {
            this.message = 'Wrong password, please try again.'
          } else if (error.code === 'auth/too-many-requests') {
            this.message = 'Too many login attempts, please try again later.'
          } else if (error.code === 'auth/invalid-email') {
            this.message = 'Invalid email. Please check for typos.'
          } else {
            this.message = error.message || 'Login failed!'
          }
          this.loginOnProcess = false
        })
      })
  }

  async anonymouseLogin() {
    try {
      runInAction(() => {
        this.loginOnProcess = true
      })
      const user = await this.rootStore.apis.loginAnonymously()
      if (!user?.refreshToken) {
        throw Error('Login failed!')
      }
      const token = await user.getIdToken(false)
      await this.storeUserDetails({
        //email: user.email,
        refreshToken: user.refreshToken,
        token,
        userId: user.uid,
        isAnonymous: true,
      })
      await this.rootStore.apis.registerUser({ token })
      runInAction(async () => {
        this.username = ''
        this.password = ''
        this.loginOnProcess = false
        this.doesUserAuthenticated = true
        this.emailFromInvitation = false
      })
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.message = error.message || 'Login failed!'
        this.loginOnProcess = false
      })
    }
  }

  sso_login(params) {
    const decipher = createDecipheriv(
      algorithm,
      CRYPTO_SECRET_KEY,
      Buffer.from(params.iv, 'hex')
    )
    const decrpyted = Buffer.concat([
      decipher.update(Buffer.from(params.content, 'hex')),
      decipher.final(),
    ])

    const user_string = decrpyted.toString()
    const { username, password, workspaceId } = JSON.parse(user_string)

    runInAction(() => {
      this.loginOnProcess = true
    })

    this.rootStore.apis
      .login({ username, password })
      .then(async (user) => {
        if (!user?.refreshToken) {
          throw Error('Login failed!')
        }
        const token = await user.getIdToken(false)
        this.storeUserDetails({
          email: user.email,
          refreshToken: user.refreshToken,
          token,
          userId: user.uid,
          isAnonymous: false,
        })
        await this.getUserState()

        runInAction(() => {
          this.username = username
          this.password = ''
          this.loginOnProcess = false
          this.doesUserAuthenticated = true
          this.emailFromInvitation = false
          this.rootStore.apis.registerUser({ token }).then(() => {
            //dispatch(Actions.USER_LOGGED_IN, { user });
            history.push(`/w/${workspaceId}`)
            this.rootStore.orgsWorkspacesStore.verifyOrganization()
          })
        })
      })
      .catch((error) => {
        //console.error(error)
        runInAction(() => {
          this.message = error.message || 'Login failed!'
          this.loginOnProcess = false
        })
      })
  }

  async googleLogin() {
    try {
      runInAction(() => {
        this.loginOnProcess = true
      })
      const user = await this.rootStore.apis.signInWithGoogle()
      //console.log('logged in with google')
      if (!user?.refreshToken) {
        throw Error('Login failed!')
      }
      const token = await user.getIdToken(false)
      await this.storeUserDetails({
        email: user.email,
        refreshToken: user.refreshToken,
        token,
        userId: user.uid,
        isAnonymous: false,
      })

      await this.rootStore.apis.registerUser({ token })
      runInAction(async () => {
        this.username = user.email
        this.password = ''
        this.loginOnProcess = false
        this.doesUserAuthenticated = true
        this.emailFromInvitation = false
        dispatch(Actions.USER_LOGGED_IN, { user })
      })

      await this.getUserState()
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.message = error.message || 'Login failed!'
        this.loginOnProcess = false
      })
    }
  }

  async HITLogin() {
    try {
      console.log('HITLogin')
      runInAction(() => {
        this.loginOnProcess = true
      })
      console.log('loginOnProcess')
      console.log('this.rootStore.apis.signInWithHIT()')
      const user = await this.rootStore.apis.signInWithHIT()
      console.log({ 'HITLogin user': user })
      console.log('logged in with HIT')

      if (!user?.stsTokenManager?.refreshToken) {
        throw Error('Login failed!')
      }

      //const token = await user.getIdToken(false);

      await this.storeUserDetails({
        email: user.email,
        refreshToken: user.stsTokenManager.refreshToken,
        token: user.idToken,
        userId: user.uid,
        isAnonymous: false,
      })

      await this.rootStore.apis.registerUser({
        token: user.idToken,
        userEmail: user.email,
        userName: user.displayName,
        sso: 'HIT',
      })

      runInAction(async () => {
        this.username = user.email
        this.password = ''
        this.loginOnProcess = false
        this.doesUserAuthenticated = true
        this.emailFromInvitation = false
        dispatch(Actions.USER_LOGGED_IN, { user })
      })

      await this.getUserState()
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.message = error.message || 'Login failed!'
        this.loginOnProcess = false
      })
    }
  }

  async travelcounsellorsLogin() {
    try {
      runInAction(() => {
        this.loginOnProcess = true
      })
      const user = await this.rootStore.apis.signInWithTravelcounsellors()
      //console.log({ 'travelcounsellorsLogin user': user })
      //console.log('logged in with travelcounsellors')

      if (!user?.stsTokenManager?.refreshToken) {
        throw Error('Login failed!')
      }

      //const token = await user.getIdToken(false);

      await this.storeUserDetails({
        email: user.email,
        refreshToken: user.stsTokenManager.refreshToken,
        token: user.idToken,
        userId: user.uid,
        isAnonymous: false,
      })

      await this.rootStore.apis.registerUser({
        token: user.idToken,
        userEmail: user.email,
        userName: user.displayName,
        sso: 'travelcounsellors',
        groups: user.groups,
        country: user.country,
      })

      runInAction(async () => {
        this.username = user.email
        this.password = ''
        this.loginOnProcess = false
        this.doesUserAuthenticated = true
        this.emailFromInvitation = false
        dispatch(Actions.USER_LOGGED_IN, { user })
      })

      await this.getUserState()
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.message = error.message || 'Login failed!'
        this.loginOnProcess = false
      })
    }
  }

  async bonareaLogin() {
    try {
      runInAction(() => {
        this.loginOnProcess = true
      })
      const user = await this.rootStore.apis.signInWithBonarea()
      //console.log({ 'BonareaLogin user': user })
      //console.log('logged in with Bonarea')

      if (!user?.stsTokenManager?.refreshToken) {
        throw Error('Login failed!')
      }

      //const token = await user.getIdToken(false);

      await this.storeUserDetails({
        email: user.email,
        refreshToken: user.stsTokenManager.refreshToken,
        token: user.idToken,
        userId: user.uid,
        isAnonymous: false,
      })

      await this.rootStore.apis.registerUser({
        token: user.idToken,
        userEmail: user.email,
        userName: user.displayName,
        sso: 'bonarea',
      })

      runInAction(async () => {
        this.username = user.email
        this.password = ''
        this.loginOnProcess = false
        this.doesUserAuthenticated = true
        this.emailFromInvitation = false
        dispatch(Actions.USER_LOGGED_IN, { user })
      })

      await this.getUserState()
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.message = error.message || 'Login failed!'
        this.loginOnProcess = false
      })
    }
  }

  async bgstrLogin() {
    try {
      runInAction(() => {
        this.loginOnProcess = true
      })
      const user = await this.rootStore.apis.signInWithBgstr()
      console.log({ 'bgstrLogin user': user })
      console.log('logged in with bgstrLogin')

      if (!user?.stsTokenManager?.refreshToken) {
        throw Error('Login failed!')
      }

      //const token = await user.getIdToken(false);

      await this.storeUserDetails({
        email: user.email,
        refreshToken: user.stsTokenManager.refreshToken,
        token: user.idToken,
        userId: user.uid,
        isAnonymous: false,
      })

      await this.rootStore.apis.registerUser({
        token: user.idToken,
        userEmail: user.email,
        userName: user.displayName,
        sso: 'bgstr',
      })

      runInAction(async () => {
        this.username = user.email
        this.password = ''
        this.loginOnProcess = false
        this.doesUserAuthenticated = true
        this.emailFromInvitation = false
        dispatch(Actions.USER_LOGGED_IN, { user })
      })

      await this.getUserState()
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.message = error.message || 'Login failed!'
        this.loginOnProcess = false
      })
    }
  }

  async brownhavenLogin() {
    try {
      runInAction(() => {
        this.loginOnProcess = true
      })
      const user = await this.rootStore.apis.signInWithBrownhaven()
      console.log({ 'brownhavenLogin user': user })
      console.log('logged in with brownhavenLogin')

      if (!user?.stsTokenManager?.refreshToken) {
        throw Error('Login failed!')
      }

      //const token = await user.getIdToken(false);

      await this.storeUserDetails({
        email: user.email,
        refreshToken: user.stsTokenManager.refreshToken,
        token: user.idToken,
        userId: user.uid,
        isAnonymous: false,
      })

      await this.rootStore.apis.registerUser({
        token: user.idToken,
        userEmail: user.email,
        userName: user.displayName,
        sso: 'brownhaven',
      })

      runInAction(async () => {
        this.username = user.email
        this.password = ''
        this.loginOnProcess = false
        this.doesUserAuthenticated = true
        this.emailFromInvitation = false
        dispatch(Actions.USER_LOGGED_IN, { user })
      })

      await this.getUserState()
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.message = error.message || 'Login failed!'
        this.loginOnProcess = false
      })
    }
  }

  verify3rdPartyLogin() {
    ///3rd-party-login
  }

  verifyUserAuthenticated() {
    //Oz checking
    //console.log('no verification!')
    //if (!this.doesUserAuthenticated) {
    //  history.push(LOGIN);
    //}
  }

  toggleShowPassword() {
    runInAction(() => {
      this.showPassword = !this.showPassword
    })
  }
}

export default AuthenticationStore
