import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store/StoresProvider.js'

const Refine = observer(
  (
    {
      // from parent
    }
  ) => {
    return <div> Refine</div>
  }
)

export default Refine
