export function sortCategory({ category }) {
  const categoryTagsSorted = () => {
    if (category.sortBy === 'name' && category.sortOrder === 'desc') {
      return category.tags.slice().sort((a, b) => b.name.localeCompare(a.name))
    } else if (category.sortBy === 'name' && category.sortOrder === 'asc') {
      return category.tags.slice().sort((a, b) => a.name.localeCompare(b.name))
    } else if (category.sortBy === 'count' && category.sortOrder === 'asc') {
      return category.tags.slice().sort((a, b) => (a.count > b.count ? 1 : -1))
    } else {
      // default (category.sortBy === "count" && category.sortOrder === "dec")
      return category.tags.slice().sort((a, b) => (a.count > b.count ? -1 : 1))
    }
  }

  return categoryTagsSorted()
}
