import React, { useState, useRef, useCallback } from 'react'
import { styled } from '@mui/system'

import './MyComponents.scss'

import Button from '@material-ui/core/Button'
import MuTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CloseIcon from '@material-ui/icons/Close'

import Avatar from '@material-ui/core/Avatar'

import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FBC02D',
      light: '#FCC742',
      dark: '#E2AD29',
    },
    secondary: {
      main: '#F7F6F3',
      light: '#F6F6F6',
      dark: '#EEECE7',
    },
    warning: {
      main: '#CF232E',
      light: '#F6F6F6',
      dark: '#BB2029',
    },
    grey: {
      main: '#F7F6F3',
      light: '#F6F6F6',
      dark: '#EEECE7',
    },
    action: {
      disabledBackground: '#F7F6F3',
      disabled: '#D0CDC4',
    },
  },
})

theme.shape = { borderRadius: 8 }

theme.typography.h6 = {
  fontSize: '24px',
  fontWeight: '400',
}

theme.typography.body1 = {
  fontSize: '14px',
}

theme.props = {
  root: {},
  MuiButton: {
    disableElevation: true,
  },
  MuiTab: {
    disableRipple: true,
    indicatorcolor: 'secondary',
  },
}

theme.overrides = {
  root: {
    textTransform: 'none',
  },
  MuiButton: {
    root: {
      textTransform: 'none',
    },
    contained: {
      padding: '10px 24px',
      fontSize: '16px',
      backgroundColor: '#F7F6F3',
    },
    label: {
      lineHeight: '19px',
    },
  },
  MuiTab: {
    root: {
      textTransform: 'none',
      minWidth: 0,
    },
    selected: {
      color: '#000',
      fontWeight: 'bold',
    },
  },
  input: {
    root: {
      borderRadius: '8px',
    },
    InputAdornment: {
      color: 'rgba(0, 0, 0, 0.20)',
    },
    input: {
      padding: '8px',
    },
  },
  muiChip: {
    root: {
      maxWidth: '60px',
    },
  },
}

theme.components = {}

const MyButton = styled(Button)({
  backgroundColor: 'primary',
  borderRadius: '8px',
  color: '#000',
  whiteSpace: 'nowrap',
})

const MyTabs = styled(MuTabs)({})

const MyTab = styled(Tab)({
  minWidth: 100,
})

//////////////////
// Keyword
//////////////////

const MyKeyword = ({
  idx,
  selected,
  disabled,
  count,
  onClick,
  avatar,
  avatarColor,
  icon,
  text,
  onDelete,
}) => {
  return (
    <div
      key={idx}
      className={`keyword ${selected ? 'selected' : ''} ${
        disabled ? 'disabled' : ''
      } ${count === 0 ? 'light' : ''}`}
      onClick={!disabled ? onClick : null}
    >
      {avatar ? (
        <Avatar
          src={avatar}
          style={{ backgroundColor: avatarColor }}
          className={`small-avatar`}
        >
          {' '}
        </Avatar>
      ) : null}
      {icon ? <img src={icon} className={'small-icon'} alt={icon} /> : null}
      <p className="kw-text">{text}</p>
      {count !== undefined ? <p className="kw-count">{count}</p> : null}
      {onDelete ? (
        <CloseIcon
          sx={{ fontSize: 8 }}
          className="delete-tag"
          onClick={onDelete}
        />
      ) : null}
    </div>
  )
}

//////////////////
// LONG PRESS
//////////////////

const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false)
  const timeout = useRef()
  const target = useRef()

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        onLongPress(event)
        setLongPressTriggered(true)
      }, delay)
    },
    [onLongPress, delay, shouldPreventDefault]
  )

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current)
      shouldTriggerClick && !longPressTriggered && onClick(event)
      setLongPressTriggered(false)
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault)
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  )

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onTouchMove: (e) => clear(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  }
}

const isTouchEvent = (event) => {
  return 'touches' in event
}

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}

export { MyButton, theme, MyTabs, MyTab, MyKeyword, useLongPress }
