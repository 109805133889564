import axios from 'axios'
import { host } from '../../../services/host'
import { getHeaderMerged } from '../util'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import history from '../../../services/history'

//NEW
export const getUserOrganizations = async () => {
  const url = `${host}/orgs`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getWorkspaceById = async (workspaceId) => {
  const url = `${host}/orgs/workspaces/${workspaceId}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getOrgById = async (orgId) => {
  const url = `${host}/orgs/${orgId}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getUserWorkspaces = async (orgId) => {
  const url = `${host}/orgs/${orgId}/workspaces`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

//create workspace in organization
export const addOrganizationWorkspace = async ({ orgId, workspaceName }) => {
  const url = `${host}/orgs/${orgId}/workspaces`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { name: workspaceName, type: 'private' },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const deleteWorkspace = async ({ workspaceId }) => {
  const url = `${host}/orgs/workspaces/remove/${workspaceId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateWorkspace = async ({
  workspaceId,
  workspaceType,
  workspaceName,
}) => {
  const url = `${host}/orgs/workspaces/${workspaceId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { type: workspaceType, name: workspaceName },
    })
    return data
  } catch (err) {
    throw err
  }
}

//create organization
export const addOrganization = async (orgName) => {
  const url = `${host}/orgs`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { name: orgName },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateOrganization = async ({ orgId, update }) => {
  const url = `${host}/orgs/${orgId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: update,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getUsersFromWorkspace = async ({ workspaceId }) => {
  const url = `${host}/orgs/workspaces/${workspaceId}/users`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateUserInWorkspace = async ({ workspaceId, userId, role }) => {
  const url = `${host}/orgs/workspaces/${workspaceId}/users/${userId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { role: role },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const addUserToWorkspace = async ({ orgId, ...utw }) => {
  const url = `${host}/orgs/${orgId}workspaces/user`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: utw,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getUserIsOwnOrganization = async () => {
  const url = `${host}/orgs`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const inviteUsers = async ({ workspaceId, users }) => {
  const url = `${host}/orgs/workspaces/${workspaceId}/invite`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: users,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const removeMembers = async ({ workspaceId, userId }) => {
  const url = `${host}/orgs/workspaces/${workspaceId}/users/${userId}`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateFullName = async () => {
  const url = `${host}/orgs/workspaces/user`
  const { displayName } = firebase.auth().currentUser
  if (displayName !== null && displayName !== '') {
    try {
      const { data } = await axios({
        url,
        method: 'PUT',
        headers: await getHeaderMerged(),
        data: { displayName },
      })
      return data
    } catch (err) {
      throw err
    }
  }
}

export const updateWorkspaceName = async (workspaceId, name) => {
  const url = `${host}/orgs/workspaces/${workspaceId}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { type: 'private', name: name },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const createPaymentLink = async ({ orgId, plan }) => {
  const url = `${host}/payments`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { orgId, plan },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const savePaymentsInfo = async ({ info, payment, orgId }) => {
  const url = `${host}/payments/save`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { info, payment, orgId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const cancelPayments = async ({ orgId }) => {
  const url = `${host}/payments/cancel`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { orgId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updatePaymentsPlan = async ({ orgId, plan, payment }) => {
  const url = `${host}/payments/update`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { orgId, payment, plan },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateUserQuantityPayments = async ({ orgId }) => {
  const url = `${host}/payments/quantity`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { orgId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const addWorkspaceTemplate = async ({
  orgId,
  workspaceId,
  templateId,
}) => {
  const url = `${host}/orgs/workspace-template`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { orgId, workspaceId, templateId },
    })

    //wait 1 second so all workspaces will propogate into index
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return data
  } catch (err) {
    throw err
  }
}

export const getOrgLimits = async ({ orgId }) => {
  const url = `${host}/orgs/limits/${orgId}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
      progress: false,
    })
    return data
  } catch (err) {
    throw err
  }
}
