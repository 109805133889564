import React, { useCallback, useEffect, useState, useMemo } from 'react'
import './style.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Alert from '@material-ui/lab/Alert'

import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CreateBookmark from '../CreateBookmark/CreateBookmark.js'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Popper from '@material-ui/core/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import MenuIcon from '@mui/icons-material/Menu'
import { MyButton } from '../_MyComponents'
import Divider from '@mui/material/Divider'

import ShareDialog from './ShareDialog'
import OmniSearch from '../OmniSearch/OmniSearch.js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    zIndex: 99,
    width: '100%',
  },
}))

const TopBar = observer(
  ({
    // from parent
    settingsDialog,
    disable, // used only in create organization view - should refactor out
    openNavbar,
    reset,
    isBookmarkOpen,
    openBookmark,
    closeBookmark,
    setSelectedList,
    setShowBulkEditDialog,
    setType,
    uploadDialog,
    importDialog,
    previewDialog,
    setIsPlanPrompt,
  }) => {
    const { authenticationStore, filesStore, orgsWorkspacesStore } = useStore()

    const { logout, isAnonymous } = authenticationStore

    const { getFilesFiltered, isUpload, closeUpload, getFilesByIds } =
      filesStore

    const {
      isAllowed,
      organization,
      organizations,
      changeOrganization,
      orgName,
      orgType,
      workspaceId,
      workspaceType,
      updateWorkspace,
      planResolvedPercent,
      isJustCollectionUser,
      orgPlan,
      logo,
      featureFlags,
    } = orgsWorkspacesStore

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [uploadError, setUploadError] = React.useState()
    const [recentlyUploadedFiles, setRecentlyUploadedFiles] = useState(false)

    const [showShareDialog, setShowShareDialog] = useState(false)

    const onLoad = useCallback((event) => {
      event.preventDefault()
      event.returnValue = ''
    }, [])

    useEffect(() => {
      if (isUpload) {
        window.addEventListener('beforeunload', onLoad)
      } else {
        window.removeEventListener('beforeunload', onLoad)
      }
      return () => window.removeEventListener('beforeunload', onLoad)
    }, [isUpload, onLoad])

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const isLnet = orgType === 'lnet'

    const openSettings = () => {
      settingsDialog('workspace')
      setAnchorEl(null)
    }

    const openShare = () => {
      setShowShareDialog(true)
      setAnchorEl(null)
    }

    function goToHelpCenter() {
      window.open(
        'https://tagbox.notion.site/Tagbox-s-Help-Center-ad93580eb41d4b7dabe45bdf706cc397',
        '_blank'
      )
    }

    function submitFeedback() {
      window.open('https://airtable.com/shryZsFxMBBRTV89z', '_blank')
    }

    const handleLogout = () => {
      isAnonymous
        ? window.open(
            `https://tagbox.io?utm_source=in_app&utm_medium=public_view&utm_campaign=${orgName}`,
            '_blank'
          )
        : logout()
    }

    const orgs = organizations.map((org, idx) => {
      return (
        <MenuItem
          disabled={organization._id == org.orgId._id}
          selected={organization._id == org.orgId._id}
          key={idx}
          onClick={() => {
            setAnchorEl(null)
            changeOrganization({ org: org.orgId })
          }}
        >
          <AccountCircleIcon className="menu-icon" fontSize="small" />
          {org.orgId.name}
        </MenuItem>
      )
    })

    const nonSelectedOrgs = organizations.filter(
      (org) => organization._id != org.orgId._id
    )

    return (
      <div className={classes.root + ' top-bar'}>
        <ShareDialog
          open={showShareDialog}
          setOpen={setShowShareDialog}
          currentWorkspaceType={workspaceType}
          updateWorkspace={updateWorkspace}
        ></ShareDialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={() => setUploadError()}
          open={!!uploadError}
          autoHideDuration={6000}
        >
          <Alert severity="error">{uploadError}</Alert>
        </Snackbar>

        {/* actual content */}
        <AppBar position="static">
          <Toolbar className={'toolbar' + ' header-menu'}>
            <div className="topbar-left">
              {!window.mobileCheck() && (
                <span
                  className="flex-row"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (!isJustCollectionUser()) {
                      reset()
                    }
                  }}
                >
                  {!!organization && logo && (
                    <img className="logo" src={logo} alt="logo" />
                  )}
                </span>
              )}
              {window.mobileCheck() &&
                !isJustCollectionUser() &&
                !isAnonymous && (
                  <IconButton
                    className="filter-icon-mobile"
                    aria-label="close"
                    color="inherit"
                    onClick={() => openNavbar()}
                  >
                    <MenuIcon fontSize="inherit" />
                  </IconButton>
                )}
            </div>
            <div className="topbar-middle">
              {!disable && (
                <OmniSearch
                  previewDialog={previewDialog} //from Home
                />
              )}
            </div>
            <div className="topbar-right">
              {!isAnonymous ? (
                <Avatar
                  className={'settings'}
                  aria-controls="top-bar-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <AccountCircleIcon
                    style={{
                      height: '100%',
                      width: '100%',
                      backgroundColor: 'white',
                      color: '#212121',
                    }}
                  />
                </Avatar>
              ) : (
                !window.mobileCheck() &&
                isAnonymous && (
                  <Button
                    color="primary"
                    style={{ textTransform: 'none', width: 'max-content' }}
                    onClick={handleLogout}
                    fullWidth
                    variant="contained"
                  >
                    <Trans>Try Tagbox Free</Trans>
                  </Button>
                )
              )}
              {!disable && isAllowed(RULES.UPLOAD_DOCUMENTS) && (
                <SplitButton
                  mobile={window.mobileCheck()}
                  onClick={handleClose}
                  openBookmark={openBookmark}
                  getFilesFiltered={getFilesFiltered}
                  aria-controls="upload-menu"
                  workspaceId={workspaceId}
                  uploadDialog={uploadDialog}
                  planResolvedPercent={planResolvedPercent}
                  setIsPlanPrompt={setIsPlanPrompt}
                  orgPlan={orgPlan}
                  importDialog={importDialog}
                  featureFlags={featureFlags}
                />
              )}
              {isBookmarkOpen && (
                <CreateBookmark
                  id="upload-menu"
                  open={isBookmarkOpen}
                  closeBookmark={closeBookmark}
                />
              )}
            </div>
            <Menu
              id="top-bar-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              {!disable && !isAnonymous && (
                <MenuItem onClick={openSettings}>
                  <Trans>Settings</Trans>
                </MenuItem>
              )}
              {!disable && isAllowed(RULES.SHARE_WORKSPACE) && (
                <MenuItem onClick={openShare}>
                  <Trans>Share</Trans>
                </MenuItem>
              )}
              <MenuItem onClick={goToHelpCenter}>
                <Trans>Help Center</Trans>
              </MenuItem>
              <MenuItem onClick={submitFeedback}>
                <Trans>Submit feedback</Trans>
              </MenuItem>
              {nonSelectedOrgs.length > 0 && (
                <div>
                  <Divider className="divider" />
                  <MenuItem disabled>
                    <b>
                      <Trans>My accounts</Trans>
                    </b>
                  </MenuItem>
                  {orgs}
                  {/*<MenuItem onClick={submitFeedback}><AddCircleOutlineIcon className="menu-icon" fontSize="small"/><b>Add account</b></MenuItem>*/}
                  <Divider className="divider" />
                </div>
              )}

              {!isLnet && (
                <MenuItem onClick={handleLogout}>
                  {isAnonymous ? <Trans>Login</Trans> : <Trans>Logout</Trans>}
                </MenuItem>
              )}
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
)

export default TopBar

export function SplitButton(props) {
  const {
    openBookmark,
    mobile,
    uploadDialog,
    planResolvedPercent,
    setIsPlanPrompt,
    orgPlan,
    featureFlags,
  } = props
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleUploadClick = (type) => {
    if (planResolvedPercent * 100 < 100 || orgPlan !== 'free') {
      uploadDialog(type)
    } else {
      setIsPlanPrompt(true)
    }
  }

  return (
    <div className="uploadButton">
      {mobile ? (
        <Fab
          color="primary"
          ref={anchorRef}
          onClick={handleToggle}
          aria-label="split button"
          id="upload-button"
          className="mobile-upload-button"
        >
          <AddIcon />
        </Fab>
      ) : (
        <ButtonGroup
          variant="text"
          id="upload-button"
          disableElevation
          ref={anchorRef}
          aria-label="split button"
        >
          <MyButton
            onClick={handleUploadClick}
            variant="contained"
            color="primary"
          >
            <label>
              <Trans>Upload</Trans>
            </label>
          </MyButton>
          <MyButton
            color="primary"
            variant="contained"
            className="arrow-menu"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <KeyboardArrowDownIcon />
          </MyButton>
        </ButtonGroup>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem onClick={() => handleUploadClick('files')}>
                    <Trans>Upload Files</Trans>
                  </MenuItem>
                  <MenuItem onClick={() => handleUploadClick('folders')}>
                    <Trans>Upload Folders</Trans>
                  </MenuItem>
                  <MenuItem onClick={openBookmark}>
                    <Trans>Add Link</Trans>
                  </MenuItem>
                  {featureFlags?.import?.enabled && (
                    <MenuItem onClick={() => props.importDialog(true)}>
                      <Trans>Import Files</Trans>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
