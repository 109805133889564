import { makeAutoObservable, runInAction, configure } from 'mobx'
import history from '../../services/history'
import { dispatch } from '../../services/pubsub'
import Actions from '../../util/actions'
import { PERMISSIONS, ROLES, RULES } from './permissions'
import logo from '../../assets/logo.svg'

import {
  CREATE_ORGANIZATION,
  CREATE_WORKSPACE,
  LOGIN,
  REGISTER,
  NEW_ORG,
} from '../../util/routes'

configure({ enforceActions: 'observed', isolateGlobalState: true })

class OrgsWorkspacesStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
    //this.getUserIsOrganizationWorkspaces = this.getUserIsOrganizationWorkspaces.bind(this)
    this.addOrganization = this.addOrganization.bind(this)
    this.orgNameChange = this.orgNameChange.bind(this)
    this.verifyOrganization = this.verifyOrganization.bind(this)
    this.addWorkspace = this.addWorkspace.bind(this)
    this.deleteWorkspace = this.deleteWorkspace.bind(this)
    this.updateWorkspace = this.updateWorkspace.bind(this)
    this.updateOrganization = this.updateOrganization.bind(this)
    this.setWorkspaceId = this.setWorkspaceId.bind(this)
    this.setWorkspaceDefault = this.setWorkspaceDefault.bind(this)
    this.changeWorkspace = this.changeWorkspace.bind(this)
    this.changeWorkspaceFromDetail = this.changeWorkspaceFromDetail.bind(this)
    this.workspaceNameChange = this.workspaceNameChange.bind(this)
    this.setCurrentOrganization = this.setCurrentOrganization.bind(this)
    this.changeOrganization = this.changeOrganization.bind(this)
    this.setWorkspaces = this.setWorkspaces.bind(this)
    this.inviteUser = this.inviteUser.bind(this)
    //this.getInvitationUsers = this.getInvitationUsers.bind(this);
    this.getUsersFromWorkspace = this.getUsersFromWorkspace.bind(this)
    this.isAllowed = this.isAllowed.bind(this)
    this.updateUserIsRole = this.updateUserIsRole.bind(this)
    this.removeMember = this.removeMember.bind(this)
    this.getUserRole = this.getUserRole.bind(this)

    this.getUserOrganizations = this.getUserOrganizations.bind(this)
    this.getUserWorkspaces = this.getUserWorkspaces.bind(this)
    this.updateWorkspaceName = this.updateWorkspaceName.bind(this)
    this.createPaymentLink = this.createPaymentLink.bind(this)
    this.savePaymentsInfo = this.savePaymentsInfo.bind(this)
    this.cancelPayments = this.cancelPayments.bind(this)
    this.updatePaymentsPlan = this.updatePaymentsPlan.bind(this)
    this.updateUserQuantityPayments = this.updateUserQuantityPayments.bind(this)
    this.addWorkspaceTemplate = this.addWorkspaceTemplate.bind(this)
    this.getOrgLimits = this.getOrgLimits.bind(this)
    this.isJustCollectionUser = this.isJustCollectionUser.bind(this)
    this.isSingleFileUser = this.isSingleFileUser.bind(this)
    this.isOutsideVisitor = this.isOutsideVisitor.bind(this)
  }

  invitationUsers = []
  workspaceUsers = []
  role = ''
  collectionRole = ''

  //NEW
  organizations = []
  //org = current org.
  organization = null
  orgId = null
  orgName = ''
  orgType = ''
  orgPayment = ''
  orgPlan = ''
  orgPlanLimits = {}
  orgInfo = {}
  orgPaymentInfo = null
  featureFlags = {}

  workspaces = []
  //workspace = current workspace
  workspace = null
  workspaceId = null
  workspaceType = null
  workspaceName = 'Main workspace'
  logo = null

  customPermissions = null
  ORG_PERMISSIONS = PERMISSIONS
  ORG_RULES = RULES
  ORG_ROLES = ROLES

  ///////Plan limits////////

  planResolvedPercent = 0
  totalFileSize = 0
  totalFileCount = 0

  ///////Plan limits////////

  reset() {
    runInAction(() => {
      this.organizations = []
      this.organization = null
      this.orgId = null
      this.orgName = ''
      this.orgType = ''
      this.orgPayment = ''
      this.orgPlan = ''
      this.orgPlanLimits = {}
      this.orgInfo = {}
      this.orgPaymentInfo = null
      this.featureFlags = {}
      this.workspaces = []
      this.workspace = null
      this.workspaceId = null
      this.workspaceType = null
      this.workspaceName = 'Main workspace'
      this.logo = null
      this.customPermissions = null
      this.ORG_PERMISSIONS = PERMISSIONS
      this.ORG_RULES = RULES
      this.ORG_ROLES = ROLES
    })
  }

  async updateWorkspaceName({ workspaceId, name }) {
    const res = await this.rootStore.apis.updateWorkspaceName(workspaceId, name)
    if (res.nModified > 0) {
      runInAction(() => {
        this.workspaceName = name
        this.getUserWorkspaces(this.orgId)
      })
    }
  }

  async getUserOrganizations(orgId) {
    const organizations = await this.rootStore.apis.getUserOrganizations()
    await runInAction(async () => {
      this.organizations = organizations
    })

    if (organizations.length !== 0) {
      if (orgId) {
        const organization = organizations.find(
          (org) => org.orgId._id === orgId
        )
        if (organization) {
          console.log('user org')
          await this.setCurrentOrganization(organization.orgId)
          await this.getUserWorkspaces(organization.orgId._id)
        } else {
          await this.setCurrentOrganization(organizations?.[0].orgId)
          await this.getUserWorkspaces(organizations?.[0].orgId._id)
        }
      } else {
        console.log('different org')
        await this.setCurrentOrganization(organizations?.[0].orgId)
        await this.getUserWorkspaces(organizations?.[0].orgId._id)
      }
    }

    return { organizations }
  }

  async getUserWorkspaces(orgId) {
    const workspaces = await this.rootStore.apis.getUserWorkspaces(orgId)
    runInAction(async () => {
      this.workspaces = workspaces
    })
    return { workspaces }
  }

  async getWorkspaceById(workspaceId) {
    const workspace = await this.rootStore.apis.getWorkspaceById(workspaceId)
    return {
      workspace,
    }
  }

  async getOrgById(orgId) {
    return this.rootStore.apis.getOrgById(orgId).then((org) => {
      runInAction(async () => {
        this.setCurrentOrganization(org)
      })
      return {
        org,
      }
    })
  }

  //END NEW

  async setOrgPermissions(customPermissions) {
    if (customPermissions) {
      runInAction(() => {
        this.customPermissions = customPermissions
        this.ORG_PERMISSIONS = customPermissions.PERMISSIONS
        this.ORG_RULES = customPermissions.RULES
        this.ORG_ROLES = customPermissions.ROLES
      })
    }
  }

  async setCurrentOrganization(org) {
    runInAction(() => {
      this.organization = org
      this.orgId = org._id
      this.orgName = org.name
      this.orgType = org.type
      this.orgPayment = org.payment
      this.orgPaymentInfo = org.paymentInfo
      this.featureFlags = org.featureFlags
      this.orgPlan = org.plan
      this.orgPlanLimits = org.planLimits
      this.orgInfo = org.info
      console.log({ orgPlanLimits: org.planLimits })
      this.setOrgPermissions(org.customPermissions)
      /* planLimits example
      {
        fileSizeLimitGB : 50,
        fileCountLimit: 1000,
        userCountLimit : 3
        workspaceCountLimit : 1,
        collectionCountLimit : 10,
        cdnCountLimit: 10,
        cdnCount: 0,
      }
      */
    })
    console.log('setCurrentOrganization')
    this.getOrgLimits()
  }

  async setCurrentWorkspace({ workspaceId, role }) {
    const organization = this.organizations.find(
      (org) => org.orgId._id.toString() === workspaceId.orgId.toString()
    )
    if (organization) {
      console.log('only saving user workspaces to state')
      this.rootStore.authenticationStore.updateUserState({
        workspace: workspaceId,
      })
    }

    if (
      workspaceId.type === 'private' &&
      (this.rootStore.authenticationStore.isAnonymous ||
        role == 'collectionUser')
    ) {
      //move to collections and not full workspace
      var queryParams = new URLSearchParams(history.location.search)

      if (!queryParams.has('collections')) {
        queryParams.append('collections', 'open')
        history.replace(`/w/${workspaceId._id}?` + queryParams.toString())
      } else {
        history.replace(`/w/${workspaceId._id}` + history.location.search)
      }
    } else {
      history.replace(`/w/${workspaceId._id}` + history.location.search)
    }

    await runInAction(async () => {
      this.workspace = workspaceId
      this.workspaceName = workspaceId.name
      this.workspaceId = workspaceId._id
      this.workspaceType = workspaceId.type
      this.role = role
      this.logo = workspaceId.logo || this.organization.logo || logo
    })
  }

  async setWorkspaceId(workspaceId) {
    runInAction(() => {
      this.workspaceId = workspaceId
    })
  }

  async setWorkspaces(workspaces) {
    runInAction(async () => {
      this.workspaces = workspaces
    })
  }

  async changeOrganization({ org }) {
    console.log(org)

    runInAction(async () => {
      await this.setCurrentOrganization(org)
      //await this.getUserOrganizations();
      //history.push(CREATE_WORKSPACE);
      await this.getUserWorkspaces(this.orgId)
      await this.changeWorkspace(this.workspaces?.[0])
    })
  }

  async checkIfWorkspaceIsPublic(workspaceId) {
    try {
      var workspace = await this.getWorkspaceById(workspaceId)
      return {
        workspace: workspace.workspace,
        public: workspace.workspace.type === 'public',
      }
    } catch (e) {
      return { workspace: {}, public: false }
    }
  }

  //after logged in - main function
  async verifyOrganization() {
    await this.rootStore.authenticationStore.getUserState()

    const { doesUserAuthenticated, isAnonymous, isSuperAdmin } =
      this.rootStore.authenticationStore

    var local_workspaceId
    var is_workspace_public = false
    var is_file_public = false
    var local_workspace
    var is_search_query = false
    var query

    if (!doesUserAuthenticated) {
      console.log('verifyOrganization, signing in anonymously')
      await this.rootStore.authenticationStore.anonymouseLogin()
    }

    if (window.location.pathname.includes('/w/')) {
      console.log('Checking if workspace is public')
      var path_array = window.location.pathname.split('/')
      var index_of_w = path_array.indexOf('w')
      local_workspaceId = path_array[index_of_w + 1] //workspace id is right after 'w'
      var response_workspace = await this.checkIfWorkspaceIsPublic(
        local_workspaceId
      )
      is_workspace_public = response_workspace.public
      local_workspace = response_workspace.workspace
    }

    if (window.location.search != '') {
      is_search_query = true
      query = new URLSearchParams(window.location.search)
    }

    if (is_search_query) {
      let fileId = query.get('openedFile')
      if (fileId) {
        is_file_public = await this.rootStore.apis.checkIfFileIsPublic(fileId)
      }
    }

    //if WS in path
    // check if user is in the workspace
    // if so: regular
    // else:
    //check if workspace is public
    //if public:
    // if user is not logged in - log in anonymously
    //continue regular
    //else:
    //trough user to login
    //else: go to regular login

    if (window.location.pathname.includes('/unsubscribe')) {
      //nothing
    }
    //special case for super admin
    else if (window.location.pathname.includes('/w/') && isSuperAdmin) {
      console.log('0')
      console.log('superAdmin is logged in and going to a workspace')
      // check if user is in the workspace
      await this.getUserOrganizations(local_workspace.orgId)
      runInAction(async () => {
        const w = this.workspaces.filter(
          (workspace) => workspace.workspaceId._id === local_workspaceId
        )
        if (w.length > 0) {
          //user is in the workspace
          console.log('user is in the workspace', w[0])
          runInAction(async () => {
            await this.setCurrentWorkspace(w[0])
            if (!window.location.pathname.includes('/d/')) {
              this.dispatchWorkspaceActions()
            }
          })
        } else {
          // this is another org workspace - we are superAdmin so can do anything
          await this.getOrgById(local_workspace.orgId)
          //continue regular
          runInAction(async () => {
            await this.setCurrentWorkspace({
              workspaceId: local_workspace,
              role: 'admin',
            })
            this.setWorkspaces([])
            this.dispatchWorkspaceActions()
          })
        }
      })
    }
    //if WS in path and user is logged in
    else if (
      window.location.pathname.includes('/w/') &&
      doesUserAuthenticated &&
      !isAnonymous
    ) {
      console.log('1')
      console.log('user is logged in and going to a workspace')
      // check if user is in the workspace
      await this.getUserOrganizations(local_workspace.orgId)
      runInAction(async () => {
        const w = this.workspaces.filter(
          (workspace) => workspace.workspaceId._id === local_workspaceId
        )
        if (w.length > 0) {
          //user is in the workspace
          console.log('user is in the workspace', w[0])
          runInAction(async () => {
            await this.setCurrentWorkspace(w[0])
            if (!window.location.pathname.includes('/d/')) {
              this.dispatchWorkspaceActions()
            }
          })
        } else {
          //check if workspace is public
          console.log('check if workspace is public')
          //var response_workspace = await this.checkIfWorkspaceIsPublic(local_workspaceId)
          //is_workspace_public = response_workspace.public
          //local_workspace = response_workspace.workspace

          if (is_workspace_public) {
            //if public:
            console.log('workspace is public')
            await this.getOrgById(local_workspace.orgId)
            //continue regular
            runInAction(async () => {
              await this.setCurrentWorkspace({
                workspaceId: local_workspace,
                role: 'viewer',
              })
              this.setWorkspaces([])
              this.dispatchWorkspaceActions()
            })
          } else if (is_search_query) {
            let collectionId = query.get('collectionId')
            if (collectionId) {
              //check if collection is public
              console.log('check if collection is public')
              let collectionPublic =
                await this.rootStore.filesStore.checkIfCollectionIsPublic(
                  collectionId
                )
              if (collectionPublic) {
                //continue regular but with private workspace
                console.log('continue regular but with private workspace')
                await this.getOrgById(local_workspace.orgId)
                runInAction(async () => {
                  await this.setCurrentWorkspace({
                    workspaceId: local_workspace,
                    role: 'collectionUser',
                  })
                  this.setWorkspaces([])
                  this.dispatchWorkspaceActions()
                })
                return
              } else {
                console.log(
                  'throw user to login as this is not public or part of user workspaces'
                )
                history.replace(LOGIN)
                dispatch(Actions.USER_LOGGED_IN)
              }
            } else if (is_file_public && !is_workspace_public) {
              console.log('continue for file public but with private workspace')
              await this.getOrgById(local_workspace.orgId)
              runInAction(async () => {
                await this.setCurrentWorkspace({
                  workspaceId: local_workspace,
                  role: 'singleFileUser',
                })
                this.setWorkspaces([])
                //this.dispatchWorkspaceActions();
              })
            } else {
              console.log(
                'throw user to login as this is not public or part of user workspaces'
              )
              history.replace(LOGIN)
              dispatch(Actions.USER_LOGGED_IN)
            }
          } else {
            console.log(
              'throw user to login as this is not public or part of user workspaces'
            )
            history.replace(LOGIN)
            dispatch(Actions.USER_LOGGED_IN)
          }
        }
      })
    } else if (
      window.location.pathname.includes('/w/') &&
      (!doesUserAuthenticated || (doesUserAuthenticated && isAnonymous))
    ) {
      //user is anonymouse logged in or anonymouse not logged in yet
      //console.log("2")
      console.log('anonymouse user going to a workspace')
      // if user is not logged in - log in anonymously
      if (!doesUserAuthenticated) {
        await this.rootStore.authenticationStore.anonymouseLogin()
      }
      //check if workspace is public
      console.log('check if workspace is public')
      let response_workspace2 = await this.checkIfWorkspaceIsPublic(
        local_workspaceId
      )
      is_workspace_public = response_workspace2.public
      local_workspace = response_workspace2.workspace

      if (is_workspace_public) {
        //if public:
        //continue regular
        console.log('public for anonymouse')
        await this.getOrgById(local_workspace.orgId)
        runInAction(async () => {
          await this.setCurrentWorkspace({
            workspaceId: local_workspace,
            role: 'viewer',
          })
          this.dispatchWorkspaceActions()
        })
      }

      if (is_search_query) {
        let collectionId = query.get('collectionId')
        if (collectionId) {
          //check if collection is public
          console.log('check if collection is public')
          let collectionPublic =
            await this.rootStore.filesStore.checkIfCollectionIsPublic(
              collectionId
            )
          if (collectionPublic) {
            //continue regular but with private workspace
            console.log('continue regular but with private workspace')
            await this.getOrgById(local_workspace.orgId)
            runInAction(async () => {
              await this.setCurrentWorkspace({
                workspaceId: local_workspace,
                role: 'viewer',
              })
              this.dispatchWorkspaceActions()
            })
            return
          } else {
            //throw user to login
            console.log(
              'throw user to login as this is not public and user is anonymouse'
            )
            await this.rootStore.authenticationStore.logout()
            history.replace(LOGIN)
          }
        } else if (is_file_public && !is_workspace_public) {
          console.log('continue for file public but with private workspace')
          await this.getOrgById(local_workspace.orgId)
          runInAction(async () => {
            await this.setCurrentWorkspace({
              workspaceId: local_workspace,
              role: 'singleFileUser',
            })
            this.setWorkspaces([])
            //this.dispatchWorkspaceActions();
          })
          return
        }
      }

      if (!is_workspace_public) {
        //throw user to login
        console.log(
          'throw user to login as this is not public and user is anonymouse'
        )
        await this.rootStore.authenticationStore.logout()
        history.replace(LOGIN)
      }
    } else if (
      !window.location.pathname.includes('/w/') &&
      doesUserAuthenticated &&
      !isAnonymous
    ) {
      //console.log("3")
      console.log('user going to login or any other screen')
      //check state to put user in the last state workspace and organization
      let stateOrgId =
        this.rootStore.authenticationStore.userState?.workspace?.orgId || null
      let stateWorkspaceId =
        this.rootStore.authenticationStore.userState?.workspace?._id || null

      this.getUserOrganizations(stateOrgId).then(({ organizations }) => {
        runInAction(() => {
          if (organizations.length == 0) {
            //history.replace(CREATE_ORGANIZATION);
            //this.addOrganization("My Organization");
            console.log('CREATE_ORGANIZATION')
            this.addOrganization(this.rootStore.authenticationStore.username)
          } else if (this.workspaces.length == 0) {
            const { _id } = this.organization
            history.replace(CREATE_WORKSPACE.replace(':id', _id))
          } else {
            if (stateWorkspaceId) {
              console.log('We remeber workspace')
              const userWorkspace = this.workspaces.find(
                (ws) => ws.workspaceId._id === stateWorkspaceId
              )
              console.log({ userWorkspace })
              if (userWorkspace) {
                this.changeWorkspace(userWorkspace)
              } else {
                this.changeWorkspace(this.workspaces?.[0])
              }
            } else {
              this.changeWorkspace(this.workspaces?.[0])
            }
          }
        })
      })
    } else {
      //console.log("4")
      console.log('anonymouse user at login')
      if (doesUserAuthenticated && isAnonymous) {
        await this.rootStore.authenticationStore.logout()
      }
      //nothing?
      if (
        !(
          window.location.pathname.includes('/login') ||
          window.location.pathname.includes('/register')
        )
      ) {
        console.log('throwing to login')
        history.push('/login')
      }
      if (window.location.pathname.includes('/login')) {
        if (is_search_query) {
          let email = query.get('email')
          if (email) {
            dispatch(LOGIN, { email })
          }
        }
      }
      if (window.location.pathname.includes('/register')) {
        if (is_search_query) {
          let email = query.get('email')
          if (email) {
            dispatch(REGISTER, { email })
          }
        }
      }
    }
  }

  isCollectionSelected() {
    if (window.location.search != '') {
      let query = new URLSearchParams(window.location.search)
      let collectionId = query.get('collectionId')
      if (collectionId) {
        return true
      }
    }
    return false
  }

  isFileSelected() {
    if (window.location.search != '') {
      let query = new URLSearchParams(window.location.search)
      let fileId = query.get('openedFile')
      if (fileId) {
        return true
      }
    }
    return false
  }

  changeWorkspace(workspace) {
    runInAction(async () => {
      await this.setCurrentWorkspace(workspace)
      if (!this.isCollectionSelected()) {
        dispatch(Actions.GET_FILES, {})
        dispatch(Actions.GET_ALL_TAGS, {})
      }
      //dispatch(Actions.GET_FILES_USERS, {});
      dispatch(Actions.GET_POPULAR_TAGS, {})
      //dispatch(Actions.GET_POPULAR_USERS, {});
    })
  }

  dispatchWorkspaceActions() {
    runInAction(() => {
      if (!this.isCollectionSelected()) {
        dispatch(Actions.GET_FILES, {})
        dispatch(Actions.GET_ALL_TAGS, {})
      }
      //dispatch(Actions.GET_FILES_USERS, {});
      dispatch(Actions.GET_POPULAR_TAGS, {})
      //dispatch(Actions.GET_POPULAR_USERS, {});
    })
  }

  changeWorkspaceFromDetail(workspace) {
    //console.log('changeWorkspaceFromDetail')
    runInAction(async () => {
      await this.setCurrentWorkspace(workspace)
      if (!this.isCollectionSelected()) {
        dispatch(Actions.GET_FILES, {})
      }
      //dispatch(Actions.GET_ALL_TAGS, {});
      //dispatch(Actions.GET_FILES_USERS, {});
    })
  }

  addWorkspace({ workspaceName }) {
    return this.rootStore.apis
      .addOrganizationWorkspace({
        orgId: this.orgId,
        workspaceName,
      })
      .then((workspace) => {
        runInAction(() => {
          this.workspaces.push(workspace)
          this.changeWorkspace(workspace)
        })
      })
  }

  deleteWorkspace() {
    return this.rootStore.apis
      .deleteWorkspace({ workspaceId: this.workspace._id })
      .then(() => {
        runInAction(() => {
          this.workspaces = this.workspaces.filter((ws) => {
            return ws.workspaceId._id !== this.workspace._id
          })
          this.changeWorkspace(this.workspaces?.[0])
          this.getOrgLimits()
        })
      })
  }

  updateWorkspace({ workspaceName, workspaceType }) {
    return this.rootStore.apis
      .updateWorkspace({
        workspaceId: this.workspaceId,
        workspaceName,
        workspaceType,
      })
      .then((workspace) => {
        runInAction(async () => {
          await this.getUserWorkspaces(this.orgId)
          const w = this.workspaces.filter(
            (workspace) => workspace.workspaceId._id === this.workspaceId
          )
          await this.setCurrentWorkspace({
            workspaceId: w[0].workspaceId,
            role: this.role,
          })
        })
      })
  }

  async updateOrganization({ update }) {
    try {
      const org = await this.rootStore.apis.updateOrganization({
        orgId: this.orgId,
        update,
      })
      runInAction(async () => {
        await this.setCurrentOrganization(org)
      })
    } catch (e) {
      console.log(e)
    }
  }

  addOrganization(orgName) {
    return this.rootStore.apis.addOrganization(orgName).then((org) => {
      runInAction(async () => {
        await this.setCurrentOrganization(org)
        await this.getUserOrganizations()
        //history.push(CREATE_WORKSPACE);
        await this.getUserWorkspaces(this.orgId)
        this.setWorkspaceId(this.workspaces?.[0].workspaceId._id)
        history.push(
          NEW_ORG.replace(':id', this.workspaces?.[0].workspaceId._id)
        )
        //await this.changeWorkspace(this.workspaces?.[0])
      })
    })
  }

  async setWorkspaceDefault() {
    await this.changeWorkspace(this.workspaces?.[0])
  }

  getUserIsOwnOrganization() {
    return this.rootStore.apis.getUserIsOwnOrganization()
  }

  orgNameChange(name) {
    runInAction(() => {
      this.orgName = name
    })
  }

  workspaceNameChange(name) {
    runInAction(() => {
      this.workspaceName = name
    })
  }

  async inviteUser({ emails, role }) {
    const { createdAt, updatedAt, ...organization } = {
      ...this.organization,
      isTemplate: true,
    }

    //change to this when api for batch vreat is ready
    /* const users = emails.map((username) => ({
      email: username,
      role: role,
    })); */

    await Promise.all(
      emails.map(
        async (email) =>
          await this.rootStore.apis.inviteUsers({
            users: { email, role },
            workspaceId: this.workspaceId,
          })
      )
    )

    this.getUsersFromWorkspace()
    /*
    const users = {
      email: emails[0],
      role: role,
    }

    return this.rootStore.apis
      .inviteUsers({ users, workspaceId: this.workspaceId })
      .then(() => {
        this.getUsersFromWorkspace()
      })
      */

    //.then(()=> {
    //  this.updateUserQuantityPayments();
    //});
  }

  updateUserIsRole({ userId, role }) {
    this.rootStore.apis
      .updateUserInWorkspace({ userId, role, workspaceId: this.workspaceId })
      .then(() => {
        this.getUsersFromWorkspace()
      })
  }

  removeMember(user) {
    this.rootStore.apis
      .removeMembers({ userId: user.userId._id, workspaceId: this.workspaceId })
      .then(() => {
        this.getUsersFromWorkspace()
      })
    //.then(()=> {
    //  this.updateUserQuantityPayments();
    //})
  }

  /* getInvitationUsers() {
    return this.rootStore.apis
      .getInvitationUsers({ orgId: this.orgId })
      .then(({ invitationUsers }) => {
        runInAction(() => {
          this.invitationUsers = invitationUsers;
        });
      });
  } */

  async getUsersFromWorkspace() {
    return this.rootStore.apis
      .getUsersFromWorkspace({ workspaceId: this.workspaceId })
      .then((workspaceUsers) => {
        runInAction(() => {
          this.workspaceUsers = workspaceUsers
        })
      })
  }

  isAllowed(...identities) {
    if (!this.organization) {
      return false
    }
    const identitiesPermission = identities.map(
      (identity) => this.ORG_PERMISSIONS[identity]
    )
    if (!identitiesPermission.length) {
      return false
    }

    return identitiesPermission.some(
      (permission) =>
        permission.includes(this.collectionRole) ||
        permission.includes(this.role)
    )
  }

  isJustCollectionUser() {
    return (
      this.workspaceType === 'private' &&
      (this.rootStore.authenticationStore.isAnonymous ||
        this.role == 'collectionUser')
    )
  }

  isSingleFileUser() {
    return this.role == 'singleFileUser'
  }

  isOutsideVisitor() {
    return (
      this.rootStore.authenticationStore.isAnonymous ||
      this.role == 'collectionUser'
    )
  }

  getUserRole() {
    return this.role
  }

  async createPaymentLink(plan) {
    const link = await this.rootStore.apis.createPaymentLink({
      orgId: this.orgId,
      plan,
    })
    return link
  }

  async savePaymentsInfo({ data, payment }) {
    const org = await this.rootStore.apis.savePaymentsInfo({
      info: data,
      payment,
      orgId: this.orgId,
    })
    runInAction(async () => {
      this.setCurrentOrganization(org)
    })
  }

  async cancelPayments() {
    const org = await this.rootStore.apis.cancelPayments({ orgId: this.orgId })
    runInAction(async () => {
      this.setCurrentOrganization(org)
    })
  }

  async updatePaymentsPlan({ plan, payment }) {
    const org = await this.rootStore.apis.updatePaymentsPlan({
      orgId: this.orgId,
      plan,
      payment,
    })
    runInAction(async () => {
      this.setCurrentOrganization(org)
    })
  }

  async updateUserQuantityPayments() {
    if (this.orgType == 'regular' && this.orgPayment != 'free') {
      await this.rootStore.apis.updateUserQuantityPayments({
        orgId: this.orgId,
      })
    }
  }

  async addWorkspaceTemplate(templateId) {
    await this.rootStore.apis.addWorkspaceTemplate({
      orgId: this.orgId,
      workspaceId: this.workspaceId,
      templateId,
    })
  }

  async getOrgLimits() {
    const limits = await this.rootStore.apis.getOrgLimits({ orgId: this.orgId })
    //console.log({ orgLimits: limits })

    runInAction(async () => {
      this.totalFileSize = limits.totalSizeFile
      this.totalFileCount = limits.totalFiles

      var sizePercent =
        limits.totalSizeFile /
        (this.orgPlanLimits.fileSizeLimitGB * 1024 * 1024 * 1024)
      var countPercent = limits.totalFiles / this.orgPlanLimits.fileCountLimit

      this.planResolvedPercent = Math.max(sizePercent, countPercent, 0)
    })
  }
}

export default OrgsWorkspacesStore
