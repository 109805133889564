import { makeAutoObservable, runInAction } from 'mobx'
import { capitalize } from 'lodash'
import { subscribe, dispatch } from '../../services/pubsub'
import Actions from '../../util/actions'
import logger from '../../services/logger'
import storage from '../../services/storage'
import { TOKEN_KEYWORD } from '../../util/consts'

class ThirdPartyStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)

    this.handle3rdPartyResponse = this.handle3rdPartyResponse.bind(this)
    this.get3rdPartyDetails = this.get3rdPartyDetails.bind(this)
    subscribe(Actions.THIRD_PARTY_RESPONSE, this.handle3rdPartyResponse)
  }

  get3rdPartyDetails() {
    const thirdPartyDetails = storage.get(TOKEN_KEYWORD) || {
      thirdPartyDetails: {},
    }
    return thirdPartyDetails
  }

  async handle3rdPartyResponse({ hash }) {
    const params = hash
      .split('&')
      .map((keyValuePair) => keyValuePair.split('='))
      .reduce(
        (params, [key, value]) => ({
          ...params,
          [key]: value,
        }),
        {}
      )

    const {
      access_token,
      token_type,
      '#uid': userId,
      account_id: accountId,
    } = params
    const token = `${capitalize(token_type)} ${access_token}`
    const user = this.rootStore.storage.get('user')
    const organizationWorkspaces =
      await this.rootStore.orgsWorkspacesStore.getUserIsOrganizationWorkspaces()

    this.rootStore.apis
      .setToken({
        token,
        userId,
        accountId,
        email: user?.email,
        organizationWorkspaces,
      })
      .then((thirdPartyDetails) => {
        runInAction(() => {
          storage.set('thirdPartyDetails', thirdPartyDetails)
        })
        this.rootStore.dropboxImportStore.assignWorkspace({ token })
      })
      .catch((error) => {
        logger.error(error)
        dispatch(Actions.FAILED_TO_STORE_3RD_PARTY_TOKEN, error)
      })
  }
}

export default ThirdPartyStore
