import RootStore from './RootStore'
import AuthenticationStore from '../AuthenticationStore'
import ThirdPartyStore from '../ThirdPartyStore'
import FilesStore from '../FilesStore'
import RegisterStore from '../RegisterStore'
import DropboxImportStore from '../DropboxImportStore'
import OrgsWorkspacesStore from '../OrgsWorkspacesStore'

import storage from '../../services/storage'
import apis from '../../services/apis'
import router from '../../services/router'

const authenticationStore = new AuthenticationStore({
  doesUserAuthenticated: false,
  token: null,
  username: '',
  password: '',
  message: '',
  whenLoginRouteUrl: '/',
  loginOnProcess: false,
  logoutUrl: '/login',
  showPassword: false,
  isAnonymous: false,
})

const thirdPartyStore = new ThirdPartyStore()

const filesStore = new FilesStore({
  widthPercents: 0.8,
  fileContainerWidth: 235,
})

const registerStore = new RegisterStore({
  doesUserRegistered: false,
  username: '',
  password: '',
  confirmPassword: '',
  message: '',
  whenRegisterRouteUrl: '/',
  registerOnProcess: false,
  showPassword: false,
  credentialsError: false,
  passwordErrorMessage: '',
  confirmPasswordErrorMessage: '',
  usernameErrorMessage: '',
})

const dropboxImportStore = new DropboxImportStore()

const orgsWorkspacesStore = new OrgsWorkspacesStore()

const rootStore = new RootStore({
  apis,
  router,
  storage,
  window,
  TOKEN_KEYWORD: 'your_token_keyword', // Add this if needed
  authenticationStore,
  thirdPartyStore,
  filesStore,
  registerStore,
  dropboxImportStore,
  orgsWorkspacesStore,
})

export default rootStore
