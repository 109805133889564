import * as firebaseApi from './firebase'
import filesApi from './files'
import usersApi from './users'
import * as orgs from './orgs'
import * as tagsApi from './tags'
import * as categoriesApi from './categories'
import * as collectionApi from './collections'
export default {
  ...firebaseApi,
  ...filesApi,
  ...usersApi,
  ...orgs,
  ...tagsApi,
  ...categoriesApi,
  ...collectionApi,
}
