import keyMirror from 'key-mirror'

export const ROLES = keyMirror({
  admin: null,
  editor: null,
  viewer: null,
  collectionAdmin: null,
  collectionEditor: null,
  collectionViewer: null,
})

const all = Object.values(ROLES)

export const RULES = keyMirror({
  // ASSETS
  PREVIEW_DOC: null,
  DOWNLOAD_DOC: null,
  ADD_LINK: null,
  REMOVE_LINKS: null,
  UPLOAD_DOCUMENTS: null,
  DELETE_DOC: null,
  EDIT_DOCUMENTS: null, // edit asset name
  SHARE_DOCUMENTS: null,
  COMMENT_ON_A_FILE: null,
  EDIT_COMMENT: null,
  UPDATE_VERSIONS: null,
  VIEW_SIMILAR: null,
  VIEW_DETAILS_BAR: null,

  // TAGS
  ADD_TAG: null,
  CREATE_TAG: null,
  REMOVE_TAGS: null,
  EDIT_TAGS: null,

  // COLLECTIONS
  CREATE_COLLECTION: null,
  ADD_TO_COLLECTION: null,
  REMOVE_FROM_COLLECTION: null,
  EDIT_COLLECTION: null,
  EDIT_DESCRIPTION: null,
  SHARE_COLLECTION: null,
  DELETE_COLLECTION: null,

  // WORKSPACES
  SHARE_WORKSPACE: null,
  EDIT_WORKSPACES: null,
  CREATE_WORKSPACES: null,

  // ORGANIZATIONS
  CHANGE_ORG_PLAN: null,

  // USERS
  ADDING_USERS: null,
  REMOVING_USERS: null,

  // PERSONS
  EDIT_PERSONS: null,
  DELETE_PERSONS: null,

  // VIEWS & UI
  SELECT_GRID: null,
  SHOW_FILTERS: null,

  // OTHER
  SMART_ORGANIZER: null, // DEPRECATED
  ALLOWED_HOMEPAGE: null, // FOR PERMISSIONS
  ALLOWED_TAGMANAGMENTPAGE: null, // FOR PERMISSIONS
})

export const PERMISSIONS = {
  [RULES.PREVIEW_DOC]: all,
  [RULES.DOWNLOAD_DOC]: all,
  [RULES.ADD_TAG]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.CREATE_TAG]: [ROLES.admin, ROLES.collectionAdmin],
  [RULES.REMOVE_TAGS]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.ADD_LINK]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.REMOVE_LINKS]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.UPLOAD_DOCUMENTS]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.EDIT_DOCUMENTS]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.VIEW_SIMILAR]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.VIEW_DETAILS_BAR]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.viewer,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
    ROLES.collectionViewer,
  ],
  [RULES.SHARE_DOCUMENTS]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.EDIT_PERSONS]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.DELETE_PERSONS]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.DELETE_DOC]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.ADDING_USERS]: [ROLES.admin],
  [RULES.REMOVING_USERS]: [ROLES.admin],
  [RULES.CHANGE_ORG_PLAN]: [ROLES.admin],
  [RULES.EDIT_TAGS]: [ROLES.admin, ROLES.collectionAdmin],
  [RULES.COMMENT_ON_A_FILE]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.EDIT_COMMENT]: [ROLES.admin, ROLES.collectionAdmin],
  [RULES.SMART_ORGANIZER]: [ROLES.admin],
  [RULES.SHARE_WORKSPACE]: [ROLES.admin],
  [RULES.EDIT_WORKSPACES]: [ROLES.admin],
  [RULES.CREATE_WORKSPACES]: [ROLES.admin],
  [RULES.CREATE_COLLECTION]: [ROLES.admin, ROLES.editor],
  [RULES.ADD_TO_COLLECTION]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.REMOVE_FROM_COLLECTION]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.SHARE_COLLECTION]: [ROLES.admin, ROLES.collectionAdmin],
  [RULES.EDIT_COLLECTION]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.EDIT_DESCRIPTION]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.DELETE_COLLECTION]: [ROLES.admin, ROLES.collectionAdmin],
  [RULES.UPDATE_VERSIONS]: [ROLES.admin, ROLES.collectionAdmin],
  [RULES.ALLOWED_HOMEPAGE]: all,
  [RULES.ALLOWED_TAGMANAGMENTPAGE]: [ROLES.admin],
  [RULES.SELECT_GRID]: [
    ROLES.admin,
    ROLES.editor,
    ROLES.collectionAdmin,
    ROLES.collectionEditor,
  ],
  [RULES.SHOW_FILTERS]: all,
}
