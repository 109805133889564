import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import './style.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import DetailsBar from '../DetailsBar/DetailsBar.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import './style.scss'
import TopBarPreview from './TopBarPreview'
import DOG from '../../assets/dog.png'
import Button from '@material-ui/core/Button'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import history from '../../services/history'
import { EXT2TYPE } from '../SearchByFileType/filesExt'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Dialog from '@material-ui/core/Dialog'
import ProgressiveImage from 'react-progressive-graceful-image'
import { MyButton } from '../_MyComponents'
import File from '../File/File.js'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Cropper } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css'
import 'react-advanced-cropper/dist/themes/corners.css'

const useStyles = makeStyles(() => ({
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
  },
}))

const Preview = observer(
  ({
    // from parent
    isOpen,
    previewDialog,
    setView,
    setIsPlanPrompt,
  }) => {
    const { filesStore, orgsWorkspacesStore, authenticationStore } = useStore()

    const {
      currentFile: file,
      getAllTags,
      resetCurrentFile: reset,
      previewNextPrev,
      isFileFirst,
      isFileLast,
      updateFileType,
      setCDN,
      downloadFile,
      transcription,
      simlarFiles,
      deleteFile,
      proxyDownloadFile,
    } = filesStore

    const {
      organization,
      workspace,
      workspaceId,
      getUsersFromWorkspace,
      isAllowed,
      isSingleFileUser,
      logo,
      orgPlanLimits,
    } = orgsWorkspacesStore

    const { username, userState, updateUserState } = authenticationStore

    const { _id } = useParams()
    const classes = useStyles()
    const [viewReady, setViewReady] = React.useState(false)
    const [signedLocation, setSignedLocation] = useState(undefined)
    const [detailBarOpen, setDetailBarOpen] = useState(false)
    const [isFile, setIsFile] = useState(true)
    const [isFileFirstState, setIsFileFirstState] = useState(false)
    const [isFileLastState, setIsFileLastState] = useState(false)
    const [versionIndex, setVersionIndex] = useState(null)
    const [allowedToUpgradeVersion, setAllowedToUpgradeVersion] =
      useState(false)
    const [isCaption, setIsCaption] = useState(false)
    const [captionSrc, setCaptionSrc] = useState(false)
    const [captionLang, setCaptionLang] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [prevNextLoad, setPrevNextLoad] = useState(false) // prevents glitch on prev/next
    const [similarFilesFiltered, setSimilarFilesFiltered] = useState([]) // if people in photos - make sure it's the same people
    const [tabValue, setTabValue] = useState(0)

    // Export
    const cropperRef = useRef(null)
    const [exportMode, setExportMode] = useState(false)
    let ratios = useMemo(
      () => [
        { name: '1:1', value: 1 },
        { name: '4:3', value: 4 / 3 },
        { name: '3:4', value: 3 / 4 },
        { name: '16:9', value: 16 / 9 },
        { name: '9:16', value: 9 / 16 },
        { name: '16:10', value: 16 / 10 },
        {
          name: t`Original`,
          value: 1,
        },
        { name: t`Freeform`, value: null },
        /* { name: 'Custom', value: 1 }, */
      ],
      []
    )
    const [aspectRatio, setAspectRatio] = useState(ratios[0])
    const [exportFormat, setExportFormat] = useState('png')
    const [exportQuality, setExportQuality] = useState(100)
    const [resize, setResize] = useState(100)
    const [crop, setCrop] = useState({
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    })

    const location = useLocation()
    const historyDOM = useHistory()

    const { fileId, versions } = file || {}
    const versionLength = versions?.length

    useEffect(() => {
      if (transcription && transcription.vtt) {
        setIsCaption(true)
        setCaptionSrc(transcription.vtt)
        setCaptionLang(transcription.language)
      } else {
        setIsCaption(false)
      }
    }, [transcription])

    const updateStateOnCaptionChange = (e) => {
      if (e.target[0].mode === 'showing') {
        updateUserState({ 'transcription.captionOpen': true })
      } else {
        updateUserState({ 'transcription.captionOpen': false })
      }
    }

    //////////
    // if people in photos - make sure it's the same people. Move to BE?
    //////////

    const comparePersons = (inputPersons) => {
      const personIds = !!file.persons
        ? file.persons?.map((person) => {
            return person._id
          })
        : []
      const currentFilePersons = JSON.stringify(personIds?.sort())
      const simmilarFilePersons = JSON.stringify(inputPersons?.sort())
      return currentFilePersons === simmilarFilePersons
    }

    useEffect(() => {
      if (!simlarFiles) {
        return
      }
      const tempSimilarFiles = simlarFiles.filter((f) =>
        comparePersons(f.persons || [])
      )
      setSimilarFilesFiltered(tempSimilarFiles)
    }, [simlarFiles, file])

    useEffect(() => {
      document.addEventListener('keydown', keyboardPrevNext)
      return () => {
        document.removeEventListener('keydown', keyboardPrevNext)
      }
    }, [])

    const keyboardPrevNext = (e) => {
      if (e.keyCode === 37 && !e.repeat && e.target.nodeName !== 'INPUT') {
        handlePrevNext('prev')
      } else if (
        e.keyCode === 39 &&
        !e.repeat &&
        e.target.nodeName !== 'INPUT'
      ) {
        handlePrevNext('next')
      }
    }

    useEffect(() => {
      if (file.fileId) {
        const queryParams = new URLSearchParams(location.search)

        if (!queryParams.has('openedFile')) {
          queryParams.append('openedFile', file.fileId)
          historyDOM.replace({
            search: queryParams.toString(),
          })
        } else {
          queryParams.delete('openedFile')
          queryParams.append('openedFile', file.fileId)
          historyDOM.replace({
            search: queryParams.toString(),
          })
        }
      }
      if (file.workspaces && workspaceId) {
        if (file.workspaces[0] != workspaceId) {
          handleClose()
        }
      }
    }, [file])

    useEffect(() => {
      setIsFileFirstState(isFileFirst())
      setIsFileLastState(isFileLast())
    }, [file])

    const allowedToEdit = isAllowed(RULES.EDIT_DOCUMENTS)
    const allowedToShare = isAllowed(RULES.SHARE_DOCUMENTS)

    useEffect(() => {
      setAllowedToUpgradeVersion(
        isAllowed(RULES.UPDATE_VERSIONS) || file?.username == username
      )
    }, [file])

    const arr = EXT2TYPE()

    window.mobileCheck = () => {
      let check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    }

    useEffect(() => {
      const updateFile = async () => {
        if (workspaceId === _id) {
          if (file.file !== undefined) {
            if (file.storageType === 'link') {
              setSignedLocation(file.preview)
            } else {
              //const resFile = await getFile(file.fileId)
              //const encodeURL = encodeURIComponent(resFile.signedLocation)
              if (isItemDoc(file.ext)) {
                const encodeURL = encodeURIComponent(file.preview)
                const url = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURL}`
                setSignedLocation(url)
              } else {
                // for previewing old versions
                const useFile = !!versionIndex
                  ? file.versions[versionIndex]
                  : file
                if (useFile.convertedPreview) {
                  setSignedLocation(useFile.convertedPreview)
                } else {
                  setSignedLocation(useFile.preview)
                }
              }
            }
          } else if (file.file === undefined) {
            setIsFile(false)
          }
        }
      }
      updateFile()
    }, [workspace, fileId, versionIndex, versionLength])

    useEffect(() => {
      /*eslint-disable eqeqeq*/
      if (workspaceId === _id) {
        if (organization && workspace) {
          getUsersFromWorkspace()
          getAllTags({})
        }
      }
    }, [workspace])

    useEffect(() => {
      if (workspaceId === _id) {
        if (signedLocation !== undefined) setViewReady(true)
      }
    }, [signedLocation])

    const isItemDoc = (ext) => {
      return [
        'pptx',
        'odt',
        'doc',
        'xlsx',
        'docx',
        'docm',
        'dotm',
        'dotx',
        'xlsb',
        'ppsx',
        'one',
        'xlsm',
        'xls',
        'xlt',
        'xltx',
      ].includes(ext)
    }

    const openDetailBar = useCallback(() => {
      setDetailBarOpen(!detailBarOpen)
    }, [detailBarOpen])

    const isImage = () => {
      if (
        !file.preview?.includes('drive.google.com') &&
        (arr[file.ext] === 'images' || arr[file.ext] === 'vectors')
      ) {
        return true
      } else {
        return false
      }
    }

    const isVideo = () => {
      if (
        !file.preview.includes('drive.google.com') &&
        arr[file.ext] === 'videos'
      ) {
        return true
      } else {
        return false
      }
    }

    const isAudio = () => {
      if (
        !file.preview.includes('drive.google.com') &&
        arr[file.ext] === 'audio'
      ) {
        return true
      } else {
        return false
      }
    }

    const parseUrl = () => {
      if (file?.preview.includes('drive.google.com')) {
        return file?.preview.replace('/view?', '/preview?')
      } else if (file?.preview.includes('youtube.com')) {
        return file?.preview.replace('watch?v=', 'embed/').split('&')[0]
      } else return signedLocation
    }

    const previewRender = () => {
      const src = file.preview ? file.preview : null
      if (isImage()) {
        return (
          <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                  contentStyle={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ProgressiveImage src={signedLocation} placeholder={src}>
                    {(source) => (
                      <img
                        src={signedLocation}
                        alt={file.name}
                        className={'detail-image'}
                        onLoad={() => setLoaded(true)}
                        style={
                          loaded
                            ? { pointerEvents: 'auto' }
                            : { display: 'none' }
                        }
                        onError={setLoaded(true)}
                        onContextMenu={
                          // prevent right click on image
                          isAllowed(RULES.DOWNLOAD_DOC)
                            ? null
                            : (e) => e.preventDefault()
                        }
                      />
                    )}
                  </ProgressiveImage>
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        )
      } else if (isVideo()) {
        return (
          //<video id="myVideo" width="100%" tabIndex="-1" webkit-playsinline="true" controlsList="nodownload" className={"detail-video"} controls src={signedLocation}>
          <video
            id="myVideo"
            width="100%"
            tabIndex="-1"
            webkit-playsinline={true}
            controlsList="nodownload"
            className={'detail-video'}
            crossorigin="anonymous"
            loop
            autoplay
            preload="metadata"
            controls
            playsInline
            src={signedLocation + '#t=0.001'} //added #t=0.001 as a Safari hack to make the video load
            onLoadedData={() => setLoaded(true)}
            onLoadedMetadata={() => setLoaded(true)}
            onError={() => setLoaded(true)}
            onLoadStart={() => {
              //add listener to the track to check if the caption is changing
              document
                .getElementById('myVideo')
                .textTracks.addEventListener(
                  'change',
                  updateStateOnCaptionChange
                )
            }}
            //clean up the listener
            onEnded={() => {
              document
                .getElementById('myVideo')
                .textTracks.removeEventListener(
                  'change',
                  updateStateOnCaptionChange
                )
            }}
          >
            {isCaption && (
              <track
                id="myTrack"
                src={captionSrc}
                label={t`Subtitles`}
                kind="subtitles"
                srclang={captionLang}
                default={userState?.transcription?.captionOpen ? true : false}
              />
            )}
          </video>
        )
      } else if (isAudio()) {
        return (
          <audio
            id="myAudio"
            width="100%"
            tabIndex="-1"
            webkit-playsinline="true"
            controlsList="nodownload"
            className={'detail-audio'}
            controls
            src={signedLocation}
            onLoadedData={() => setLoaded(true)}
          ></audio>
        )
      } else if (file.ext === 'www' && !file.isIframe) {
        if (!loaded) setLoaded(true)
        return (
          <div className={'not-iframable'}>
            {file?.file?.Thumbnail && <img src={file?.file?.Thumbnail} />}
            <div className={'site-name'}>
              <div className={'url'}>{file.preview}</div>
              <Trans>cannot be viewed in Tagbox.io</Trans>
            </div>
            <Button
              onClick={() => window.open(file.preview, '_blank').focus()}
              variant="contained"
              color="primary"
            >
              <Trans>Open in new tab</Trans>
            </Button>
          </div>
        )
      } else {
        if (!loaded) setLoaded(true)
        return (
          //change to isIframable
          <embed
            //</div>check if the iframe content is loaded or show an empty state
            className={'detail-iframe'}
            src={parseUrl()}
            title="File preview"
          ></embed>
        )
      }
    }

    //////////////////////////////////////
    ///// Export image
    //////////////////////////////////////

    useEffect(() => {
      // initiate format. If format is one of png, jpeg or webp, keep as is. otherwise, default to jpg
      const formatArray = ['png', 'jpeg', 'webp']
      const tempFormat = formatArray.includes(file.ext) ? file.ext : 'jpeg'

      ratios[6].value = file.metadata?.width / file.metadata?.height
      setExportFormat(tempFormat)
    }, [file.ext, file.metadata?.width, file.metadata?.height])

    useEffect(() => {
      // update crop when aspect ratio changes
      const tempCrop = cropperRef.current?.getCoordinates()
      setCrop(tempCrop)
    }, [aspectRatio])

    /* useEffect(() => {
      if (cropperRef.current) {
        cropperRef.current.setCoordinates(crop)
      }
    }, [crop?.height, crop?.width]) */

    const onCropChange = (crop, percentCrop) => {
      setCrop(crop.getCoordinates())
    }

    const onCropUpdate = (crop) => {
      setLoaded(true)
    }

    const previewCrop = (
      <Cropper
        ref={cropperRef}
        className="example__cropper"
        src={signedLocation}
        //canvas={false}
        crossOrigin={false} //having issues with the cropper. it said that the image is not loaded due to CORS policy but it should be fine
        //checkCrossOrigin={true}
        //checkOrientation={false}
        onChange={onCropChange}
        onUpdate={onCropUpdate}
        aspectRatio={aspectRatio.value}
        // make defaultSize a 1:1 square touching the image's edges
        defaultSize={{
          width: Math.min(file.metadata?.height, file.metadata?.width),
          height: Math.min(file.metadata?.height, file.metadata?.width),
        }}
      />
    )

    const createCroppedImage = useCallback(() => {
      console.log('Creating cropped image')
      return new Promise(async (resolve, reject) => {
        const canvas = document.createElement('canvas')
        const image = new Image()
        image.src = await proxyDownloadFile(file)
        image.setAttribute('crossorigin', 'anonymous')
        image.onload = () => {
          const scaledWidth = Math.max(crop?.width * (resize / 100), 1)
          const scaledHeight = Math.max(crop?.height * (resize / 100), 1)
          canvas.width = scaledWidth
          canvas.height = scaledHeight
          const ctx = canvas.getContext('2d')
          ctx.drawImage(
            image,
            crop?.left,
            crop?.top,
            crop?.width,
            crop?.height,
            0,
            0,
            scaledWidth,
            scaledHeight
          )
          canvas.toBlob(
            (blob) => {
              if (blob) {
                console.log(`Blob size: ${blob.size} bytes`)
                resolve(blob)
              } else {
                console.error('Blob creation failed')
                resolve(null)
              }
            },
            `image/${exportFormat}`,
            exportQuality / 100
          )
        }
        image.onerror = (error) => {
          console.error('Image loading error:', error)
          resolve(null)
        }
      })
    }, [crop, exportFormat, exportQuality, resize])

    const downloadImage = (blob, exportFormat) => {
      const link = document.createElement('a')
      const extension = exportFormat
      // give it it's original name, but replace the old extension with the new extension
      link.download = `${file.name.replace(/\.[^/.]+$/, '')}.${extension}`
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }

    // Usage
    const downloadCroppedImage = useCallback(() => {
      createCroppedImage()
        .then((blob) => downloadImage(blob, exportFormat))
        .catch((error) => console.error('Error creating cropped image:', error))
    }, [createCroppedImage, exportFormat])

    // check if the file is an image and not a psd or svg that are not supported for export
    const isExportableImage = () => {
      return isImage() && !['psd', 'svg'].includes(file.ext)
    }

    useEffect(() => {
      if (
        tabValue === 1 &&
        isExportableImage() &&
        isAllowed(RULES.DOWNLOAD_DOC)
      ) {
        setExportMode(true)
      } else {
        setExportMode(false)
      }
    }, [tabValue, isExportableImage()])

    //////////////////////////////////////
    /////
    //////////////////////////////////////

    const handleClose = () => {
      if (!isSingleFileUser()) {
        const queryParams = new URLSearchParams(location.search)

        if (queryParams.has('openedFile')) {
          queryParams.delete('openedFile')
          historyDOM.replace({
            search: queryParams.toString(),
          })
        }

        previewDialog(false)
        reset()
      } else {
        //do nothing for single file user
      }
    }

    const handlePrevNext = async (direction) => {
      setLoaded(false)
      setPrevNextLoad(true)
      setVersionIndex(null)
      if (direction === 'next' && isFileLastState) {
        handleClose()
      } else {
        await previewNextPrev(direction)
      }
      setPrevNextLoad(false)
    }

    const assetViewer = () => {
      return (
        <div className="asset-viewer-wrapper">
          {!isFileFirstState && (
            <IconButton
              className="prevNextButton left"
              onClick={() => handlePrevNext('prev')}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          {!prevNextLoad && !exportMode && previewRender()}
          {exportMode && previewCrop}
          {!isFileLastState && (
            <IconButton
              className="prevNextButton right"
              onClick={() => handlePrevNext('next')}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
          {!!versionIndex && (
            <MyButton
              variant="contained"
              color="primary"
              className="cancel-button"
              onClick={() => setVersionIndex(null)}
            >
              <Trans>Cancel preview</Trans>
            </MyButton>
          )}
        </div>
      )
    }

    const similarImages = () => {
      return (
        <div className="similar-images-conainer">
          <div className="similar-images-title">
            <h3 className="h3">
              <Trans>Similar images</Trans>
            </h3>
          </div>
          <div className="similar-images-wrapper">
            {similarFilesFiltered.map((similarFile, index) => {
              return (
                <File
                  key={index}
                  fullFile={similarFile}
                  itemId={`${similarFile.fileId}-k`}
                  previewDialog={previewDialog}
                  isSimilar={true}
                  previewFile={file}
                ></File>
              )
            })}
          </div>
        </div>
      )
    }

    return (
      <Dialog
        onClose={handleClose}
        fullScreen
        //maxWidth={"lg"}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        {isFile === false && workspaceId !== _id ? (
          <div className="no-files-container">
            <img className="dog" src={DOG} alt="Couldnt find the file" />
            <div className="no-files-text">
              <Trans>Couldn't find the page you are looking for!</Trans>
            </div>
            <Button
              onClick={() => history.push(`/w/${workspaceId}`)}
              variant="contained"
              color="secondary"
            >
              <Trans>Back home</Trans>
            </Button>
          </div>
        ) : viewReady ? (
          <div className={classes.previewContainer}>
            <TopBarPreview
              organization={organization}
              file={file}
              allowedToEdit={allowedToEdit}
              allowedToShare={allowedToShare}
              updateFileType={updateFileType}
              setCDN={setCDN}
              isSingleFileUser={isSingleFileUser}
              allowedToUpgradeVersion={allowedToUpgradeVersion}
              downloadFile={downloadFile}
              openDetailBar={openDetailBar}
              handleClose={handleClose}
              logo={logo}
              isAllowed={isAllowed}
              RULES={RULES}
              deleteFile={deleteFile}
              orgPlanLimits={orgPlanLimits}
              setIsPlanPrompt={setIsPlanPrompt}
              signedLocation={signedLocation}
              handlePrevNext={handlePrevNext}
            />
            <div className={'detail'}>
              {!loaded && (
                <div className="spinner-wrapper">
                  <CircularProgress color="primary" />
                </div>
              )}
              <div
                //style={loaded ? {} : { display: 'none' }}
                className={`detail-iframe-container ${
                  isImage() && 'detail-image-container'
                }`}
              >
                {assetViewer()}
                {isImage() &&
                  !exportMode &&
                  isAllowed(RULES.VIEW_SIMILAR) &&
                  similarFilesFiltered.length > 0 &&
                  similarImages()}
              </div>
              {isAllowed(RULES.VIEW_DETAILS_BAR) && (
                <DetailsBar
                  file={file}
                  fileId={file.fileId}
                  tags={file.tags}
                  detailBarOpen={detailBarOpen}
                  openDetailBar={openDetailBar}
                  setVersionIndex={setVersionIndex}
                  closePreview={handleClose}
                  setView={setView}
                  setExportMode={setExportMode}
                  createCroppedImage={createCroppedImage}
                  downloadCroppedImage={downloadCroppedImage}
                  aspectRatio={aspectRatio}
                  setAspectRatio={setAspectRatio}
                  exportFormat={exportFormat}
                  setExportFormat={setExportFormat}
                  exportQuality={exportQuality}
                  setExportQuality={setExportQuality}
                  crop={crop}
                  setCrop={setCrop}
                  resize={resize}
                  setResize={setResize}
                  ratios={ratios}
                  cropperRef={cropperRef}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  isExportableImage={isExportableImage}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="loading-spinner">
            <CircularProgress color="secondary" />
          </div>
        )}
      </Dialog>
    )
  }
)

export default Preview
