import keyMirror from 'key-mirror'
export const TOKEN_KEYWORD = 'thirdPartyDetails'

export const DROPBOX_TOKEN_KEY = 'DROPBOX_TOKEN_KEY'
export const USER_TOKEN = 'USER_TOKEN'

export const TAG_TYPE = keyMirror({
  REGULAR: null,
  CURATED: null,
})
