import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LaneTitle, NewLaneButtons, Section } from './MyGlobalStyle'
import NewLaneTitleEditor from 'react-trello/dist/widgets/NewLaneTitleEditor'
import { MyButton } from '../_MyComponents'
import uuidv1 from 'uuid/v1'
import { t, Trans } from '@lingui/macro'

class MyNewLane extends Component {
  handleSubmit = () => {
    this.props.onAdd({
      id: uuidv1(),
      title: this.getValue(),
    })
  }

  getValue = () => this.refInput.getValue()

  onClickOutside = (a, b, c) => {
    if (this.getValue().length > 0) {
      this.handleSubmit()
    } else {
      this.props.onCancel()
    }
  }

  render() {
    const { onCancel, t } = this.props
    return (
      <Section>
        <LaneTitle>
          <NewLaneTitleEditor
            ref={(ref) => (this.refInput = ref)}
            placeholder={t('placeholder.title')}
            onCancel={this.props.onCancel}
            onSave={this.handleSubmit}
            resize="vertical"
            border
            autoFocus
          />
        </LaneTitle>
        <NewLaneButtons className="card-button-wrapper">
          <MyButton size="small" onClick={onCancel}>
            <Trans>Cancel</Trans>
          </MyButton>
          <MyButton
            size="small"
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
          >
            <Trans>Add category</Trans>
          </MyButton>
        </NewLaneButtons>
      </Section>
    )
  }
}

MyNewLane.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

MyNewLane.defaultProps = {}

export default MyNewLane
