import axios from 'axios'
import { host } from '../../../services/host'
import { getHeaderMerged } from '../util'
import { getQueryParam } from '../../../util/httpHelper'

export const getCategories = async ({ orgId, workspaceId }) => {
  const query = getQueryParam({
    orgId,
    workspaceId,
  })
  const url = `${host}/categories?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const createCategory = async ({
  orgId,
  workspaceId,
  name,
  type = 'regular',
}) => {
  const url = `${host}/categories`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { name, type, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const deleteCategories = async ({ ids }) => {
  const url = `${host}/categories/multiple`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { ids },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const deleteCategory = async ({ id }) => {
  const url = `${host}/categories/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const renameCategory = async ({ id, name }) => {
  const url = `${host}/categories/name/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { name },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const reorderCategory = async ({ id, order }) => {
  const url = `${host}/categories/order/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { order },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const resortCategory = async ({ id, sortBy, sortOrder }) => {
  const url = `${host}/categories/sort/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { sortBy, sortOrder },
    })
    return data
  } catch (err) {
    throw err
  }
}
