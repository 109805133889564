import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useDropzone } from 'react-dropzone'
import UPLOAD from '../../assets/upload.svg'
import { MyButton } from '../_MyComponents'
import LinearProgress from '@mui/material/LinearProgress'
import IMAGE from '../../assets/image.svg'

const SimpleUpload = observer(
  ({
    // from parent
    isOpen,
    close,
    isMobile,
    title,
    buttonText,
    fileSpec,
    endFunction,
  }) => {
    const { filesStore } = useStore()

    const {
      simpleUploadFileToS3,
      filesInQ = [],
      isUploadFinished,
      isUpload,
      closeUpload,
      cleanUploadQueue,
    } = filesStore

    const [progress, setProgress] = React.useState(0)
    const [newFileResult, setNewFileResult] = React.useState(null)

    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }, [])

    const onCloseDialog = async () => {
      closeUpload()
      close()
    }

    const onDrop = async (selectedFiles) => {
      const res = await simpleUploadFileToS3(selectedFiles[0])
      await onFinish(res)
      setNewFileResult(res)
    }

    // redundancy with onDrop?
    const handleUpload = async () => {
      const fileInput = document.getElementById('simple-upload-input')
      const selectedFiles = [...fileInput.files]
      const res = await simpleUploadFileToS3(selectedFiles[0])
      await onFinish(res)
      setNewFileResult(res)
    }

    const onFinish = async (res) => {
      await endFunction(res.s3location)
      close()
      //closeUpload();
      cleanUploadQueue()
    }

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
      onDrop,
      accept: 'image/*',
      noDragEventsBubbling: true,
    })

    const formatFileSize = (size) => {
      const newSize = size / 1000
      if (newSize < 1000) {
        return `${newSize.toFixed(2)}KB`
      } else {
        return `${(newSize / 1000).toFixed(2)}MB`
      }
    }

    const files = filesInQ.map((file, idx) => (
      <div className="uploading-file-container" key={file.name}>
        <img src={IMAGE} alt="image" />
        <div className="content">
          <div className="text">
            <p className="file-name light">{file.name}</p>
            <p className="file-size">{formatFileSize(file.size)}</p>
          </div>
          <LinearProgress
            variant="determinate"
            style={{ color: '#FBC02D' }}
            color="inherit"
            value={isUploadFinished ? 100 : progress}
          />
        </div>
      </div>
    ))

    return (
      <Dialog
        onClose={onCloseDialog}
        fullWidth={isMobile ? false : true}
        maxWidth={'xs'}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>

        <DialogContent className="dialog-content">
          <input {...getInputProps()} />
          <div {...getRootProps()} className={'dropzone-area solid'}>
            <div className="flex-row">
              <img src={UPLOAD} alt="upload" />
              <div className="dropzone-text">
                <p className="p">
                  <Trans>Upload a photo</Trans>
                </p>
                <p className="p light">{fileSpec}</p>
              </div>
            </div>
            <label htmlFor="simple-upload-input">
              <MyButton
                variant="contained"
                component="span"
                color="primary"
                onClick={(e) => e.preventDefault()}
              >
                <Trans>Upload</Trans>
              </MyButton>
            </label>
          </div>
          <div className="uploading-files-container">{files}</div>
          <input
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            id="simple-upload-input"
            name="file"
            type="file"
            onClick={(e) => {
              e.target.value = ''
            }}
            onChange={(e) => handleUpload(e.target.files)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog} variant="contained" color="inherit">
            <Trans>Cancel</Trans>
          </Button>
          <Button
            onClick={() => onFinish(newFileResult)} // redundancy, never supposed to be activated.
            color="primary"
            variant="contained"
            disabled={filesInQ.length < 1 || isUpload || newFileResult === null}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default SimpleUpload
