import React, { useEffect } from 'react'
import { MyKeyword } from '../_MyComponents'
import { EXT } from '../SearchByFileType/filesExt'
import './SearchByType.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import linkIcon from '../../assets/link.svg'
import videoIcon from '../../assets/video.svg'
import imageIcon from '../../assets/image.svg'
import vectorIcon from '../../assets/vector.svg'
import docIcon from '../../assets/document.svg'
import audioIcon from '../../assets/audio.svg'

import landscapeIcon from '../../assets/landscape.svg'
import portraitIcon from '../../assets/portrait.svg'
import squareIcon from '../../assets/square.svg'
import NOTAG from '../../assets/no-tag.svg'

const SearchByType = observer(() => {
  const { filesStore } = useStore()

  const {
    exts,
    selectedExt,
    selectedFileType,
    onExtSelected,
    onFileTypeSelected,
    partialView,
    selectedDuration,
    onDurationSelected,
    selectedFileSize,
    onFileSizeSelected,
    selectedOrientationMode,
    onOrientationModeSelected,
    selectedResolution,
    onResolutionSelected,
    onUnTaggedChanged,
    unTagged,
  } = filesStore

  const extList = exts.map((ext) => ext._id)
  const types = []
  const images = EXT.images.filter((value) => extList.includes(value))
  const videos = EXT.videos.filter((value) => extList.includes(value))
  const audio = EXT.audio.filter((value) => extList.includes(value))
  const links = EXT.links.filter((value) => extList.includes(value))
  const vectors = EXT.vectors.filter((value) => extList.includes(value))
  // to find 'docs' which are actually 'else'
  const allTypes = EXT.images.concat(
    EXT.videos,
    EXT.audio,
    EXT.links,
    EXT.vectors
  )
  const docs = extList.filter((elem) => !allTypes.includes(elem))

  const onExtClicked = (extension) => {
    const selected = selectedExt.find((ext) => ext === extension)

    if (selected) {
      const newSelectedExt = selectedExt.filter((ext) => ext != extension)
      onExtSelected(newSelectedExt)
    } else {
      const newSelectedExt = selectedExt.concat(extension)
      onExtSelected(newSelectedExt)
    }
  }

  const onFormatClicked = ({ type, extensions }) => {
    const selected = selectedFileType?.name === type.name

    if (selected) {
      onFileTypeSelected([], { name: '' })
    } else {
      onFileTypeSelected(extensions, type)
    }
  }

  const onDurationClicked = ({ name, key, lte, gte }) => {
    const selected = selectedDuration?.key === key

    if (selected) {
      onDurationSelected({})
    } else {
      onDurationSelected({ name, key, lte, gte })
    }
  }

  const onFileSizeClicked = ({ name, key, lte, gte }) => {
    const selected = selectedFileSize?.key === key

    if (selected) {
      onFileSizeSelected({})
    } else {
      onFileSizeSelected({ name, key, lte, gte })
    }
  }

  const onOrientationModeClicked = (mode) => {
    const selected = selectedOrientationMode.key === mode.key

    if (selected) {
      onOrientationModeSelected({ key: null })
    } else {
      onOrientationModeSelected(mode)
    }
  }

  const onResolutionClicked = (resolution) => {
    const selected = selectedResolution.key === resolution.key

    if (selected) {
      onResolutionSelected({})
    } else {
      onResolutionSelected(resolution)
    }
  }

  const SearchByExt = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">
              <Trans>Format</Trans>
            </h2>
          </div>
        )}
        <div className="filter-tags">
          {/* {!!exts?.length && <div className={"title-2"}>File Type</div>} */}
          {exts
            .slice()
            .sort((a, b) => (a.count > b.count ? -1 : 1))
            .slice(0, 9)
            .map((ext, idx) => {
              const { _id, count } = ext
              const selected = selectedExt.find((ext) => ext === _id)
              return (
                <MyKeyword
                  onClick={() => {
                    onExtClicked(_id)
                  }}
                  selected={selected}
                  text={`${_id}`}
                  key={`${_id}`}
                />
              )
            })}
        </div>
      </div>
    )
  }

  const SearchByFormat = () => {
    if (!!images.length) {
      types.push({ name: t`Image`, icon: imageIcon, exts: images })
    }
    if (!!videos.length) {
      types.push({ name: t`Video`, icon: videoIcon, exts: videos })
    }
    if (!!audio.length) {
      types.push({ name: t`Audio`, icon: audioIcon, exts: audio })
    }
    if (!!docs.length) {
      types.push({ name: t`Doc`, icon: docIcon, exts: docs })
    }
    if (!!links.length) {
      types.push({ name: t`Link`, icon: linkIcon, exts: links })
    }
    if (!!vectors.length) {
      types.push({ name: t`Vector`, icon: vectorIcon, exts: vectors })
    }

    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">
              <Trans>Type</Trans>
            </h2>
          </div>
        )}
        <div className="filter-tags">
          {types
            .slice()
            .sort((a, b) => (a.count > b.count ? -1 : 1))
            .map((format) => {
              //const selected = selectedExt.find((ext) => ext === _id)
              return (
                <MyKeyword
                  onClick={() => {
                    onFormatClicked({
                      type: { name: format.name, icon: format.icon },
                      extensions: format.exts,
                    })
                  }}
                  selected={selectedFileType.name === format.name}
                  text={`${format.name}`}
                  key={`${format.name}`}
                  icon={format.icon}
                />
              )
            })}
        </div>
      </div>
    )
  }

  const SearchByDuration = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">
              <Trans>Video duration</Trans>
            </h2>
          </div>
        )}
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onDurationClicked({
                name: t`Up to 1m`,
                key: '1m',
                lte: 1 * 60 * 1000,
                gte: 50,
              })
            }
            selected={selectedDuration?.key === '1m'}
            text={t`Up to 1m`}
            key={`1m`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({
                name: t`1m+`,
                key: '1m+',
                gte: 1 * 60 * 1000,
              })
            }
            selected={selectedDuration?.key === '1m+'}
            text={t`1m+`}
            key={`1m+`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({
                name: t`5m+`,
                key: '5m+',
                gte: 5 * 60 * 1000,
              })
            }
            selected={selectedDuration?.key === '5m+'}
            text={t`5m+`}
            key={`5m+`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({
                name: t`15m+`,
                key: '15m+',
                gte: 15 * 60 * 1000,
              })
            }
            selected={selectedDuration?.key === '15m+'}
            text={t`15m+`}
            key={`15m+`}
          />
          <MyKeyword
            onClick={() =>
              onDurationClicked({
                name: t`1h+`,
                key: '1h+',
                gte: 60 * 60 * 1000,
              })
            }
            selected={selectedDuration?.key === '1h+'}
            text={t`1h+`}
            key={`1h+`}
          />
        </div>
      </div>
    )
  }

  const SearchByOrientation = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">
              <Trans>Orientation</Trans>
            </h2>
          </div>
        )}

        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onOrientationModeClicked({
                name: t`Landscape`,
                key: 'landscape',
                icon: landscapeIcon,
              })
            }
            selected={selectedOrientationMode?.key === 'landscape'}
            text={t`Landscape`}
            key={`landscape`}
            icon={landscapeIcon}
          />
          <MyKeyword
            onClick={() =>
              onOrientationModeClicked({
                name: t`Portrait`,
                key: 'portrait',
                icon: portraitIcon,
              })
            }
            selected={selectedOrientationMode?.key === 'portrait'}
            text={t`Portrait`}
            key={`portrait`}
            icon={portraitIcon}
          />
          <MyKeyword
            onClick={() =>
              onOrientationModeClicked({
                name: t`Square`,
                key: 'square',
                icon: squareIcon,
              })
            }
            selected={selectedOrientationMode.key === 'square'}
            text={t`Square`}
            key={`square`}
            icon={squareIcon}
          />
        </div>
      </div>
    )
  }

  const SearchByResolution = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">
              <Trans>Resolution</Trans>
            </h2>
          </div>
        )}
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onResolutionClicked({ name: t`Thumbnail`, key: 'thumbnail' })
            }
            selected={selectedResolution?.key === 'thumbnail'}
            text={t`Thumbnail`}
            key={`thumbnail`}
          />
          <MyKeyword
            onClick={() =>
              onResolutionClicked({ name: t`Low-res`, key: 'low-res' })
            }
            selected={selectedResolution?.key === 'low-res'}
            text={t`Low-res`}
            key={`low-res`}
          />
          <MyKeyword
            onClick={() =>
              onResolutionClicked({ name: t`Medium-res`, key: 'medium-res' })
            }
            selected={selectedResolution?.key === 'medium-res'}
            text={t`Medium-res`}
            key={`medium-res`}
          />
          <MyKeyword
            onClick={() =>
              onResolutionClicked({ name: t`High-res`, key: 'high-res' })
            }
            selected={selectedResolution?.key === 'high-res'}
            text={t`High-res`}
            key={`high-res`}
          />
        </div>
      </div>
    )
  }

  const SearchBySize = () => {
    return (
      <div className="filter-section">
        {!!exts.length && (
          <div className="filter-title">
            <h2 className="h2">
              <Trans>File size</Trans>
            </h2>
          </div>
        )}
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({
                name: t`Up to 1MB`,
                key: '1MB',
                lte: 1 * 1024 * 1024,
                gte: 1,
              })
            }
            selected={selectedFileSize?.key === '1MB'}
            text={t`Up to 1MB`}
            key={`1MB`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({
                name: t`1MB+`,
                key: '1MB+',
                gte: 1 * 1024 * 1024,
              })
            }
            selected={selectedFileSize?.key === '1MB+'}
            text={t`1MB+`}
            key={`1MB+`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({
                name: t`10MB+`,
                key: '10MB+',
                gte: 10 * 1024 * 1024,
              })
            }
            selected={selectedFileSize?.key === '10MB+'}
            text={t`10MB+`}
            key={`10MB+`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({
                name: t`100MB+`,
                key: '100MB+',
                gte: 100 * 1024 * 1024,
              })
            }
            selected={selectedFileSize?.key === '100MB+'}
            text={t`100MB+`}
            key={`100MB+`}
          />
          <MyKeyword
            onClick={() =>
              onFileSizeClicked({
                name: t`1GB+`,
                key: '1GB+',
                gte: 1 * 1024 * 1024 * 1024,
              })
            }
            selected={selectedFileSize?.key === '1GB+'}
            text={t`1GB+`}
            key={`1GB+`}
          />
        </div>
      </div>
    )
  }

  const SearchByNoTags = () => {
    return (
      <div className="filter-section">
        <h2 className="h2">
          <Trans>Tags</Trans>
        </h2>
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onUnTaggedChanged({
                name: t`Untagged files`,
                key: 'untagged-files',
              })
            }
            selected={unTagged}
            text={t`Untagged files`}
            key={`untagged-files`}
            icon={NOTAG}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="filter-content-wrapper">
      <SearchByFormat />
      <SearchByExt />
      {!partialView &&
        (!!videos.length || !!selectedDuration?.name?.length) && (
          <SearchByDuration />
        )}
      {!partialView &&
        (!!videos.length ||
          !!images.length ||
          !!selectedOrientationMode?.name?.length) && <SearchByOrientation />}
      {!partialView &&
        (!!videos.length ||
          !!images.length ||
          !!selectedResolution?.length) && <SearchByResolution />}
      {!partialView && <SearchBySize />}
      <SearchByNoTags />
    </div>
  )
})

export default SearchByType
