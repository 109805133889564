import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider' // Import useStore hook
import App from './App'
import './App.css'

const InjectedApp = () => {
  const { authenticationStore, registerStore, rootStore } = useStore()

  // Extract the properties you need from the stores
  const { doesUserAuthenticated } = authenticationStore
  const { doesUserRegistered } = registerStore
  const { appMounted, appWillUnmount } = rootStore

  return (
    <App
      appMounted={appMounted}
      appWillUnmount={appWillUnmount}
      doesUserAuthenticated={doesUserAuthenticated}
      doesUserRegistered={doesUserRegistered}
    />
  )
}

export default observer(InjectedApp)
