import React, { useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { MyKeyword } from '../_MyComponents'
import Avatar from '@mui/material/Avatar'
import './SearchByPeople.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import positiveIcon from '../../assets/positive.svg'
import neutralIcon from '../../assets/neutral.svg'
import negativeIcon from '../../assets/negative.svg'

const SearchByPeople = observer(
  ({
    // from parent
    partialView,
    handleShowAll,
  }) => {
    const { filesStore, authenticationStore, orgsWorkspacesStore } = useStore()

    const {
      people,
      persons,
      onPeopleSelected,
      onPersonSelected,
      selectedPersons,
    } = filesStore

    const { isAnonymous } = authenticationStore

    const { workspaceType } = orgsWorkspacesStore

    const { age } = people

    const onPeopleClicked = (newPeople) => {
      onPeopleSelected(newPeople)
    }

    const SearchByFace = () => {
      return (
        <div className="filter-section">
          <div className="filter-title">
            <div className="h2">
              <Trans>People in images</Trans>
            </div>
            <p className="p all-people" onClick={handleShowAll}>
              <Trans>View all</Trans>
            </p>
          </div>

          <div className="filter-tags faces">
            {persons
              .slice()
              .sort((a, b) => b.count - a.count)
              .slice(0, 12)
              .map((person, idx) => {
                const { name, defaultFaceLocation } = person
                return (
                  <Tooltip
                    key={`person-${person._id}`}
                    title={name || t`Unnamed person`}
                  >
                    <Avatar
                      alt={name || 'Unnamed person'}
                      src={defaultFaceLocation}
                      onClick={() => onPersonSelected(person)}
                      sx={
                        selectedPersons.find((one) => one._id === person._id)
                          ? { outline: '2px solid #FBC02D' }
                          : null
                      }
                    />
                  </Tooltip>
                )
              })}
          </div>
        </div>
      )
    }

    const SearchByEmotion = () => {
      return (
        <div className="filter-section">
          <div className="h2">
            <Trans>Emotions</Trans>
          </div>
          <div className="filter-tags">
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  emotion: {
                    positive: !people.emotion.positive,
                    neutral: false,
                    negative: false,
                  },
                })
              }}
              selected={people.emotion.positive}
              text={t`Positive`}
              icon={positiveIcon}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  emotion: {
                    positive: false,
                    neutral: !people.emotion.neutral,
                    negative: false,
                  },
                })
              }}
              selected={people.emotion.neutral}
              text={t`Neutral`}
              icon={neutralIcon}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  emotion: {
                    positive: false,
                    neutral: false,
                    negative: !people.emotion.negative,
                  },
                })
              }}
              selected={people.emotion.negative}
              text={t`Negative`}
              icon={negativeIcon}
            />
          </div>
        </div>
      )
    }

    const SearchByGender = () => {
      return (
        <div className="filter-section">
          <div className="h2">
            <Trans>Gender</Trans>
          </div>
          <div className="filter-tags">
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  gender: {
                    male: !people.gender.male,
                    female: false,
                    both: false,
                  },
                })
              }}
              selected={people.gender.male}
              text={t`Male`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  gender: {
                    male: false,
                    female: !people.gender.female,
                    both: false,
                  },
                })
              }}
              selected={people.gender.female}
              text={t`Female`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  gender: {
                    male: false,
                    female: false,
                    both: !people.gender.both,
                  },
                })
              }}
              selected={people.gender.both}
              text={t`Both`}
            />
          </div>
        </div>
      )
    }

    const SearchByCount = () => {
      return (
        <div className="filter-section">
          <div className="h2">
            <Trans>Number of people</Trans>
          </div>
          <div className="filter-tags">
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  number: people.number === 0 ? -1 : 0,
                })
              }}
              selected={people.number === 0}
              text={t`None`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  number: people.number === 1 ? -1 : 1,
                })
              }}
              selected={people.number === 1}
              text={'1'}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  number: people.number === 2 ? -1 : 2,
                })
              }}
              selected={people.number === 2}
              text={'2'}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  number: people.number === 3 ? -1 : 3,
                })
              }}
              selected={people.number === 3}
              text={'3+'}
            />
          </div>
        </div>
      )
    }

    const SearchByAge = () => {
      return (
        <div className="filter-section">
          <div className="h2">
            <Trans>Age</Trans>
          </div>
          <div className="filter-tags">
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, infants: !people.age.infants },
                })
              }}
              selected={people.age.infants}
              text={t`Infants`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, children: !people.age.children },
                })
              }}
              selected={people.age.children}
              text={t`Children`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, teenagers: !people.age.teenagers },
                })
              }}
              selected={people.age.teenagers}
              text={t`Teens`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, twenties: !people.age.twenties },
                })
              }}
              selected={people.age.twenties}
              text={t`20s`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, thirties: !people.age.thirties },
                })
              }}
              selected={people.age.thirties}
              text={t`30s`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, forties: !people.age.forties },
                })
              }}
              selected={people.age.forties}
              text={t`40s`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, fifties: !people.age.fifties },
                })
              }}
              selected={people.age.fifties}
              text={t`50s`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, sixties: !people.age.sixties },
                })
              }}
              selected={people.age.sixties}
              text={t`60s`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  age: { ...age, older: !people.age.older },
                })
              }}
              selected={people.age.older}
              text={t`Older`}
            />
          </div>
        </div>
      )
    }

    const SearchByHeadPose = () => {
      return (
        <div className="filter-section">
          <div className="h2">
            <Trans>Head pose</Trans>
          </div>
          <div className="filter-tags">
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  pose: {
                    straight: !people.pose.straight,
                    sideways: false,
                    up: false,
                    down: false,
                    tilted: false,
                  },
                })
              }}
              selected={people.pose.straight}
              text={t`Straight`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  pose: {
                    straight: false,
                    sideways: !people.pose.sideways,
                    up: false,
                    down: false,
                    tilted: false,
                  },
                })
              }}
              selected={people.pose.sideways}
              text={t`Sideways`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  pose: {
                    straight: false,
                    sideways: false,
                    up: !people.pose.up,
                    down: false,
                    tilted: false,
                  },
                })
              }}
              selected={people.pose.up}
              text={t`Up`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  pose: {
                    straight: false,
                    sideways: false,
                    up: false,
                    down: !people.pose.down,
                    tilted: false,
                  },
                })
              }}
              selected={people.pose.down}
              text={t`Down`}
            />
            <MyKeyword
              onClick={() => {
                onPeopleClicked({
                  ...people,
                  pose: {
                    straight: false,
                    sideways: false,
                    up: false,
                    down: false,
                    tilted: !people.pose.tilted,
                  },
                })
              }}
              selected={people.pose.tilted}
              text={t`Tilted`}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="filter-content-wrapper">
        {!!persons?.length && (!isAnonymous || workspaceType === 'public') && (
          <SearchByFace />
        )}
        {!partialView && <SearchByEmotion />}
        {!partialView && <SearchByGender />}
        {!partialView && <SearchByCount />}
        {!partialView && <SearchByAge />}
        {!partialView && <SearchByHeadPose />}
      </div>
    )
  }
)

export default SearchByPeople
