import keyMirror from 'key-mirror'

const appActions = keyMirror({
  APPLICATION_MOUNTED: null,
  APPLICATION_UNMOUNTED: null,
  LOGOUT_REQUESTED: null,
  UNAUTHORIZED: null,
  LOGIN_ERROR: null,
  GET_CURRENT_USER: null,
  THIRD_PARTY_RESPONSE: null,
  FAILED_TO_STORE_3RD_PARTY_TOKEN: null,
  GET_FILES: null,
  GET_TAGS: null,
  GET_ALL_TAGS: null,
  WINDOW_RESIZED: null,
  GET_FILES_USERS: null,
  GET_POPULAR_TAGS: null,
  //GET_POPULAR_USERS: null,
  HTTP_API_STARTED: null,
  HTTP_API_ENDED: null,
  ACCOUNTS_REQUESTED: null,
  VERIFY_ACCOUNT_REQUESTED: null,
  USER_LOGGED_IN: null,
})

const Actions = {
  ...appActions,
}

export default Actions
