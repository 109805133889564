import React, { useState, useEffect } from 'react'
import './EmptyStateMain.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import MAGNIFYING from '../../assets/magnifying-glass.svg'
import CheckIcon from '@mui/icons-material/Check'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import Dialog from '@material-ui/core/Dialog'
import { MyButton } from '../_MyComponents'

import { Steps } from 'intro.js-react'
import { steps1 } from '../_intro-js'
import DemoUpload from '../DemoUpload/DemoUpload.js'

const EmptyStateMain = observer(
  ({
    // from parent
    openUpload,
  }) => {
    const { authenticationStore } = useStore()

    const { userState, updateUserState, isAnonymous } = authenticationStore

    const [introJS, setIntroJS] = useState(null)
    const [introVideo, setIntroVideo] = useState(false)

    const onCloseIntro = () => {
      updateUserState({ 'onboarding.upload': true })
    }

    const onCloseIntroVideo = () => {
      setIntroVideo(false)
      if (introJS === null) {
        setTimeout(() => {
          setIntroJS(true)
        }, 1000)
      }
    }

    useEffect(() => {
      if (userState && !userState?.onboarding?.upload) {
        setIntroVideo(true)
      }
    }, [userState])

    return (
      <div className="empty-state">
        <div className="empty-state-card-container">
          <div className="empty-state-card">
            <div className="empty-state-content">
              <div className="file-type-icon-wrappaer">
                <img
                  src={MAGNIFYING}
                  className="file-type-icon"
                  alt="search-icon"
                />
              </div>
              <div className="es-card-title">
                <Trans>Upload a few images to get started.</Trans>
              </div>
              <div className="es-card-desc">
                <Trans>
                  Once you upload, we'll help you organize them using the magic
                  of tags
                </Trans>
              </div>
            </div>
            <MyButton
              onClick={openUpload}
              className="es-card-button"
              variant="contained"
              color="primary"
            >
              <label /* htmlFor="contained-button-file" */>
                <Trans>Upload</Trans>
              </label>
            </MyButton>
            <p
              className="p light"
              style={{ cursor: 'pointer' }}
              onClick={() => setIntroVideo(true)}
            >
              <Trans>Watch explainer video</Trans>
            </p>
          </div>
        </div>
        <DemoUpload openUpload={openUpload} />
        <Dialog
          onClose={onCloseIntroVideo}
          maxWidth={'md'}
          aria-labelledby="simple-dialog-title"
          open={introVideo}
        >
          <div className="walkthrough-container">
            <div className="dialog-header">
              <Trans>Welcome to Tagbox.io</Trans>{' '}
              <span role="img" aria-label="hi">
                👋
              </span>
            </div>
            <div className="dialog-content">
              <div className="dialog-text">
                <div className="h1">
                  <Trans>Manage all your creative assets in one place</Trans>
                </div>
                <div>
                  <div className="p">
                    <CheckIcon fontSize="small" />
                    <Trans>Upload your assets of any file type</Trans>
                  </div>
                  <div className="p">
                    <CheckIcon fontSize="small" />
                    <Trans>Organize automatically using smart tags</Trans>
                  </div>
                  <div className="p">
                    <CheckIcon fontSize="small" />
                    <Trans>
                      Search and filter to instantly find the assets you're
                      looking for
                    </Trans>
                  </div>
                </div>
                <MyButton
                  variant="contained"
                  color="primary"
                  onClick={onCloseIntroVideo}
                  sx={{ alignSelf: 'flex-end' }}
                  endIcon={<ArrowForwardIcon />}
                >
                  <Trans>Let's start</Trans>
                </MyButton>
              </div>
              <div className="dialog-video">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/hOirEwMBPT8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </Dialog>
        {!isAnonymous && userState && !userState?.onboarding?.upload && (
          <Steps
            enabled={introJS}
            steps={steps1}
            initialStep={0}
            onExit={onCloseIntro}
            options={{
              doneLabel: 'Start',
              disableInteraction: false,
              showBullets: false,
            }}
          />
        )}
      </div>
    )
  }
)

export default EmptyStateMain
