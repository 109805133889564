import axios from 'axios'
import { host } from '../../host'
import { getHeaderMerged } from '../util'
import { getQueryParam } from '../../../util/httpHelper'

export const getCollections = async ({
  orgId,
  workspaceId,
  text,
  skip = 0,
  limit = 50,
}) => {
  const query = getQueryParam({
    orgId,
    workspaceId,
    text,
    skip,
    limit,
  })
  const url = `${host}/collections?${query}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}


export const createCollection = async ({
  orgId,
  workspaceId,
  name,
  description = '',
  type = 'regular',
}) => {
  const url = `${host}/collections`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { name, description, type, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const deleteCollections = async ({ ids }) => {
  const url = `${host}/collections/multiple`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { ids },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const deleteCollection = async ({ id }) => {
  const url = `${host}/collections/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    throw err
  }
}

export const getCollection = async ({ id }) => {
  const url = `${host}/collections/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'GET',
      headers: await getHeaderMerged(),
    })
    return data
  } catch (err) {
    return { public: false }
  }
}

export const editCollection = async ({ id, name, description }) => {
  const url = `${host}/collections/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { name, description },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateCollectionPrivacy = async ({ id, publicMode }) => {
  const url = `${host}/collections/privacy/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { publicMode },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateCollectionCover = async ({ id, coverImage }) => {
  const url = `${host}/collections/cover/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { coverImage },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateCollectionLocation = async ({ id, location }) => {
  const url = `${host}/collections/location/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { location },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateCollectionType = async ({ id, type }) => {
  const url = `${host}/collections/type/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { type },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateCollectionFMP = async ({ id, isFMP }) => {
  const url = `${host}/collections/fmp/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { isFMP },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const inviteToCollection = async ({
  id,
  invitedUsers,
  orgId,
  workspaceId,
}) => {
  const url = `${host}/collections/users/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { invitedUsers, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const removeFromCollection = async ({
  id,
  removedUsers,
  orgId,
  workspaceId,
}) => {
  const url = `${host}/collections/users/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { removedUsers, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateUserRoleInCollection = async ({
  id,
  updatedUser,
  orgId,
  workspaceId,
}) => {
  const url = `${host}/collections/user/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { updatedUser, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const runCollectionRules = async ({ id }) => {
  const url = `${host}/collections/run-rules/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      //data: { rules },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const updateCollectionRules = async ({ id, rules }) => {
  const url = `${host}/collections/rules/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { rules },
    })
    return data
  } catch (err) {
    throw err
  }
}
