import axios from 'axios'
import { host } from '../../../services/host'
import { getHeaderMerged } from '../util'

export const createTag = async ({ orgId, workspaceId, tag, type }) => {
  const url = `${host}/tags`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { name: tag, type, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const checkSimilarTags = async ({ orgId, workspaceId, tag, type }) => {
  const url = `${host}/tags/similar`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { name: tag, type, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const changeTagOrderlist = async (orgId, tagId, list, name) => {
  const url = `${host}/tags/orderlist`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { tagId, orderList: [...list], name },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const SplitTags = async ({ orgId, workspaceId, tags }) => {
  const url = `${host}/tags/split`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { splitTags: tags, orgId, workspaceId },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const MergeTags = async ({ orgId, tags }) => {
  const url = `${host}/tags/merge`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { mergeTags: tags },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const DeleteTags = async ({ orgId, workspace, ids }) => {
  const url = `${host}/tags/multiple`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { tagIds: ids },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const renameTag = async ({ id, name, force }) => {
  const url = `${host}/tags/name/${id}`
  try {
    const { data } = await axios({
      url,
      method: 'PUT',
      headers: await getHeaderMerged(),
      data: { name, force },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const addCategories = async ({ tagId, categoriesIds }) => {
  const url = `${host}/tags/categories/${tagId}`
  try {
    const { data } = await axios({
      url,
      method: 'POST',
      headers: await getHeaderMerged(),
      data: { categoriesIds },
    })
    return data
  } catch (err) {
    throw err
  }
}

export const removeCategories = async ({ tagId, categoriesIds }) => {
  const url = `${host}/tags/categories/${tagId}`
  try {
    const { data } = await axios({
      url,
      method: 'DELETE',
      headers: await getHeaderMerged(),
      data: { categoriesIds },
    })
    return data
  } catch (err) {
    throw err
  }
}
