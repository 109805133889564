export function langToCountry(lang) {
  const languageToCountryCode = {
    ar: 'sa', // Saudi Arabia, also widely spoken in MENA region
    zh: 'cn', // China, also spoken in Taiwan (tw), Singapore (sg)
    en: 'us', // United States, also an official language in UK, CA, AU, etc.
    fr: 'fr', // France, removed because the language code matches the country code
    de: 'de', // Germany, also an official language in Austria (at), Switzerland (ch)
    hi: 'in', // India
    it: 'it', // Italy, removed because the language code matches the country code
    ja: 'jp', // Japan
    ko: 'kr', // South Korea
    pt: 'pt', // Portugal, also widely spoken in Brazil (br)
    ru: 'ru', // Russia, also spoken in Belarus (by), Kazakhstan (kz)
    es: 'es', // Spain, also widely spoken in Latin America
    sv: 'se', // Sweden, also spoken in Finland (fi)
    tr: 'tr', // Turkey, also spoken in Cyprus (cy)
    uk: 'ua', // Ukraine
    iw: 'il', // Hebrew for Israel, 'iw' is the old code, 'he' is the new one
    pl: 'pl', // Poland
    cs: 'cz', // Czech Republic
    sk: 'sk', // Slovakia
    hu: 'hu', // Hungary
    ro: 'ro', // Romania
    bg: 'bg', // Bulgaria
    sr: 'rs', // Serbia
    hr: 'hr', // Croatia
    sl: 'si', // Slovenia
    et: 'ee', // Estonia
    lv: 'lv', // Latvia
    lt: 'lt', // Lithuania
    fi: 'fi', // Finland
    da: 'dk', // Denmark
    no: 'no', // Norway
    el: 'gr', // Greece
    he: 'il', // Israel, modern code for Hebrew
    fa: 'ir', // Iran, Persian
    ur: 'pk', // Pakistan, Urdu
    th: 'th', // Thailand
    vi: 'vn', // Vietnam
    id: 'id', // Indonesia
    ms: 'my', // Malaysia
  }

  return languageToCountryCode[lang] || lang
}
