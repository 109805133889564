import React, { useState, useEffect } from 'react'
import './style.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import TopBar from '../TopBar/TopBar.js'
import Tabs from '../Tabs/Tabs.js'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import DriveFolders from './DriveFolders/DriveFolders.js'

const theme = createTheme({
  palette: {
    secondary: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
    },
    primary: {
      main: '#00ABEB',
      light: '#3ccdff',
      dark: '#007aa2',
    },
  },
  typography: {
    body1: {
      fontFamily: 'Ubuntu sans-serif',
      fontWeight: 400,
      fontSize: 16,
    },
  },
})

const Drive = observer(
  (
    {
      // from parent
    }
  ) => {
    const { orgsWorkspacesStore, filesStore } = useStore()

    const { org } = orgsWorkspacesStore
    const { reset } = filesStore

    const [isCleanupDialogOpen, setIsCleanupDialogOpen] = useState(false)
    const [view, setView] = useState('')
    const [filterOpen, setFilterOpen] = useState(false)
    const [selectedFolders, setSelectedFolders] = useState([])

    useEffect(() => {
      setView(org?.name === 'israaid' ? 'Curated' : 'All')
    }, [org])

    const ocleanupDialog = (state) => {
      setIsCleanupDialogOpen(state)
    }
    const changeView = (tab) => {
      setView(tab)
    }
    const openFilters = () => {
      setFilterOpen(!filterOpen)
    }

    return (
      <MuiThemeProvider theme={theme}>
        <div className="drive">
          <TopBar
            ocleanupDialog={ocleanupDialog}
            openFilters={openFilters}
            reset={reset}
          />
          <Tabs changeView={changeView} />
          <div className={'content-wrapper'}>
            {/* <LeftBar filterOpen={filterOpen} closeFilter={openFilters}/> */}
            <div className="displayWindow">
              <DriveFolders
                ocleanupDialog={ocleanupDialog}
                selectedFolders={selectedFolders}
                setSelectedFolders={setSelectedFolders}
              />
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
)

export default Drive
