import { USER_TOKEN } from '../../util/consts'
import storage from '../../services/storage'
import { fireAuth } from './firebase'
export const getToken = async () => {
  //TODO: Handle token, need to thinks if we're going to verify by firebase token
  const _token = await fireAuth.currentUser.getIdToken(false)

  //update storage if needed
  const { username, userId, refreshToken, token } = storage.get(USER_TOKEN, {})
  if (_token != token) {
    console.log('replacing token on Storage')
    storage.set(USER_TOKEN, {
      token: _token,
      username,
      userId,
      refreshToken,
    })
  }
  return _token
}

export const getUserId = () => {
  //TODO: Handle token, need to thinks if we're going to verify by firebase token
  const { userId } = storage.get(USER_TOKEN, {})
  return userId
}

export const getInitToken = () => {
  //TODO: Handle token, need to thinks if we're going to verify by firebase token
  const { token } = storage.get(USER_TOKEN, {})
  //console.log(token)
  return token
}

export const getHeaderS3 = (headers) => {
  return {
    'Content-Type': 'multipart/form-data',
    'Content-Transfer-Encoding': 'binary',
    ...headers,
  }
}

export const getHeaderMerged = async (headers) => {
  return {
    'Content-Type': 'application/json',
    ...headers,
    Authorization: 'Bearer ' + (await getToken()),
  }
}
