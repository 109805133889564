import React, { useState, useEffect } from 'react'
import './import-wizard.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'

import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Import from './Import/Import.js'
import Organize from './Organize/Organize.js'
import Refine from './Refine/Refine.js'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { MyTabs, MyTab, MyButton } from '../_MyComponents'
import ActionDialog from '../ActionDialog/ActionDialog.js'

const ImportWizard = observer(
  ({
    // from parent
    isOpen,
    setDialog,
    walkthrough,
    setWalkthrough,
  }) => {
    const { orgsWorkspacesStore, filesStore } = useStore()

    const { getOrgLimits } = orgsWorkspacesStore

    const {
      closeUpload,
      getAllTags,
      getAllPersons,
      updateFilters,
      getAllFilesUsers,
      getAllFilesExtensions,
      driveFoldersNames,
      createDriveCategories,
      SyncChosenFiles,
    } = filesStore

    const [menuState, setMenuState] = useState(0)
    const [showCloseDialog, setShowCloseDialog] = useState(false)

    const handleClose = async () => {
      closeUpload()
      setDialog(false)

      //onReset()
      updateFilters()
      getAllTags({})
      getAllFilesUsers({})
      getAllFilesExtensions({})
      getAllPersons({})
      getOrgLimits()
    }

    const nextStep = async () => {
      switch (menuState) {
        case 0:
          setMenuState(menuState + 1)
          break
        case 1:
          await createDriveCategories()
          SyncChosenFiles()
          handleClose()
          break
        default:
          break
      }
    }

    useEffect(() => {
      if (driveFoldersNames) {
        setMenuState(1)
      }
    }, [driveFoldersNames])

    return (
      <Dialog
        onClose={handleClose}
        fullScreen
        //maxWidth={"lg"}
        aria-labelledby="simple-dialog-title"
        open={!!isOpen}
      >
        <div className="wizard-header">
          <MyTabs
            indicatorColor="primary"
            variant="scrollable"
            value={menuState}
          >
            <MyTab label={t`1. Import`} onClick={() => setMenuState(0)} />
            <MyTab label={t`2. Organize`} onClick={() => setMenuState(1)} />
            {/* <MyTab label="2. Refine" onClick={() => setMenuState(2)} /> */}
          </MyTabs>
          <IconButton
            className="close-wizard"
            onClick={() => setShowCloseDialog(true)}
            component="span"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="wizard-container">
          <div className="wizard-content-container">
            {menuState === 0 && (
              <Import setDialog={setDialog} setMenuState={setMenuState} />
            )}
            {menuState === 1 && (
              <Organize
                walkthrough={walkthrough}
                setWalkthrough={setWalkthrough}
              />
            )}
            {menuState === 2 && <Refine />}
          </div>

          <div className="button-container">
            <MyButton
              endIcon={<ArrowForwardIcon />}
              variant="contained"
              color="primary"
              onClick={() => nextStep()}
            >
              <Trans>Continue</Trans>
            </MyButton>
          </div>
        </div>

        <ActionDialog
          actionName={t`Cancel`}
          cancelName={t`Close import`}
          cancel={() => handleClose()}
          action={() => setShowCloseDialog(false)}
          question={t`Close import?`}
          comments={[
            t`Closing the import might casue issues.`,
            t`We recommend not closing it.`,
          ]}
          //color={'primary'}
          isOpen={showCloseDialog}
        />
      </Dialog>
    )
  }
)

export default ImportWizard
