import React, { useState } from 'react'
//import { DateRangePicker } from 'materialui-daterange-picker'

import moment from 'moment'
import { t, Trans } from '@lingui/macro'

import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { MyKeyword } from '../_MyComponents'
import './SearchByUpload.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import DatePicker from 'react-multi-date-picker'

const SearchByUpload = observer(() => {
  const { filesStore } = useStore()

  const {
    users,
    selectedUsers,
    onUserSelected,
    onDateSelected,
    startDate,
    endDate,
    dateRangeType,
  } = filesStore

  const getRangeDisplay = (startDate, endDate) => {
    return `${moment(startDate).format('MM-DD-YYYY')} - ${moment(
      endDate
    ).format('MM-DD-YYYY')}`
  }

  const resetDate = () => {
    onDateSelected({
      dateRangeType: {},
      endDate: null,
      startDate: null,
    })
  }

  const changeDate = (dateType) => {
    if (dateRangeType.key === dateType.key) {
      resetDate()
    } else {
      onDateSelected({
        dateRangeType: dateType,
      })
    }
  }

  const SearchByDate = () => {
    return (
      <div className="filter-section">
        <h2 className="h2">
          <Trans>Upload date</Trans>
        </h2>
        {/* <div className={"title-2"}>DATE</div> */}
        <div className="filter-tags">
          <MyKeyword
            onClick={() => {
              changeDate({ name: t`Past week`, key: 'week' })
            }}
            selected={dateRangeType.key === 'week'}
            text={<Trans>Past week</Trans>}
          />

          <MyKeyword
            onClick={() => {
              changeDate({ name: t`Past month`, key: 'month' })
            }}
            selected={dateRangeType.key === 'month'}
            text={<Trans>Past month</Trans>}
          />
          <MyKeyword
            onClick={() => {
              changeDate({ name: t`Past year`, key: 'year' })
            }}
            selected={dateRangeType.key === 'year'}
            text={<Trans>Past year</Trans>}
          />
          {/* popup UI issue, resolve later and uncomment */}
          <DatePicker
            maxDate={new Date()}
            onChange={(range) => {
              if (range.length === 2) {
                onDateSelected({
                  startDate: new Date(
                    range[0].year,
                    range[0].month.index,
                    range[0].day
                  ),
                  endDate: new Date(
                    range[1].year,
                    range[1].month.index,
                    range[1].day
                  ),
                  dateRangeType: { name: `Pick date`, key: 'custom' },
                })
              }
            }}
            range
            rangeHover
            render={(value, openCalendar) => {
              return (
                <MyKeyword
                  onClick={openCalendar}
                  selected={dateRangeType.key === 'custom'}
                  text={
                    startDate && dateRangeType.key === 'custom'
                      ? getRangeDisplay(startDate, endDate)
                      : t`Pick date`
                  }
                />
              )
            }}
          />

          {dateRangeType.key === 'custom' && (
            <RotateLeftIcon onClick={resetDate} />
          )}
        </div>
      </div>
    )
  }

  const SearchByUsers = () => {
    return (
      <div className="filter-section">
        {!!users?.length && (
          <h2 className="h2">
            <Trans>Uploaded by</Trans>
          </h2>
        )}
        {/* {!!users?.length && <div className={"title-2"}>PEOPLE</div>} */}
        <div className="filter-tags">
          {users
            .slice()
            .sort((a, b) => (a.count > b.count ? -1 : 1))
            .slice(0, 5)
            .map((user, idx) => {
              const { username, name } = user
              const selected = selectedUsers.find(
                (userId) => userId.username === username
              )
              const displayName = name ? name : username
              return (
                <MyKeyword
                  onClick={() => {
                    onUserSelected(
                      selected
                        ? selectedUsers.filter((userId) => {
                            return userId.username !== username
                          })
                        : selectedUsers.concat(user)
                    )
                  }}
                  selected={selected}
                  text={displayName}
                  key={user._id}
                />
              )
            })}
        </div>
      </div>
    )
  }

  return (
    <div className="filter-content-wrapper">
      <SearchByDate />
      <SearchByUsers />
    </div>
  )
})

export default SearchByUpload
