export default process.env.REACT_APP_NODE_ENV === 'production' ||
process.env.REACT_APP_NODE_ENV === 'staging'
  ? {
      apiKey: 'AIzaSyBDJ--VgIxLcxOvXfd9NYiskyqo53K0WOo',
      authDomain: 'tagbox-io.firebaseapp.com',
      databaseURL: 'https://tagbox-io.firebaseio.com',
      projectId: 'tagbox-io',
      storageBucket: 'tagbox-io.appspot.com',
      messagingSenderId: '684262614313',
      appId: '1:684262614313:web:bd0a91e96ab83b15134338',
      measurementId: 'G-N692WEFF8D',
    }
  : {
      apiKey: 'AIzaSyDRdKTjr6hPvkds68FyBnMqi50k_hQhteM',
      authDomain: 'tagbox-development.firebaseapp.com',
      projectId: 'tagbox-development',
      storageBucket: 'tagbox-development.appspot.com',
      messagingSenderId: '720866407063',
      appId: '1:720866407063:web:715968963c172b44c7efd2',
      measurementId: 'G-S7KBBWRZ5X',
    }
