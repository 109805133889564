import React, { useState, useEffect, useStyle } from 'react'
import { t, Trans } from '@lingui/macro'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { MyButton } from '../_MyComponents'
import Switch from '@material-ui/core/Switch'
import Snackbar from '@material-ui/core/Snackbar'

//import "./cleanup-wizard.scss";

const ShareDialog = ({
  open,
  setOpen,
  currentWorkspaceType,
  updateWorkspace,
}) => {
  const [urlSnackbarOpen, setUrlSnackbarOpen] = useState(false)
  const [shareToggle, setShareToggle] = useState(null)

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggleShare = (e) => {
    setShareToggle(!shareToggle)
    updateWorkspace({ workspaceType: shareToggle ? 'private' : 'public' })
  }

  const copyUrl = () => {
    setUrlSnackbarOpen(true)
    navigator.clipboard.writeText(window.location.href)
  }

  useEffect(() => {
    setShareToggle(currentWorkspaceType === 'private' ? false : true)
  }, [currentWorkspaceType])

  const closeUrlSnackbar = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setUrlSnackbarOpen(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'xs'}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="form-dialog-title">
        <Trans>Share workspace</Trans>
      </DialogTitle>
      <DialogContent>
        <div className={'title-2'}>
          <Trans>Share to web</Trans>
        </div>
        <div className={'title-4'}>
          <Trans>
            Anyone with the link will be able to view this workspace
          </Trans>
        </div>
        <Switch
          className={'share-toggle'}
          checked={shareToggle}
          color="primary"
          onChange={handleToggleShare}
        />
        <div className={'copy-url ' + (!shareToggle ? 'disabled' : '')}>
          <MyButton
            className={'url'}
            disableRipple
            onClick={copyUrl}
            disabled={!shareToggle}
          >
            {window.location.href}
          </MyButton>
          <MyButton
            onClick={copyUrl}
            disabled={!shareToggle}
            variant="contained"
            color="secondary"
          >
            <Trans>Copy</Trans>
          </MyButton>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={urlSnackbarOpen}
            autoHideDuration={4000}
            onClose={closeUrlSnackbar}
            message={<Trans>Link copied!</Trans>}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <MyButton onClick={handleClose} color="primary" variant="contained">
          <Trans>Done</Trans>
        </MyButton>
      </DialogActions>
    </Dialog>
  )
}

export default ShareDialog
