import React from 'react'
import './style.scss'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const theme = createTheme({
  palette: {
    secondary: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
    },
    primary: {
      main: '#00ABEB',
      light: '#3ccdff',
      dark: '#007aa2',
    },
  },
  typography: {
    body1: {
      fontFamily: 'Ubuntu sans-serif',
      fontWeight: 400,
      fontSize: 16,
    },
  },
})

const FilePreview = (props) => {
  const { previewUrl, closeDialog } = props
  const encodeURL = encodeURIComponent(previewUrl)

  const url = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURL}`

  const handleClose = () => {
    closeDialog()
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={true}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <IconButton
          className="close-preview-dialog"
          onClick={handleClose}
          aria-label="delete"
        >
          <CloseIcon />
        </IconButton>
        <div className="file-preview">
          <iframe
            className="iframe"
            src={url}
            frameBorder="0"
            title="file preview"
          ></iframe>
        </div>
      </Dialog>
    </MuiThemeProvider>
  )
}

export default FilePreview
