import { makeAutoObservable, runInAction, reaction, configure } from 'mobx'
import { t, Trans } from '@lingui/macro'
import { subscribe } from '../../services/pubsub'
import Actions from '../../util/actions'
import moment from 'moment'
import { TOKEN_KEYWORD, USER_TOKEN } from '../../util/consts'
import storage from '../../services/storage'
import { debounce, isEmpty } from 'lodash'
import history from '../../services/history'
import axios from 'axios'
import { getHeaderS3 } from '../../services/apis/util'
import * as CryptoJS from 'crypto-js'
import * as Sentry from '@sentry/react'
import { v4 } from 'uuid'
//import { saveAs } from 'file-saver'
import { union, uniqBy } from 'lodash'
import { update } from '@intercom/messenger-js-sdk'

const SCOPE =
  'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.activity.readonly'

configure({ enforceActions: 'observed', isolateGlobalState: true })

const DATE_RANGES = {
  week: {
    unit: 'days',
    amount: 7,
  },
  month: {
    unit: 'months',
    amount: 1,
  },
  year: {
    unit: 'years',
    amount: 1,
  },
  custom: {
    unit: null,
    amount: null,
  },
  none: {
    unit: null,
    amount: null,
  },
}

class FilesStore {
  widthPercents = 0.8
  fileContainerWidth = 235

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)

    this.tagsValueReaction = reaction(
      () => this.selectedTags,
      (selectedTags) => {
        this.tagsValue = selectedTags.map(({ _id }) => _id)
      }
    )

    this.keywordsValueReaction = reaction(
      () => this.selectedKeywords,
      (selectedKeywords) => {
        this.keywordsValue = selectedKeywords.map(({ _id }) => _id)
      }
    )

    this.personsValueReaction = reaction(
      () => this.selectedPersons,
      (selectedPersons) => {
        this.personsValue = selectedPersons.map(({ _id }) => _id)
      }
    )

    this.collectionsValueReaction = reaction(
      () => this.selectedCollections,
      (selectedCollections) => {
        this.collectionsValue = selectedCollections.map(({ _id }) => _id)
      }
    )

    this.debouncePreSearch = debounce(async (text, type, context) => {
      const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
      if (text) {
        this.rootStore.apis
          .preSearch({
            orgId,
            workspaces: workspaceId,
            text: text,
            collections: this.collectionsValue,
            context: context
              ? context
              : this.currentCollection
              ? 'COLLECTION'
              : 'WORKSPACE',
          })
          .then((res) => {
            runInAction(() => {
              if (type === 'OMNI') {
                this.preSearchResults = res.data
              } else if (type === 'TAGLIST') {
                this.preSearchTagResults = res.data
              }
            })
          })
      }
    }, 350)

    subscribe(Actions.GET_FILES, this.getFilesFiltered)
    subscribe(Actions.GET_TAGS, this.getTagsFiltered)
    subscribe(Actions.GET_ALL_TAGS, this.getAllTags)
    subscribe(Actions.GET_ALL_TAGS, this.getAllFilesUsers)
    subscribe(Actions.GET_FILES_USERS, this.getUsersFiltered)
    subscribe(Actions.GET_ALL_TAGS, this.getAllFilesExtensions)
    subscribe(Actions.GET_POPULAR_TAGS, this.getPopularTags)
    subscribe(Actions.GET_ALL_TAGS, this.getAllCollections)
    subscribe(Actions.GET_ALL_TAGS, this.getAllPersons)
    subscribe(Actions.WINDOW_RESIZED, this.resized)
  }

  FILES_IN_PAGE = 100
  PERSONS_IN_PAGE = 90
  COLLECTIONS_IN_PAGE = 90
  SEARCH_IMAGE_TRESHOLD = 80
  SIMILAR_IMAGE_TRESHOLD = 120
  mainView = 'home' // home, collections, tags
  settingsDialogView = null // null (close), workspace, account, analytics
  fileViewMode = 'grid' // grid or table
  currentFilesPage = 0 // table page, used for selectAll
  tags = []
  files = []
  filesCount = 0
  allTags = []
  collectionTags = []
  categories = []
  allCategories = null
  collections = []
  collectionsCount = 0
  selectedTags = []
  users = []
  exts = []
  persons = []
  allPersons = []
  duplicatePersons = []
  people = {
    gender: { male: false, female: false, both: false },
    number: -1,
    emotion: { positive: false, neutral: false, negative: false },
    pose: {
      straight: false,
      sideways: false,
      up: false,
      down: false,
      tilted: false,
    },
    age: {
      infants: false,
      children: false,
      teenagers: false,
      twenties: false,
      thirties: false,
      forties: false,
      fifties: false,
      sixties: false,
      older: false,
    },
  }
  selectedUsers = []
  selectedKeywords = []
  selectedPersons = []
  selectedCollections = []
  popularUsers = []
  popularTags = []
  maxFilesInRow = 6
  tagsValue = []
  keywordsValue = []
  personsValue = []
  collectionsValue = []
  selectedExt = []
  selectedExtByType = []
  selectedFileType = { name: '' }
  selectedDuration = {}
  selectedFileSize = {}
  selectedOrientationMode = { name: null }
  selectedColors = []
  selectedResolution = {}

  selectedAdvanceColorFilter = { name: null }
  imageToSearch = null
  driveFolders = []
  driveFoldersNames = null
  driveFilesCount = 0
  driveFolderIds = []
  driveCategories = []
  drives = {}
  driveAuthResponse = {}
  driveRefreshToken = ''
  driveAccessToken = ''
  dateRangeType = {}
  syncFiles = false
  syncJob = null
  gettingSuggestions = false
  gettingTags = false
  querySearch = []
  currentFile = {}
  currentCollection = null
  isLoading = true // used to show spinner on file fetch
  isBulkDownloading = false // used to show spinner on bulk download
  selectedList = [] // selected files list
  simlarFiles = [] // similar files list

  //upload snackbar
  isUpload = false
  isUploadFinished = false
  isAnalsisFinished = false
  isCheckingUploadStatus = false

  isFacesDetectRunning = false
  resetThisUpload = false
  filesInQ = []
  uploadQIndex = 0
  analysisQIndex = 0
  proccessedQIndex = 0
  recentlyUploadedFilesIds = []
  toProccessFileIds = []
  recentlyUploadedFiles = []

  foundFilesSnackbar = false
  tagIsSuggest = false
  tagSuggestion = null
  tagSuggestIsExist = false
  tagSuggestionCount = 0
  lastTag = null

  textToSearch = null
  isFavorite = false
  unTagged = false
  textToPreSearch = ''
  preSearchResults = {}
  preSearchTagResults = {}

  transcription = null
  language = 'en-US'

  setLanguage = (lang) => {
    runInAction(() => {
      this.language = lang
    })
  }

  readChunked = (file, chunkCallback, endCallback) => {
    var fileSize = file.size
    var chunkSize = 4 * 1024 * 1024 // 4MB
    var offset = 0

    var reader = new FileReader()
    reader.onload = function () {
      if (reader.error) {
        endCallback(reader.error || {})
        return
      }
      offset += reader.result.length
      // callback for handling read chunk
      // TODO: handle errors
      chunkCallback(reader.result, offset, fileSize)
      if (offset >= fileSize) {
        endCallback(null)
        return
      }
      readNext()
    }

    reader.onerror = function (err) {
      endCallback(err || {})
    }

    function readNext() {
      var fileSlice = file.slice(offset, offset + chunkSize)
      reader.readAsBinaryString(fileSlice)
    }
    readNext()
  }

  /*
  getSHA256(blob, cbProgress) {
    return new Promise((resolve, reject) => {
      var sha256_ = CryptoJS.algo.SHA256.create()
      this.readChunked(
        blob,
        (chunk, offs, total) => {
          sha256_.update(CryptoJS.enc.Latin1.parse(chunk))
          if (cbProgress) {
            cbProgress(offs / total)
          }
        },
        (err) => {
          if (err) {
            reject(err)
          } else {
            // TODO: Handle errors
            var hash = sha256_.finalize()
            var hashHex = hash.toString(CryptoJS.enc.Hex)
            resolve(hashHex)
          }
        }
      )
    })
  }
  */

  getSHA256 = (blob, cbProgress) => {
    return new Promise((resolve, reject) => {
      var sha256_ = CryptoJS.algo.SHA256.create()
      const chunkSize = 16 * 1024 * 1024 // 16MB chunk size
      const reader = new FileReader()

      reader.onload = (e) => {
        sha256_.update(CryptoJS.enc.Latin1.parse(e.target.result))
        if (cbProgress) {
          cbProgress(chunkSize / blob.size)
        }
        var hash = sha256_.finalize()
        var hashHex = hash.toString(CryptoJS.enc.Hex)
        resolve(hashHex)
      }

      reader.onerror = (err) => {
        reject(err)
      }

      // Read only the first 16MB of the file
      const blobSlice = blob.slice(0, chunkSize)
      reader.readAsBinaryString(blobSlice)
    })
  }

  getHashedFile = async (file) => {
    const hashDigest = await this.getSHA256(file, (prog) =>
      console.log('Progress: ' + prog)
    )
    console.log({ hashDigest })

    const item = {
      name: file.name,
      storageType: 's3',
      size: file.size,
      type: file.type,
      hash: hashDigest,
      originalDateCreated: file.lastModified,
    }

    this.updateFileInFileQHash({ uniqId: file.uniqId, hash: hashDigest })

    file.item = item
    return file
  }

  updateFileInFileQ = ({ _id, updates }) => {
    runInAction(() => {
      let index = this.filesInQ.findIndex(
        (qFile) => qFile._id === _id && !qFile.duplicate
      )

      if (index === -1) {
        return
      }

      for (const key in updates) {
        if (
          key === 'status' &&
          updates[key] === 'uploaded' &&
          this.filesInQ[index].status !== 'uploaded'
        ) {
          //this.uploadQIndex = this.uploadQIndex + 1
        } else if (
          key === 'status' &&
          updates[key] === 'complete' &&
          this.filesInQ[index].status !== 'complete'
        ) {
          this.analysisQIndex = this.analysisQIndex + 1
        }

        this.filesInQ[index] = {
          ...this.filesInQ[index],
          [key]: updates[key],
          name: this.filesInQ[index].name,
          size: this.filesInQ[index].size,
        }
        //this.filesInQ[index][key] = updates[key]
      }
    })
  }

  deleteFileFromQ = (_id) => {
    if (this.filesInQ.length === 0) {
      return
    }

    runInAction(() => {
      this.filesInQ = this.filesInQ.filter((qFile) => qFile._id !== _id)
      this.recentlyUploadedFilesIds = this.recentlyUploadedFilesIds.filter(
        (id) => id !== _id
      )
      this.recentlyUploadedFiles = this.recentlyUploadedFiles.filter(
        (file) => file._id !== _id
      )
    })
  }

  updateFileInFileQHash = ({ uniqId, hash }) => {
    runInAction(() => {
      let index = this.filesInQ.findIndex((qFile) => qFile.uniqId === uniqId)
      this.filesInQ[index]['hash'] = hash
    })
  }

  extractFacesFromFiles = async (filesPromises) => {
    //checking lock
    while (this.isFacesDetectRunning) {
      //console.log("Waiting on isFacesDetectRunning !!!")
      //waiting
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      await timer(2000)
    }

    //locking
    runInAction(() => {
      this.isFacesDetectRunning = true
    })

    for await (let filePromise of filesPromises) {
      //start running when 1 is done then 2 ....

      let fileId = filePromise._id
      if (
        this.filesInQ.find((file) => file._id === filePromise._id) &&
        filePromise.analysisProgress === 90 &&
        !filePromise.duplicate &&
        filePromise.status != 'done' &&
        (filePromise.finishedFile?.people?.number > 0 ||
          filePromise.finishedFile?.lastFrameLocation?.length > 0)
      ) {
        //run face extract only if in q and is not done or duplicate
        var rF = await this.rootStore.apis.extractFaces(fileId)
        console.log({ rF })
        if (rF?.status === 'complete') {
          this.updateFileInFileQ({
            _id: fileId,
            updates: { status: 'analaysed' },
          })
          this.updateFileInFileQ({
            _id: fileId,
            updates: { status: 'done', analysisProgress: 100 },
          })
        } else {
          //this takes time, wait and retry
          let i = 0
          let timer = (ms) => new Promise((res) => setTimeout(res, ms))
          while (rF?.status != 'complete' && i < 1 * 30 * 15) {
            //wait 15 min
            await timer(2000)
            rF = await this.rootStore.apis.getFile(fileId)
            console.log({ rF })
            i = i + 1
          }
          this.updateFileInFileQ({
            _id: fileId,
            updates: { status: 'analaysed' },
          })
          this.updateFileInFileQ({
            _id: fileId,
            updates: { status: 'done', analysisProgress: 100 },
          })
        }
      } else {
        console.log({ skipping: filePromise })
        this.updateFileInFileQ({
          _id: fileId,
          updates: { status: 'analaysed' },
        })
        this.updateFileInFileQ({
          _id: fileId,
          updates: { status: 'done', analysisProgress: 100 },
        })
      }

      //check if uploading state is done and moving to analysis state
      if (!this.isUploadFinished) {
        runInAction(() => {
          //only finished if all are 100 done in uploaded or failed
          this.isUploadFinished = this.filesInQ.reduce(
            (accumulator, currentValue) =>
              accumulator &&
              (currentValue.progress >= 100 || currentValue.failed === true),
            true
          )
        })
      }
    }

    //unlocking
    runInAction(() => {
      this.isFacesDetectRunning = false
    })

    console.log('finished extracting faces to all!')

    //check if all Done
    //only finished if all are done
    const allDone = this.filesInQ.reduce(
      (accumulator, currentValue) =>
        accumulator && currentValue.status === 'done',
      true
    )
    //check if all persons have thumbnails (this is async)
    var personsDone = await this.isAllPersonsHaveTumbnails()

    const timer = (ms) => new Promise((res) => setTimeout(res, ms))

    var retry = 0
    while (!personsDone && retry < 3) {
      console.log({ retryPerson: retry })
      await timer(1500)
      personsDone = await this.isAllPersonsHaveTumbnails()
      retry = retry + 1
    }

    runInAction(() => {
      console.log({ allDone })
      if (allDone) {
        this.isUpload = false
        this.getRecentlyUploadedFiles()
      }
    })
  }

  searchFaceFromImage = async (location) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    var result = await this.rootStore.apis.searchFace(
      location,
      orgId,
      workspaceId
    )

    if (result.error) {
      if (result.error === 'No faces found') {
        return {
          error: t`Couldn't identify a face in the photo, please try again.`,
        }
        //no face error
      } else if (result.error === 'only one face allowed') {
        return {
          error: t`We identified multiple people in the photo, please try uploading a photo that only shows you.`,
        }
        //more than 1 face detected
      } else if (result.error === 'face not found in workspace') {
        return {
          error: t`We couldn't find any photos of you in this collection. Try taking another photo, or close this window to see other photos in the collection.`,
        }
        //face not found
      }
    }

    var person = result

    // guy check this:
    await this.onPersonSelected(person)
    return person
  }

  getSimilarImages = async (file) => {
    if (file?.hasThumbnail && file?.thumbnail) {
      var { files } = await this.getFiles_getter({
        limit: 10,
        skip: 0,
        image: file.thumbnail,
        imageTreshold: this.SIMILAR_IMAGE_TRESHOLD,
        collections: this.collectionsValue,
      })

      files = files.filter((f) => f._id.toString() !== file._id.toString())

      runInAction(() => {
        this.simlarFiles = files
      })
    } else {
      runInAction(() => {
        this.simlarFiles = []
      })
    }
  }

  //create thumbnail using canvas and store it in the file object for videos. return thumbnail as blob
  createThumbnail = async (file) => {
    if (file.type.split('/')[0] === 'image') {
      const url = URL.createObjectURL(file)
      const img = new Image()
      img.src = url
      await img.decode()
      // Resize the image to max 300 x 300 and reduce quality to 0.5
      const canvas = document.createElement('canvas')
      const maxDim = 300
      let scale = maxDim / img.width
      scale = Math.min(1, scale) // don't enlarge the image
      canvas.width = img.width * scale
      canvas.height = img.height * scale
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      // Create the thumbnail as a Blob with reduced quality
      return new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            resolve({
              url: URL.createObjectURL(blob),
              width: canvas.width,
              height: canvas.height,
            })
            URL.revokeObjectURL(url)
          },
          'image/jpeg',
          0.7
        ) // Adjust the quality here
      })
    } else if (file.type.split('/')[0] === 'video') {
      return await new Promise((resolve, reject) => {
        const videoURL = URL.createObjectURL(file)
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = () => {
          video.currentTime = 1 // Move to the first second to ensure data is loaded
        }
        video.onseeked = async () => {
          const canvas = document.createElement('canvas')
          const maxDim = 300
          let scale = maxDim / video.videoWidth
          scale = Math.min(1, scale) // don't enlarge the video frame
          canvas.width = video.videoWidth * scale
          canvas.height = video.videoHeight * scale

          const ctx = canvas.getContext('2d')
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

          // Create the thumbnail as a Blob with reduced quality
          canvas.toBlob(
            (blob) => {
              resolve({
                url: URL.createObjectURL(blob),
                width: canvas.width,
                height: canvas.height,
              })
              URL.revokeObjectURL(videoURL) // Revoke video ObjectURL here
            },
            'image/jpeg',
            0.7
          ) // Adjust the quality here
        }
        video.onerror = reject
        video.src = videoURL
      })
    } else {
      return {}
    }
  }

  formatBatch = async (batch) => {
    const promises = batch.map(async (file) => {
      try {
        file.ext = file.name.split('.').pop().toLowerCase()
        let { url, width, height } = await this.createThumbnail(file)
        file.thumbnail = url
        file.hasThumbnail = !!url
        file.thumbnail_metadata = {
          width,
          height,
        }
        file.status = 'pending'
        file.progress = 0
        file.uploadedSize = 0
        file.uniqId = v4()
        return file
      } catch (error) {
        console.error('Error loading image: ', error)
        console.error('Error loading image file: ', file)
        file.ext = file.name.split('.').pop().toLowerCase()
        file.hasThumbnail = false
        file.status = 'pending'
        file.progress = 0
        file.uploadedSize = 0
        file.uniqId = v4()
        return file
      }
    })

    return Promise.all(promises)
  }

  formatSimpleBatch = async (batch) => {
    const promises = batch.map(async (file) => {
      file.ext = file.name.split('.').pop().toLowerCase()
      file.status = 'pending'
      file.progress = 0
      file.uploadedSize = 0
      file.uniqId = v4()
      return file
    })

    return Promise.all(promises)
  }

  formatFiles = async (localFiles) => {
    const batchSize = 1
    const pushToQueueSize = 5
    const maxFormatFiles = 25
    const results = []

    var smallBatch = []
    for (let i = 0; i < localFiles.length; i += batchSize) {
      const batch = localFiles.slice(i, i + batchSize)
      var batchResults = []
      if (i < maxFormatFiles) {
        batchResults = await this.formatBatch(batch)
      } else {
        batchResults = await this.formatSimpleBatch(batch)
      }
      results.push(...batchResults)
      smallBatch.push(...batchResults)

      //adding to Q in small batches so UI can update faster
      if (
        smallBatch.length >= pushToQueueSize ||
        i + batchSize >= localFiles.length
      ) {
        runInAction(() => {
          this.filesInQ = this.filesInQ.concat(smallBatch)
        })
        smallBatch = []
      }
    }

    return results
  }

  uploadToS3 = async (files) => {
    // set file view mode to grid on uploader
    this.setFileViewMode('grid')

    runInAction(() => {
      this.isUpload = true
      this.isAnalsisFinished = false
      this.isUploadFinished = false
    })

    const {
      orgId,
      workspaceId,
      username: userId,
    } = this.rootStore.orgsWorkspacesStore

    // remove system files
    // .DS_Store && desktop.ini
    files = files.filter(
      (file) => file.name !== '.DS_Store' && file.name !== 'desktop.ini'
    )

    //TODO: add upload activity to workspace.
    if (this.syncJob === null) {
      var syncJob = await this.rootStore.apis.createSyncJob({
        orgId,
        workspaceId,
        type: 'upload',
        driveType: 'direct-upload',
        status: 'uploading',
        totalFiles: files.length,
      })

      //console.log({ syncJob })

      runInAction(() => {
        this.syncJob = syncJob
      })
    } else {
      let updatedSyncJob = await this.rootStore.apis.updateSyncJob({
        syncjobId: this.syncJob._id,
        status: 'uploading',
        totalFiles: files.length + this.syncJob.totalFiles,
      })

      //console.log({ updatedSyncJob })

      runInAction(() => {
        this.syncJob = updatedSyncJob
      })
    }

    files = await this.formatFiles(files)
    console.log('finished formatting files')
    files = files.sort((a, b) => a.size - b.size) //sort by size to speed up faces

    const filesArr = Array.from(files)

    this.checkUploadedStatus()

    var hashedFiles = []
    for (let file of filesArr) {
      const hash_promise = this.getHashedFile(file)
      hashedFiles.push(hash_promise)
    }

    for (let i = 0; i < hashedFiles.length; i = i + 15) {
      let localhashedFiles = hashedFiles.slice(i, i + 15)
      let filesPromises = []

      //hash files to check with BE
      for await (let file of localhashedFiles) {
        //start running when 1 is done then 2 ....

        //check if hash is in LocalFiles
        var localFile = this.filesInQ
          .filter((f) => file.uniqId !== f.uniqId)
          .find(
            (f) =>
              f?.hash === file?.hash &&
              f?.uniqId !== file?.uniqId &&
              f?.size === file?.size
          )

        if (localFile) {
          var fileIndex = this.filesInQ.findIndex(
            (f) => f.uniqId === file.uniqId
          )
          var localFileIndex = this.filesInQ.findIndex(
            (f) => f.uniqId === localFile.uniqId
          )

          //check if local file is already in Q
          if (localFileIndex < fileIndex) {
            //duplicate file, in Q so no need to upload again
            console.log('localFile already exists')
            //this.recentlyUploadedFilesIds.push(responseFile._id)
            runInAction(() => {
              //update that this file is duplicate and in the Q
              let index = this.filesInQ.findIndex(
                (qFile) => qFile.uniqId === file.uniqId
              )
              //this.filesInQ[index]._id = responseFile._id
              /*
              this.filesInQ[index].uploadedSize = file.size
              this.filesInQ[index].progress = 100
              this.filesInQ[index].duplicate = true
              this.filesInQ[index].analysisProgress = 100
              this.filesInQ[index].status = 'duplicate'
              */

              var oldFile = this.filesInQ[index]

              this.filesInQ[index] = {
                ...this.filesInQ[index],
                uploadedSize: file.size,
                progress: 100,
                duplicate: true,
                analysisProgress: 100,
                status: 'duplicate',
                name: oldFile.name,
                size: oldFile.size,
              }

              this.uploadQIndex = this.uploadQIndex + 1
              this.analysisQIndex = this.analysisQIndex + 1
            })

            // update sync job we have 1 duplicate (increment counters)
            await this.rootStore.apis.updateSyncJobIncrement({
              syncjobId: this.syncJob._id,
            })
            continue
          }
        }

        var responseFile
        try {
          responseFile = await this.rootStore.apis.initUpload({
            file: file.item,
            userId,
            orgId,
            workspaceId,
            syncJobId: this.syncJob._id,
          })
          console.log({ responseFile })
        } catch (err) {
          responseFile = { failed: true }
        }

        if (responseFile.duplicate) {
          //duplicate file, no need to upload again
          this.recentlyUploadedFilesIds.push(responseFile._id)
          runInAction(() => {
            //update that this file is duplicate and already been uploaded
            let index = this.filesInQ.findIndex(
              (qFile) => qFile.uniqId === file.uniqId
            )

            var newFile = { ...file }
            newFile.duplicate = true
            newFile.status = 'duplicate'
            newFile.uploadedSize = file.size
            newFile.progress = 100
            newFile.analysisProgress = 100
            newFile._id = responseFile._id
            newFile.tags = responseFile.tags
            newFile.fileId = responseFile.fileId
            newFile.name = file.name
            newFile.size = file.size
            this.filesInQ[index] = newFile

            /*
            this.filesInQ[index]._id = responseFile._id
            this.filesInQ[index].uploadedSize = file.size
            this.filesInQ[index].progress = 100
            this.filesInQ[index].duplicate = true
            this.filesInQ[index].analysisProgress = 100
            this.filesInQ[index].status = 'duplicate'
            */

            this.uploadQIndex = this.uploadQIndex + 1
            this.analysisQIndex = this.analysisQIndex + 1
          })
        } else if (responseFile.failed) {
          runInAction(() => {
            let index = this.filesInQ.findIndex(
              (qFile) => qFile.uniqId === file.uniqId
            )
            this.filesInQ[index].failed = true
            this.filesInQ[index].status = 'failed'

            this.uploadQIndex = this.uploadQIndex + 1
            this.analysisQIndex = this.analysisQIndex + 1
          })
        } else {
          runInAction(() => {
            //update that this file _id
            let index = this.filesInQ.findIndex(
              (qFile) => qFile.uniqId === file.uniqId
            )

            //I want to keep all fields from file and only add other fields from responseFile and not override
            var newFile = file
            newFile._id = responseFile._id
            newFile.tags = responseFile.tags
            newFile.fileId = responseFile.fileId

            console.log({ newFile })
            newFile.status = 'uploading'
            this.filesInQ[index] = newFile

            this.recentlyUploadedFilesIds.push(responseFile._id)

            /*
            this.filesInQ[index]._id = responseFile.file_id
            this.filesInQ[index].status = 'uploading'
            */
          })

          const local_promise = this.uploadAndAnalyzeFile({
            res: responseFile,
            file,
          })
          filesPromises.push(local_promise)
        }
      }

      //wait for all files to be uploaded
      await Promise.all(filesPromises)
      //this.extractFacesFromFiles(filesPromises)
    }

    //all files are uploaded
    if (!this.isUploadFinished) {
      runInAction(() => {
        //only finished if all are 100 done in uploaded or failed
        this.isUploadFinished = this.filesInQ.reduce(
          (accumulator, currentValue) =>
            accumulator &&
            (currentValue.progress >= 100 || currentValue.failed === true),
          true
        )
      })
    }
  }

  uploadAndAnalyzeFile = async ({ res, file }) => {
    const transaction = Sentry.startTransaction({ name: 'upload-file' })
    const span = transaction.startChild({ op: 'upload-file' }) // This function returns a Span

    //let { success, finishedFile } = await this.awsUpload({ res, file })
    await this.awsUpload({ res, file })
    //if (success) {
    //this.recentlyUploadedFilesIds.push(res.file_id)
    //this.toProccessFileIds.push(res.file_id)
    //}
    span.finish() // Remember that only finished spans will be sent with the transaction
    transaction.finish()

    //file.finishedFile = finishedFile
    return file
  }

  checkUploadedStatus = async () => {
    if (!this.isCheckingUploadStatus) {
      runInAction(() => {
        this.isCheckingUploadStatus = true
      })

      try {
        //check if all analysis is done
        while (this.syncJob.totalFiles > this.syncJob.numberOfFilesAnalysed) {
          //wait for 5 seconds
          await new Promise((res) => setTimeout(res, 5000))

          const updatedSyncJob = await this.rootStore.apis.SyncEnded(
            this.syncJob._id
          )
          await this.getRecentlyUploadedFiles()

          //console.log({ updatedSyncJob })
          runInAction(() => {
            this.syncJob = updatedSyncJob
          })
        }
      } catch (err) {
        console.log(err)
      }

      //all files are analysed and uploaded
      runInAction(() => {
        this.isUpload = false
        this.isAnalsisFinished = true
        //this.syncJob = null
      })

      //refresh files
      //this.updateFilesAndTags()
    }

    runInAction(() => {
      this.isCheckingUploadStatus = false
    })
  }

  closeSyncJob = async () => {
    runInAction(() => {
      this.syncJob = null
    })
  }

  //separated the uploading indication for the popup
  closeUpload = async () => {
    //check if all files are done uploading, status === 'pending'
    const unDoneFiles = this.filesInQ.filter(
      (file) => file.status === 'pending'
    )
    if (unDoneFiles.length > 0) {
      //some files are still in progress

      //do enable page reload

      runInAction(() => {
        this.isUpload = false
      })

      //delete all not done files
      await Promise.all(
        unDoneFiles.map(async (file) => {
          if (file._id) {
            await this.deleteFile(file._id)
          }
        })
      )
    }
    runInAction(() => {
      //if all files are done close sync job or no files in Q anymore (deleted all)
      if (
        this.syncJob &&
        (this.syncJob.totalFiles <= this.syncJob.numberOfFilesAnalysed ||
          this.filesInQ.length === 0)
      ) {
        this.syncJob = null
      }

      this.isFacesDetectRunning = false
      this.isUpload = false
      this.filesInQ = []
      this.uploadQIndex = 0
      this.analysisQIndex = 0
      this.proccessedQIndex = 0
      this.toProccessFileIds = []
      this.isUploadFinished = false
      this.isAnalsisFinished = false
      this.recentlyUploadedFilesIds = []
      this.recentlyUploadedFiles = []
    })

    if (unDoneFiles.length > 0) {
      //this will kill all running processes
      window.location.reload()
    }
  }

  cleanUploadQueue = async () => {
    runInAction(() => {
      this.filesInQ = []
    })
  }

  awsUpload = async ({ res, file, index, retry = 0 }) => {
    const param = res.fields
    const form = new FormData()
    Object.entries(param).forEach(([key, value]) => {
      form.append(key, value)
    })
    form.append('file', file)
    const url = res.url

    this.updateFileInFileQ({ _id: file._id, updates: { status: 'uploading' } })

    try {
      const { data } = await axios({
        url,
        method: 'POST',
        headers: getHeaderS3(),
        progress: false,
        data: form,
        onUploadProgress: (progressEvent) => {
          runInAction(() => {
            this.updateFileInFileQ({
              _id: file._id,
              updates: {
                uploadedSize: progressEvent.loaded,
                progress: (100 * progressEvent.loaded) / file.size,
              },
            })
          })
          console.log(progressEvent.loaded)
        },
      })

      this.updateFileInFileQ({
        _id: file._id,
        updates: { status: 'uploaded', progress: 100 },
      })
      runInAction(() => {
        this.uploadQIndex = this.uploadQIndex + 1
      })

      this.updateFileInFileQ({
        _id: file._id,
        updates: { status: 'analaysing' },
      })
      console.log({ res })
      /*
      var finishedFile = await this.finishFileUpload(res.file_id)
      this.updateFileInFileQ({
        _id: file._id,
        updates: { status: 'analaysing', analysisProgress: 90 },
      })
      */

      return { data, success: true } // , finishedFile }
    } catch (err) {
      runInAction(() => {
        this.snackbarOpen = false
      })
      //console.log(err)

      const timer = (ms) => new Promise((res) => setTimeout(res, ms))

      if (retry < 5) {
        //console.log("!!!!!!!!!!!!!!")
        //console.log({file})
        //console.log("Failed: " + file.path);
        //console.log("retry number " + retry )
        await timer(3000 * (retry + 1))
        return this.awsUpload({ res, file, index, retry: retry + 1 })
      } else {
        //console.log("!!!!!!!!!!!!!!")
        //console.log("Failed: " + file.path);
        this.deleteFile(res.file_id)
        this.updateFileInFileQ({
          _id: file._id,
          updates: { status: 'done', failed: true },
        })
        return { success: false }
      }

      //setUploadError('Upload failed');
      //throw err
    }
  }

  simpleUploadFileToS3 = async (file) => {
    const { orgId } = this.rootStore.orgsWorkspacesStore

    runInAction(() => {
      this.filesInQ = [file]
      this.isUpload = true
      this.isUploadFinished = false
    })

    const item = {
      name: file.name,
      storageType: 's3',
      size: file.size,
      type: file.type,
    }

    const res = await this.rootStore.apis.simpleUploadFileToS3({
      file: item,
      orgId,
    })

    const param = res.fields
    const url = res.url
    const form = new FormData()
    Object.entries(param).forEach(([key, value]) => {
      form.append(key, value)
    })
    form.append('file', file)

    const { data } = await axios({
      url,
      method: 'POST',
      headers: getHeaderS3(),
      data: form,
    })

    runInAction(() => {
      this.isUpload = false
      this.isUploadFinished = true
    })

    const s3location = res.url + '/' + res.fields.key

    return { success: true, s3location }
  }

  getFilesByIds = async (ids) => {
    const res = await this.rootStore.apis.getFilesByIds({ ids })
    return res
  }

  getRecentlyUploadedFiles = async () => {
    if (this.recentlyUploadedFilesIds?.length) {
      const files = await this.getFilesByIds(this.recentlyUploadedFilesIds)
      runInAction(() => {
        this.recentlyUploadedFiles = files
        //update tags
        files.map((file) => {
          if (file.status !== 'pending') {
            this.updateFileInFileQ({
              _id: file._id,
              updates: { tags: file.tags, status: file.status },
            })
          }

          if (file.hasThumbnail) {
            let index = this.filesInQ.findIndex(
              (qFile) => qFile._id === file._id && !qFile.duplicate
            )

            if (index === -1) {
              return
            }

            if (!this.filesInQ[index].hasThumbnail) {
              console.log('updating thumbnail')
              var oldFile = this.filesInQ[index]

              this.filesInQ[index] = {
                ...this.filesInQ[index],
                thumbnail: file.thumbnail,
                thumbnail_metadata: file.thumbnail_metadata,
                hasThumbnail: true,
                name: oldFile.name,
                size: oldFile.size,
              }
            }
          }
        })
      })
    }
  }

  getThumbnail = (fileId) => {
    let x = 0
    const intervalID = setInterval(() => {
      this.getFilesFiltered()
      if (++x === 3) {
        window.clearInterval(intervalID)
      }
    }, 1500)
  }

  updateThumbnail = async (thumbnail, fileId, width, height) => {
    await this.rootStore.apis.updateThumbnail(thumbnail, fileId, width, height)
    await this.getFilesFiltered()
    return
  }

  getPopularTags = async () => {
    console.log('getPopularTags')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    this.rootStore.apis
      .getPopularsTags({ orgId, workspaceId, limit: 10 })
      .then(({ populars }) => {
        runInAction(() => {
          this.popularTags = populars
        })
      })
  }

  /*
  async getPopularUsers() {
    const {
      orgId, workspaceId
    } = this.rootStore.orgsWorkspacesStore;
    Promise.all([
      this.rootStore.apis.getPopulars({ orgId, workspaceId, type: "user" }),
      
      this.rootStore.apis.getFilesUsers({ orgId, workspaceId }),
    ]).then(([{ populars }, { users }]) => {
      runInAction(() => {
        this.popularUsers = populars.map(({ expression }) =>
          users.find(({ email }) => email === expression)
        );
      });
    });
  }
  */

  updateFilesAndTags = async () => {
    //this.getTagsFiltered();
    this.getAllTags({})
    this.getFilesFiltered()
    //this.getPopularTags();
    this.getAllCollections({})
    this.recentlyUploadedFilesIds?.length && this.getRecentlyUploadedFiles()
  }

  updateFilters = async (values) => {
    console.log('updateFilters')

    var options = { year: 'numeric', month: 'long', day: 'numeric' }

    const fullDate =
      this.dateRangeType.key === 'custom'
        ? this.startDate.toLocaleDateString('en-US', options) +
          ' - ' +
          this.endDate.toLocaleDateString('en-US', options)
        : this.dateRangeType.name

    runInAction(() => {
      this.querySearch = {
        queryTags: this.selectedTags.map((tag) => tag.name),
        queryKeywords: this.selectedKeywords,
        queryPersons: this.selectedPersons,
        queryUsers: this.selectedUsers.map((user) =>
          user.name ? user.name : user.username
        ),
        queryText: this.textToSearch ? [this.textToSearch] : [],
        queryImage: this.imageToSearch ? [t`Image search`] : [],
        queryIsFavorite: this.isFavorite ? [t`Favorites`] : [],
        queryIsUnTagged: this.unTagged ? [t`Untagged files`] : [],
        queryExts: this.selectedExt,
        queryDates: fullDate ? [fullDate] : [],
        queryPeople: this.getPeopleFilterQuery(),
        queryType:
          this.selectedFileType.name != '' ? [this.selectedFileType] : [],
        queryDuration: this.selectedDuration.name
          ? [this.selectedDuration]
          : [],
        queryFileSize: this.selectedFileSize.name
          ? [this.selectedFileSize]
          : [],
        queryOrientationMode: this.selectedOrientationMode.name
          ? [this.selectedOrientationMode]
          : [],
        queryColor: this.selectedColors,
        queryResolution: this.selectedResolution.key
          ? [this.selectedResolution]
          : [],
        queryAdvanceColorFilter: this.selectedAdvanceColorFilter.name
          ? [this.selectedAdvanceColorFilter]
          : [],
      }
    })

    /*
    const files = await this.getFilesFiltered_getter(values);
    const tagsData = await this.getTagsFiltered_getter(values);
    const usersData = await this.getUsersFiltered_getter(values)
    */

    runInAction(() => {
      if (this.isCollectionMode() && !this.isSearchMode()) {
        this.isLoading = true
      }
    })

    //const [filesData, tagsData, usersData, extensionsData] = await Promise.all([
    const [filesData, tagsData, usersData] = await Promise.all([
      this.getFilesFiltered_getter(values),
      this.getTagsFiltered_getter(values),
      this.getUsersFiltered_getter(values),
      //this.getFilesExtensionsFiltered_getter(values),
    ])

    console.log('!!!!!!!!!!!!GETTER!!!!!!!!!!')
    //console.log({files, tagsData, usersData, extensionsData});

    var localAllTags = this.allTags
    if (this.isCollectionMode() && !this.isSearchMode()) {
      localAllTags = tagsData.tags
      //set new all person to just collection files
      this.getAllPersons({})
    } else if (this.isCollectionMode() && !!this.isSearchMode()) {
      localAllTags = this.collectionTags
    }

    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    var allCategories = await this.rootStore.apis.getCategories({
      orgId,
      workspaceId,
    })

    const { categoriesArrays, otherCategory } = this.mapCategories({
      allCategories,
      allTags: localAllTags,
      tags: tagsData.tags,
    })
    runInAction(() => {
      this.files = filesData.files
      this.filesCount = filesData.count
      this.currentFilesPage = 0
      this.tags = tagsData.tags
      this.categories = { categoriesArrays, otherCategory }
      this.allCategories = allCategories

      if (this.isCollectionMode() && !this.isSearchMode()) {
        this.collectionTags = tagsData.tags
      }
      this.isLoading = false
      this.users = usersData.users
      //this.exts = extensionsData // dynamicallly updates the filters
    })

    this.getFilesAndSimiliarFiles(values)
  }

  setSelectedList = (files) => {
    runInAction(() => {
      this.selectedList = [...files]
    })
  }

  isSelectedFile = (_id) => {
    const bool = !!this.selectedList.find((item) => item?._id === _id)
    return bool
  }

  selectFile = (file) => {
    this.setSelectedList([...this.selectedList, file])
  }

  unselectFile = (file) => {
    const newList = this.selectedList.filter((item) => item._id !== file._id)
    this.setSelectedList(newList)
  }

  selectAllFilteredFiles = () => {
    // this is in order to only select the current page if in table view
    const tempFiles =
      this.fileViewMode === 'grid'
        ? this.files
        : this.files.slice(
            this.currentFilesPage * 100,
            (this.currentFilesPage + 1) * 100
          )
    const newList = uniqBy(union(this.selectedList, tempFiles), '_id')
    this.setSelectedList(newList)
  }

  onTagSelected = (tag) => {
    console.log('onTagSelected')
    document.querySelector('.files-container').scrollTop = 0

    const selected = !!this.selectedTags?.find((value) => value._id === tag._id)
    tag = tag ? tag : ''
    if (!selected) {
      // in order not to select 2 curated lists at the same time
      if (tag.type === 'CURATED') {
        this.selectedTags = []
      }
      this.selectedTags = this.selectedTags.concat([tag])
    } else {
      this.selectedTags = this.selectedTags.filter((i) => {
        return i._id !== tag._id
      })
    }
    runInAction(() => {
      const tags = this.selectedTags
        ? this.selectedTags.map(({ _id }) => _id)
        : ['']
      this.selectedTags = this.selectedTags ? this.selectedTags : ['']
      this.updateFilters({ tags })
    })
  }

  onKeywordSelected = (keyword) => {
    const selected = !!this.selectedKeywords?.find(
      (value) => value._id === keyword._id
    )
    keyword = keyword ? keyword : ''
    if (!selected) {
      this.selectedKeywords = this.selectedKeywords.concat([keyword])
    } else {
      this.selectedKeywords = this.selectedKeywords.filter((i) => {
        return i._id !== keyword._id
      })
    }
    runInAction(() => {
      const keywords = this.selectedKeywords
        ? this.selectedKeywords.map(({ _id }) => _id)
        : ['']
      this.selectedKeywords = this.selectedKeywords
        ? this.selectedKeywords
        : ['']
      this.updateFilters({ keywords })
    })
  }

  onPersonSelected = async (person, clearTags = false) => {
    if (clearTags) {
      this.selectedTags = []
    }
    const selected = !!this.selectedPersons?.find(
      (value) => value._id === person._id
    )

    if (!selected) {
      //get this person again if changed name
      person = await this.rootStore.apis.getPerson(person._id)

      runInAction(() => {
        this.selectedPersons = this.selectedPersons.concat([person])
      })
    } else {
      runInAction(() => {
        this.selectedPersons = this.selectedPersons.filter((i) => {
          return i._id !== person._id
        })
      })
    }
    runInAction(() => {
      const persons = this.selectedPersons
        ? this.selectedPersons.map(({ _id }) => _id)
        : ['']
      this.selectedPersons = this.selectedPersons ? this.selectedPersons : ['']
      this.updateFilters({ persons })
    })
  }

  onUserSelected = (users) => {
    runInAction(() => {
      this.selectedUsers = users
      const emails = users.map((user) => {
        return user.username
      })
      this.updateFilters({ users: emails })
    })
  }

  onReset = async () => {
    runInAction(() => {
      this.isLoading = true
    })
    console.log('onReset')
    const { workspaceId } = this.rootStore.orgsWorkspacesStore

    runInAction(() => {
      //history.push is for coming back from the Drive page
      history.push(`/w/${workspaceId}`)
      this.selectedTags = []
      this.selectedKeywords = []
      this.selectedPersons = []
      this.selectedCollections = []
      this.tagsValue = []
      this.keywordsValue = []
      this.personsValue = []
      this.collectionsValue = []
      this.selectedUsers = []
      this.selectedExt = []
      this.selectedExtByType = []
      this.selectedFileType = { name: '' }
      this.selectedDuration = {}
      this.selectedFileSize = {}
      this.selectedOrientationMode = { name: null }
      this.selectedColors = []
      this.selectedResolution = {}
      this.selectedAdvanceColorFilter = { name: null }
      this.imageToSearch = null
      this.textToPreSearch = ''
      this.isFavorite = false
      this.unTagged = false
      this.textToSearch = ''
      this.startDate = null
      this.endDate = null
      this.dateRangeType = {}
      this.people = {
        gender: { male: false, female: false, both: false },
        number: -1,
        emotion: { positive: false, neutral: false, negative: false },
        pose: {
          straight: false,
          sideways: false,
          up: false,
          down: false,
          tilted: false,
        },
        age: {
          infants: false,
          children: false,
          teenagers: false,
          twenties: false,
          thirties: false,
          forties: false,
          fifties: false,
          sixties: false,
          older: false,
        },
      }
      this.querySearch = []
      this.currentFilesPage = 0

      //this.updateFilters();
      //this.getPopularTags();
    })

    //this is done first to avoid stolling by UI
    var getFilesPromise = this.getFilesFiltered()

    this.getAllTags({})
    this.getAllFilesUsers({})
    this.getAllFilesExtensions({})
    this.resetCurrentCollection()
    this.getAllCollections({})
    this.getAllPersons({})
    this.rootStore.orgsWorkspacesStore.getOrgLimits()

    //await this.getFilesFiltered()
    await getFilesPromise

    runInAction(() => {
      this.isLoading = false
    })
  }

  onResetFilteres = () => {
    runInAction(() => {
      this.selectedTags = []
      this.selectedKeywords = []
      this.selectedPersons = []
      this.tagsValue = []
      this.keywordsValue = []
      this.personsValue = []
      this.selectedUsers = []
      this.selectedExt = []
      this.selectedExtByType = []
      this.selectedFileType = { name: '' }
      this.selectedDuration = {}
      this.selectedFileSize = {}
      this.selectedOrientationMode = { name: null }
      this.selectedColors = []
      this.selectedResolution = {}
      this.selectedAdvanceColorFilter = { name: null }
      this.imageToSearch = null
      this.textToPreSearch = ''
      this.isFavorite = false
      this.unTagged = false
      this.textToSearch = ''
      this.startDate = null
      this.endDate = null
      this.dateRangeType = {}
      this.people = {
        gender: { male: false, female: false, both: false },
        number: -1,
        emotion: { positive: false, neutral: false, negative: false },
        pose: {
          straight: false,
          sideways: false,
          up: false,
          down: false,
          tilted: false,
        },
        age: {
          infants: false,
          children: false,
          teenagers: false,
          twenties: false,
          thirties: false,
          forties: false,
          fifties: false,
          sixties: false,
          older: false,
        },
      }
      this.querySearch = []
      this.updateFilters()
    })
  }

  onUploadFile = ({ file }) => {
    this.rootStore.apis.uploadFile({ file }).then((file) => {
      runInAction(() => {})
    })
  }

  getFiles = async ({
    tags,
    keywords,
    persons,
    collections,
    users,
    text,
    image,
    imageTreshold = this.SEARCH_IMAGE_TRESHOLD,
    isFavorite,
    unTagged,
    limit,
    skip,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    updatedStart,
    updatedEnd,
    createdStart,
    createdEnd,
    viewedDateStart,
    viewedDateEnd,
  }) => {
    console.log('getFiles')
    const { orgId, workspaceId } =
      this.rootStore.orgsWorkspacesStore && this.rootStore.orgsWorkspacesStore

    const { files, count } = await this.rootStore.apis.getFiles({
      orgId,
      workspaceId,
      tags,
      keywords,
      persons,
      collections,
      users,
      text,
      image,
      imageTreshold,
      isFavorite,
      unTagged,
      limit,
      skip,
      exts,
      people,
      duration,
      fileSize,
      orientationMode,
      colors,
      resolution,
      advanceColorFilter,
      updatedStart,
      updatedEnd,
      createdStart,
      createdEnd,
      viewedDateStart,
      viewedDateEnd,
    })
    //pagination
    runInAction(() => {
      if (!skip) {
        this.files = files
        this.filesCount = count
        this.currentFilesPage = 0
      } else {
        this.files = this.files.concat(files)
      }
      this.isLoading = false
    })

    //async get all files with similar
    this.getFilesAndSimiliarFiles({ skip })
  }

  getAllPagesFiles = async ({
    tags,
    keywords,
    persons,
    collections,
    users,
    text,
    image,
    imageTreshold = this.SEARCH_IMAGE_TRESHOLD,
    isFavorite,
    unTagged,
    limit,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    updatedStart,
    updatedEnd,
    createdStart,
    createdEnd,
    viewedDateStart,
    viewedDateEnd,
  }) => {
    console.log('getFiles')
    const { orgId, workspaceId } =
      this.rootStore.orgsWorkspacesStore && this.rootStore.orgsWorkspacesStore

    //calculate number of pages
    const pages = Math.ceil(this.files.length / this.FILES_IN_PAGE)
    console.log({ pages })
    const totalPagesPromises = []

    for (let pageIndex = 0; pageIndex < pages; pageIndex++) {
      console.log({ pageIndex })
      const pageFilesPromise = this.rootStore.apis.getFiles({
        orgId,
        workspaceId,
        tags,
        keywords,
        persons,
        collections,
        users,
        text,
        image,
        imageTreshold,
        isFavorite,
        unTagged,
        limit,
        skip: pageIndex * this.FILES_IN_PAGE,
        exts,
        people,
        duration,
        fileSize,
        orientationMode,
        colors,
        resolution,
        advanceColorFilter,
        updatedStart,
        updatedEnd,
        createdStart,
        createdEnd,
        viewedDateStart,
        viewedDateEnd,
      })
      totalPagesPromises.push(pageFilesPromise)
    }

    const totalPages = await Promise.all(totalPagesPromises)

    //merge all pages
    var files = []
    for (const filesPage of totalPages) {
      let localFiles = filesPage.files
      files = files.concat(localFiles)
    }

    runInAction(() => {
      this.files = files
      this.filesCount = totalPages[0].count
      //this.currentFilesPage = 0 //this is not needed as we want to stay on the same page
    })

    //async get all files with similar
    for (let pageIndex = 0; pageIndex < pages; pageIndex++) {
      this.getFilesAndSimiliarFiles({ skip: pageIndex * this.FILES_IN_PAGE })
    }
  }

  getFiles_getter = async ({
    tags,
    keywords,
    persons,
    collections,
    users,
    text,
    image,
    imageTreshold = this.SEARCH_IMAGE_TRESHOLD,
    isFavorite,
    unTagged,
    limit,
    skip,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    updatedStart,
    updatedEnd,
    createdStart,
    createdEnd,
    viewedDateStart,
    viewedDateEnd,
  }) => {
    console.log('getFiles_getter')
    const { orgId, workspaceId } =
      this.rootStore.orgsWorkspacesStore && this.rootStore.orgsWorkspacesStore

    const { files, count } = await this.rootStore.apis.getFiles({
      orgId,
      workspaceId,
      tags,
      keywords,
      persons,
      collections,
      users,
      image,
      imageTreshold,
      text,
      isFavorite,
      unTagged,
      limit,
      skip,
      exts,
      people,
      duration,
      fileSize,
      orientationMode,
      colors,
      resolution,
      advanceColorFilter,
      updatedStart,
      updatedEnd,
      createdStart,
      createdEnd,
      viewedDateStart,
      viewedDateEnd,
    })

    return { files, count }
  }

  getFilesAndSimiliarFiles = async (values) => {
    const { orgId, workspaceId } =
      this.rootStore.orgsWorkspacesStore && this.rootStore.orgsWorkspacesStore

    this.getPeopleFilter()

    let data = {
      orgId,
      workspaceId,
      similar: true,
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      image: this.imageToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      ...this.getDateFilter(),
      ...values,
    }
    if (!data.text) delete data.text

    data.limit = this.FILES_IN_PAGE
    data.skip = data.skip || 0

    const { files, count } = await this.rootStore.apis.getFiles(data)

    this.updateMultipleFiles(files)
    /*
    files.map((file) => {
      this.updateFiles(file)
    })
    */
  }

  updateFilePreview = async ({ fileId, preview }) => {
    const file = await this.rootStore.apis.updateFile({ fileId, preview })
  }

  updateFileName = async ({ fileId, name }) => {
    const file = await this.rootStore.apis.updateFile({ fileId, name })
    //removed this to remove rerenders
    //if (this.currentFile._id) {
    //  this.addToFileViewed({ fileId: file.fileId, force: true })
    //}
    await this.updateFilesAndTags()
  }

  updateFileDescription = async ({ fileId, description }) => {
    const file = await this.rootStore.apis.updateFile({ fileId, description })
    if (this.currentFile?._id) {
      this.addToFileViewed({ fileId: file.fileId, force: true })
    }
    await this.updateFilesAndTags()
  }

  updateFileType = async ({ fileId, type }) => {
    const file = await this.rootStore.apis.updateFile({ fileId, type })
  }

  setCDN = async ({ fileId, enable }) => {
    const { cdnURL, cdnCount } = await this.rootStore.apis.setCDNFile({
      fileId,
      enable,
    })

    runInAction(() => {
      this.rootStore.orgsWorkspacesStore.orgPlanLimits.cdnCount = cdnCount
      var index = this.files.findIndex((file) => file._id === fileId)
      if (index !== -1) {
        this.files[index].cdnURL = cdnURL
      }
    })

    return cdnURL
  }

  getRelatedFilesFilterd = (data) => {
    console.log('getRelatedFilesFilterd')
    data.limit = 50
    data.skip = 0
    this.getFiles(data)
  }

  getFilesFiltered = async (values) => {
    console.log('getFilesFiltered')
    let data = {
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      image: this.imageToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      ...this.getDateFilter(),
      ...values,
    }
    if (!data.text) delete data.text

    data.limit = this.FILES_IN_PAGE
    data.skip = data.skip || 0

    //regular get files
    if (this.files.length <= this.FILES_IN_PAGE || data.skip != 0) {
      await this.getFiles(data)
    }
    //we already paginated - bring all pages
    else {
      await this.getAllPagesFiles(data)
    }
  }

  getFilesFiltered_getter = async (values) => {
    console.log('getFilesFiltered_getter')

    this.getPeopleFilter()

    let data = {
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      image: this.imageToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      ...this.getDateFilter(),
      ...values,
    }
    if (!data.text) delete data.text

    data.limit = this.FILES_IN_PAGE
    data.skip = data.skip || 0

    return await this.getFiles_getter(data)
  }

  getTags = async ({
    tags,
    keywords,
    persons,
    collections,
    text,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    createdStart,
    createdEnd,
    users,
  }) => {
    console.log('getTags')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    this.rootStore.apis
      .getTags({
        orgId,
        workspaceId,
        tags,
        keywords,
        persons,
        collections,
        text,
        exts,
        people,
        duration,
        fileSize,
        orientationMode,
        colors,
        resolution,
        advanceColorFilter,
        createdStart,
        createdEnd,
        users,
      })
      .then(({ tags }) => {
        runInAction(() => {
          this.tags = tags
        })
      })
  }

  getTags_getter = async ({
    tags,
    keywords,
    persons,
    collections,
    text,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    createdStart,
    createdEnd,
    users,
    isFavorite,
    unTagged,
  }) => {
    console.log('getTags_getter')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    return await this.rootStore.apis.getTags({
      orgId,
      workspaceId,
      tags,
      keywords,
      persons,
      collections,
      text,
      exts,
      people,
      duration,
      fileSize,
      orientationMode,
      colors,
      resolution,
      advanceColorFilter,
      createdStart,
      createdEnd,
      users,
      isFavorite,
      unTagged,
    })
  }

  getAllTags = async ({ tags = [], text = null, exts = '', users = [] }) => {
    console.log('getAllTags')
    runInAction(() => {
      this.gettingTags = true
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    try {
      const allTags = await this.rootStore.apis.getTags({
        orgId,
        workspaceId,
        tags,
        text,
        exts,
        users,
      })

      const allCategories = await this.rootStore.apis.getCategories({
        orgId,
        workspaceId,
      })

      //const filteredTags = await this.getTagsFiltered_getter()
      //this.getTagsFiltered();

      const { categoriesArrays, otherCategory } = this.mapCategories({
        allCategories,
        allTags: allTags.tags,
        tags: allTags.tags,
      })

      runInAction(() => {
        this.tags = allTags.tags
        this.allTags = allTags.tags
        this.allCategories = allCategories
        this.categories = { categoriesArrays, otherCategory }
        this.gettingTags = false
      })
      return allTags.tags
    } catch (err) {
      throw err
    }
  }

  getTagsFiltered = async (values) => {
    console.log('getTagsFiltered')
    const { orgId, workspaces, workspaceId } =
      this.rootStore.orgsWorkspacesStore
    //console.log("get tags filtered")
    const tags = await this.getTags_getter({
      orgId,
      workspaces,
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      createdStart: this.startDate,
      createdEnd: this.endDate,
      ...values,
    })

    const allCategories = await this.rootStore.apis.getCategories({
      orgId,
      workspaceId,
    })

    const { categoriesArrays, otherCategory } = this.mapCategories({
      allCategories: allCategories,
      allTags: this.allTags,
      tags,
    })

    runInAction(() => {
      this.tags = tags
      this.categories = { categoriesArrays, otherCategory }
    })
  }

  getTagsFiltered_getter = async (values) => {
    console.log('getTagsFiltered_getter')
    const { orgId, workspaces } = this.rootStore.orgsWorkspacesStore
    //console.log("get tags filtered")
    return await this.getTags_getter({
      orgId,
      workspaces,
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      createdStart: this.startDate,
      createdEnd: this.endDate,
      ...values,
    })
  }

  getFilesUsers = async ({
    tags,
    keywords,
    persons,
    collections,
    text,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    createdStart,
    createdEnd,
    users,
  }) => {
    console.log('getFilesUsers')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    this.rootStore.apis
      .getFilesUsers({
        orgId,
        workspaceId,
        tags,
        keywords,
        persons,
        collections,
        text,
        exts,
        people,
        duration,
        fileSize,
        orientationMode,
        colors,
        resolution,
        advanceColorFilter,
        createdStart,
        createdEnd,
        users,
      })
      .then(({ users }) => {
        runInAction(() => {
          this.users = users
        })
      })
  }

  renamePerson = async ({ personId, name }) => {
    console.log('renamePerson')

    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const person = await this.rootStore.apis.renamePerson({ personId, name })

    const data = await this.rootStore.apis.checkDuplicate({
      orgId,
      workspaceId,
      name,
    })
    if (data.result) {
      //found duplicate
      runInAction(() => {
        this.duplicatePersons = data.persons
      })
    }

    //rename in this.persons
    const personIndex = this.persons.findIndex(
      (person) => person._id === personId
    )
    if (personIndex != -1) {
      runInAction(() => {
        this.persons[personIndex].name = name
      })
    }

    //this couse rerender of the person list: do it disabled
    //this.getAllPersons({})
  }

  mergePersons = async (merge) => {
    if (!merge) {
      runInAction(() => {
        this.duplicatePersons = []
      })
    } else {
      const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

      const ids = this.duplicatePersons.map((person) => person._id)
      await this.rootStore.apis.mergePersons({ ids, orgId, workspaceId })

      runInAction(() => {
        this.duplicatePersons = []
      })

      //refresh persons
      this.getAllPersons({})
    }
  }

  removePerson = async ({ personId }) => {
    await this.rootStore.apis.removePerson({ personId })

    //refresh persons
    this.getAllPersons({})
    if (this.currentFile?._id) {
      this.addToFileViewed({ fileId: this.currentFile.fileId, force: true })
    }
    await this.updateFilesAndTags()
  }

  getPersons = async ({
    tags,
    keywords,
    persons,
    collections,
    text,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    createdStart,
    createdEnd,
    users,
  }) => {
    console.log('getPersons')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    const personResult = await this.rootStore.apis.getPersons({
      orgId,
      workspaceId,
      tags,
      keywords,
      persons,
      collections,
      text,
      exts,
      people,
      duration,
      fileSize,
      orientationMode,
      colors,
      resolution,
      advanceColorFilter,
      createdStart,
      createdEnd,
      users,
    })

    runInAction(() => {
      this.persons = personResult
    })
  }

  getAllPersons = async ({
    tags = [],
    text = null,
    exts = '',
    users = [],
    skip = 0,
  }) => {
    console.log('getAllPersons')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    var collectionsValue = []
    if (this.isCollectionMode()) {
      collectionsValue = this.selectedCollections.map(({ _id }) => _id)
    }

    try {
      const personResult = await this.rootStore.apis.getPersons({
        orgId,
        workspaceId,
        collections: collectionsValue,
        tags,
        text,
        exts,
        users,
        skip,
        limit: this.PERSONS_IN_PAGE,
      })

      runInAction(() => {
        if (skip == 0) {
          this.persons = personResult
          if (!this.isCollectionMode()) {
            this.allPersons = personResult
          }
        } else {
          this.persons = this.persons.concat(personResult)
          if (!this.isCollectionMode()) {
            this.allPersons = this.allPersons.concat(personResult)
          }
        }
      })

      return personResult
    } catch (err) {
      throw err
    }
  }

  //check if all persons in recent files have thumbanils - indicating face detection has ended
  isAllPersonsHaveTumbnails = async () => {
    //await this.getAllPersons({});
    await this.getRecentlyUploadedFiles()
    var hasThumbnailsArray = this.recentlyUploadedFiles.map((file) =>
      file.persons.reduce(
        (accumulator, currentValue) =>
          accumulator && currentValue.defaultFaceLocation != undefined,
        true
      )
    )

    var hasThumbnails = hasThumbnailsArray.reduce(
      (accumulator, currentValue) => accumulator && currentValue,
      true
    )
    return hasThumbnails
  }

  getFilesUsers_getter = async ({
    tags,
    keywords,
    persons,
    collections,
    text,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    createdStart,
    createdEnd,
    users,
    isFavorite,
    unTagged,
  }) => {
    console.log('getFilesUsers_getter')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    return await this.rootStore.apis.getFilesUsers({
      orgId,
      workspaceId,
      tags,
      keywords,
      persons,
      collections,
      text,
      exts,
      people,
      duration,
      fileSize,
      orientationMode,
      colors,
      resolution,
      advanceColorFilter,
      createdStart,
      createdEnd,
      users,
      isFavorite,
      unTagged,
    })
  }

  getFilesExtensions_getter = async ({
    tags,
    keywords,
    persons,
    collections,
    text,
    exts,
    people,
    duration,
    fileSize,
    orientationMode,
    colors,
    resolution,
    advanceColorFilter,
    createdStart,
    createdEnd,
    users,
    isFavorite,
    unTagged,
  }) => {
    console.log('getFilesExtensions_getter')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    return await this.rootStore.apis.getFilesExtensions({
      orgId,
      workspaceId,
      tags,
      keywords,
      persons,
      collections,
      text,
      exts,
      people,
      duration,
      fileSize,
      orientationMode,
      colors,
      resolution,
      advanceColorFilter,
      createdStart,
      createdEnd,
      users,
      isFavorite,
      unTagged,
    })
  }

  getAllFilesExtensions = async ({
    tags = [],
    text = null,
    exts = '',
    users = [],
  }) => {
    console.log('getAllFilgetAllFilesExtensionsesUsers')
    runInAction(() => {
      //this.gettingTags = true;
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    try {
      const allExts = await this.rootStore.apis.getFilesExtensions({
        orgId,
        workspaceId,
        tags,
        text,
        exts,
        users,
      })
      runInAction(() => {
        this.exts = allExts
      })
      return allExts
    } catch (err) {
      throw err
    }
  }

  getAllFilesUsers = async ({
    tags = [],
    text = null,
    exts = '',
    users = [],
  }) => {
    console.log('getAllFilesUsers')
    runInAction(() => {
      //this.gettingTags = true;
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    try {
      const allUsers = await this.rootStore.apis.getFilesUsers({
        orgId,
        workspaceId,
        tags,
        text,
        exts,
        users,
      })
      runInAction(() => {
        this.users = allUsers.users
        //this.allTags = allTags.tags;
        //this.gettingTags = false;
      })
      return allUsers.users
    } catch (err) {
      throw err
    }
  }

  getUsersFiltered = async (values) => {
    console.log('getUsersFiltered')
    const { orgId, workspaces } = this.rootStore.orgsWorkspacesStore
    this.getFilesUsers({
      orgId,
      workspaces,
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      createdStart: this.startDate,
      createdEnd: this.endDate,
      ...values,
    })
  }

  getUsersFiltered_getter = async (values) => {
    console.log('getUsersFiltered_getter')
    const { orgId, workspaces } = this.rootStore.orgsWorkspacesStore
    return await this.getFilesUsers_getter({
      orgId,
      workspaces,
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      createdStart: this.startDate,
      createdEnd: this.endDate,
      ...values,
    })
  }

  getFilesExtensionsFiltered_getter = async (values) => {
    console.log('getFilesExtensionsFiltered_getter')
    const { orgId, workspaces } = this.rootStore.orgsWorkspacesStore
    return await this.getFilesExtensions_getter({
      orgId,
      workspaces,
      tags: this.tagsValue,
      keywords: this.keywordsValue,
      persons: this.personsValue,
      collections: this.collectionsValue,
      users: this.selectedUsers.map((userId) => {
        return userId.username
      }),
      text: this.textToSearch,
      isFavorite: this.isFavorite,
      unTagged: this.unTagged,
      exts: this.selectedExt.length ? this.selectedExt : this.selectedExtByType,
      duration: this.selectedDuration,
      fileSize: this.selectedFileSize,
      orientationMode: this.selectedOrientationMode,
      colors: this.selectedColors,
      resolution: this.selectedResolution,
      advanceColorFilter: this.selectedAdvanceColorFilter,
      people: this.getPeopleFilter(),
      createdStart: this.startDate,
      createdEnd: this.endDate,
      ...values,
    })
  }

  onExtSelected = (exts) => {
    console.log({ onExtSelected: exts })
    runInAction(() => {
      this.selectedExt = exts
      //this.selectedFileType = type;
      this.updateFilters({ exts })
    })
  }

  onFileTypeSelected = (exts, type) => {
    runInAction(() => {
      //this.selectedExt = exts;
      this.selectedExtByType = exts
      this.selectedFileType = type
      this.updateFilters()
    })
  }

  onDurationSelected = (selectedDuration) => {
    runInAction(() => {
      this.selectedDuration = selectedDuration
      this.updateFilters()
    })
  }

  onFileSizeSelected = (selectedFileSize) => {
    runInAction(() => {
      this.selectedFileSize = selectedFileSize
      this.updateFilters()
    })
  }

  onOrientationModeSelected = (selectedOrientationMode) => {
    runInAction(() => {
      this.selectedOrientationMode = selectedOrientationMode
      this.updateFilters()
    })
  }

  onColorSelected = (selectedColors) => {
    runInAction(() => {
      this.selectedColors = selectedColors
      this.updateFilters()
    })
  }

  onImageSearch = (imageUrl) => {
    runInAction(() => {
      this.textToSearch = ''
      this.imageToSearch = imageUrl
      this.updateFilters()
    })
  }

  onResolutionSelected = (selectedResolution) => {
    runInAction(() => {
      this.selectedResolution = selectedResolution
      this.updateFilters()
    })
  }

  onAdvanceColorFilterSelected = (selectedAdvanceColorFilter) => {
    runInAction(() => {
      this.selectedAdvanceColorFilter = selectedAdvanceColorFilter
      this.updateFilters()
    })
  }

  onPeopleSelected = (people) => {
    runInAction(() => {
      this.people = people
      this.updateFilters() //maybe add here filter?
    })
  }

  resized = ({ width }) => {
    runInAction(() => {
      this.maxFilesInRow =
        parseInt((width * this.widthPercents) / this.fileContainerWidth) || 1
    })
  }

  updateFiles = (file) => {
    runInAction(() => {
      this.files = this.files.map((record) => {
        return record.fileId === file.fileId ? file : record
      })
    })
  }

  updateFileSimliar = (file) => {
    runInAction(() => {
      var index = this.files.findIndex(
        (record) => record.fileId === file.fileId
      )

      if (index != -1) {
        this.files[index].similar = file.similar
        this.files[index].preview = file.preview
        this.files[index].convertedPreview = file.convertedPreview
        this.files[index].lowRes = file.lowRes
        this.files[index].transcription = file.transcription
        this.files[index].persons = file.persons
        this.files[index].versions = file.versions
      }
    })
  }

  updateMultipleFiles = (files) => {
    files.map((file) => {
      this.updateFileSimliar(file)
    })
  }

  addToFavorite = async ({ fileId }) => {
    const { orgId, workspaces } = this.rootStore.orgsWorkspacesStore
    this.rootStore.apis
      .addToFavorite({ orgId, workspaces, fileId })
      .then(({ file }) => {
        this.updateFiles(file)
        runInAction(() => {
          this.currentFile = file
        })
      })
  }

  addToFileViewed = async ({ fileId, force = false }) => {
    const { orgId, workspaces, workspaceId } =
      this.rootStore.orgsWorkspacesStore

    var fileExist = this.files.find((file) => file.fileId === fileId)

    if (fileExist && fileExist.similar && !fileExist.transcription && !force) {
      runInAction(() => {
        this.currentFile = fileExist
        this.simlarFiles = fileExist.similar
      })
      this.rootStore.apis.addToFileViewed({
        orgId,
        workspaces,
        fileId,
      })
      return
    }

    const { file } = await this.rootStore.apis.addToFileViewed({
      orgId,
      workspaces,
      fileId,
      similar: true,
    })
    //await this.getSimilarImages(file)
    this.updateFiles(file)

    runInAction(() => {
      this.currentFile = file
      this.simlarFiles = file.similar
    })
  }

  previewNextPrev = async (direction = 'next') => {
    const currentFileIndex = this.files.findIndex(
      (file) => file._id === this.currentFile._id
    )
    var newFile
    if (direction === 'next') {
      if (
        currentFileIndex === this.files.length - 1 &&
        this.files?.length % this.FILES_IN_PAGE === 0 &&
        this.files.length > 0
      ) {
        //last index and more files
        console.log(this.files.length)
        await this.getFilesFiltered({ skip: this.files.length })
        console.log(this.files.length)
      }
      newFile = this.files[currentFileIndex + 1]
    } else {
      newFile = this.files[currentFileIndex - 1]
    }

    if (newFile) {
      this.addToFileViewed({ fileId: newFile.fileId })
    }
  }

  isFileFirst = () => {
    const currentFileIndex = this.files.findIndex(
      (file) => file._id === this.currentFile._id
    )
    return currentFileIndex === 0 || currentFileIndex === -1
  }

  isFileLast = () => {
    const currentFileIndex = this.files.findIndex(
      (file) => file._id === this.currentFile._id
    )
    return (
      currentFileIndex == -1 ||
      (this.files.length > 0 &&
        currentFileIndex === this.files.length - 1 &&
        this.files.length % this.FILES_IN_PAGE != 0)
    )
  }

  mergeVersion = async ({ newFileId, oldFileId }) => {
    const file = await this.rootStore.apis.mergeVersion({
      newFileId,
      oldFileId,
    })

    await this.addToFileViewed({ fileId: file.fileId, force: true })
    return file
  }

  restoreVersion = async ({ fileId, versionIndex }) => {
    const file = await this.rootStore.apis.restoreVersion({
      fileId,
      versionIndex,
    })
    await this.addToFileViewed({ fileId: file.fileId, force: true })
    return file
  }

  resetCurrentFile = () => {
    runInAction(() => {
      this.currentFile = {}
      this.simlarFiles = []
    })
  }

  // This is used by both taglist and omnisearch
  onPreSearchChanged = async ({ text, type = 'OMNI', context }) => {
    runInAction(() => {
      // prevent taglist typing from chaning the omnisearch text
      if (type === 'OMNI') {
        this.textToPreSearch = text
      }
      this.debouncePreSearch(text, type, context)
    })
  }

  resetTagPresearch = () => {
    runInAction(() => {
      this.preSearchTagResults = {}
    })
  }

  onSearchChanged = (searchTerm) => {
    runInAction(() => {
      this.textToSearch =
        searchTerm !== undefined ? searchTerm : this.textToPreSearch
      //this.files = []
      this.updateFilters({ text: this.textToSearch })
    })
  }

  onIsFavoriteChanged = async () => {
    this.isLoading = true

    runInAction(() => {
      this.isFavorite = !this.isFavorite
      this.files = []
      this.tags = []
    })
    await this.updateFilters({ isFavorite: this.isFavorite })
    runInAction(() => {
      this.isLoading = false
    })
  }

  onUnTaggedChanged = async () => {
    runInAction(() => {
      if (!this.unTagged) {
        this.selectedTags = []
      }
      this.unTagged = !this.unTagged
    })
    await this.updateFilters({ unTagged: this.unTagged })
  }

  onRelatedSearchChanged = (text) => {
    runInAction(() => {
      this.textToSearch = text
      this.files = []

      this.getRelatedFilesFilterd({ text: this.textToSearch })
    })
  }

  onRelatedSearchChangedCollection = async (text) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const { files, count } = await this.rootStore.apis.getFiles({
      orgId,
      workspaceId,
      text,
      limit: 50,
      skip: 0,
    })
    return files
  }

  addOrgTag = async ({ tag, type = 'REGULAR' }) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const newTag = await this.rootStore.apis.createTag({
      orgId,
      workspaceId,
      tag,
      type,
    })
    this.checkSimilarTags({ tag, type })

    return newTag
  }

  checkSimilarTags = async ({ tag, type = 'REGULAR' }) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const newTag = await this.rootStore.apis.checkSimilarTags({
      orgId,
      workspaceId,
      tag,
      type,
    })
    runInAction(() => {
      if (newTag.type === 'REGULAR') {
        this.foundFilesSnackbar = newTag.count

        this.tagIsSuggest =
          newTag.tagsSynonyms?.length ||
          newTag.fuzzyTags?.length ||
          newTag.fuzzyKeywords?.length ||
          newTag.similarKeywords?.length
        this.tagSuggestion =
          newTag.tagsSynonyms[0] ||
          newTag.fuzzyTags[0]?.name ||
          newTag.fuzzyKeywords[0]?.name ||
          newTag.similarKeywords[0] ||
          ''
        this.tagSuggestIsExist =
          newTag.tagsSynonyms?.length || newTag.fuzzyTags?.length
        this.tagSuggestionCount = newTag.fuzzyTags[0]
          ? newTag.fuzzyTags[0]?.count
          : newTag.fuzzyKeywords[0]?.count
        this.lastTag = newTag
      }
    })
    setTimeout(() => {
      runInAction(() => {
        this.foundFilesSnackbar = false
      })
    }, 4000)

    return newTag
  }

  cancelTagSuggestions = async () => {
    runInAction(() => {
      this.tagIsSuggest = false
      this.tagSuggestion = null
      this.tagSuggestIsExist = false
      this.tagSuggestionCount = 0
      this.lastTag = null
    })
  }

  updateOrgTag = (orgId, tagId, data, name) => {
    return this.rootStore.apis.changeTagOrderlist(orgId, tagId, data, name)
  }

  addFileTag = async ({ fileId, tagsIds }) => {
    const { file } = await this.rootStore.apis.addFileTag({ fileId, tagsIds })
    await this.updateFilesAndTags()
    runInAction(() => {
      this.currentFile.tags = file.tags
    })
    return file
  }

  bulkTag = async ({ fileIds, tags, clearExisting }) => {
    const files = await this.rootStore.apis.bulkTag({
      fileIds,
      tags,
      clearExisting,
    })
    runInAction(() => {
      if (this.recentlyUploadedFilesIds?.length) {
        this.recentlyUploadedFiles = files
        files.map((file) => {
          this.updateFileInFileQ({
            _id: file._id,
            updates: { tags: file.tags },
          })
        })
      }
    })

    this.updateFilesAndTags()
    return
  }

  bulkRemoveTags = async ({ fileIds, tagIds }) => {
    const files = await this.rootStore.apis.bulkRemoveTags({ fileIds, tagIds })

    runInAction(() => {
      if (this.recentlyUploadedFilesIds?.length) {
        this.recentlyUploadedFiles = files
        files.map((file) => {
          this.updateFileInFileQ({
            _id: file._id,
            updates: { tags: file.tags },
          })
        })
      }
    })

    this.updateFilesAndTags()
  }

  downloadFile = async (file) => {
    await this.rootStore.apis.downloadFileAPI(file.fileId)
    //window.open(signedLocation, '_blank')
    //saveAs(signedLocation, file.name)
  }

  proxyDownloadFile = async (file) => {
    return await this.rootStore.apis.proxyDownloadFileAPI(file.fileId)
  }

  /*
  async bulkDownload({ fileIds })=> {
    runInAction(() => {
      this.isBulkDownloading = true
      this.selectedList = []
    })

    await this.rootStore.apis.downloadBulkFiles({ fileIds })

    runInAction(() => {
      this.isBulkDownloading = false
    })

    return
  }
  */

  bulkDownload = async ({ fileIds }) => {
    await this.rootStore.apis.streamDownloadBulkFiles({ fileIds })
  }

  removeFileTag = async ({ fileId, tagsIds }) => {
    const { file } = await this.rootStore.apis.removeFileTag({
      fileId,
      tagsIds,
    })
    runInAction(() => {
      //console.log("removeFileTag")
      //this.updateFiles(file);
      //this.getAllTags({});
      this.updateFilesAndTags()
      this.currentFile.tags = file.tags
    })
    return file
  }

  fileUploaded = async ({ file }) => {
    const { orgId, workspaces } = this.rootStore.orgsWorkspacesStore
    const thirdPartyDetails = storage.get(TOKEN_KEYWORD) || {}
    const { userId, accountId } = thirdPartyDetails
    this.rootStore.apis
      .fileUploaded({ userId, accountId, orgId, workspaces, file })
      .then(() => {
        this.getFilesFiltered()
      })
  }

  isSearchMode = () => {
    return (
      this.textToSearch ||
      this.isFavorite ||
      this.unTagged ||
      this.selectedTags?.length ||
      this.selectedKeywords?.length ||
      this.selectedPersons?.length ||
      !!this.selectedUsers?.length ||
      !!this.selectedExt?.length ||
      !!this.selectedExtByType?.length ||
      !!this.selectedDuration?.name ||
      !!this.selectedFileSize?.name ||
      !!this.selectedOrientationMode?.name ||
      !!this.selectedColors?.length ||
      !!this.selectedResolution.key ||
      !!this.selectedAdvanceColorFilter?.name ||
      !!this.dateRangeType.key ||
      !!this.imageToSearch ||
      this.isPeopleFilter()
    )
  }

  isPeopleFilter = () => {
    return (
      this.people.gender.male ||
      this.people.gender.female ||
      this.people.gender.both ||
      this.people.number != -1 ||
      this.people.emotion.positive ||
      this.people.emotion.neutral ||
      this.people.emotion.negative ||
      this.people.pose.straight ||
      this.people.pose.sideways ||
      this.people.pose.up ||
      this.people.pose.down ||
      this.people.pose.tilted ||
      this.people.age.infants ||
      this.people.age.children ||
      this.people.age.teenagers ||
      this.people.age.twenties ||
      this.people.age.thirties ||
      this.people.age.forties ||
      this.people.age.fifties ||
      this.people.age.sixties ||
      this.people.age.older
    )
  }

  getPeopleFilter = () => {
    var people = {}

    if (this.people.number == 0) {
      people.number = 0
      return people
    }
    if (this.people.number > 0) {
      people.number = this.people.number
    }

    if (this.people.gender.male) {
      people.gender = 'male'
    }
    if (this.people.gender.female) {
      people.gender = 'female'
    }
    if (this.people.gender.both) {
      people.gender = 'both'
    }

    if (this.people.emotion.positive) {
      people.emotion = 'positive'
    }
    if (this.people.emotion.neutral) {
      people.emotion = 'neutral'
    }
    if (this.people.emotion.negative) {
      people.emotion = 'negative'
    }

    if (this.people.pose.straight) {
      people.pose = 'straight'
    }
    if (this.people.pose.sideways) {
      people.pose = 'sideways'
    }
    if (this.people.pose.up) {
      people.pose = 'up'
    }
    if (this.people.pose.down) {
      people.pose = 'down'
    }
    if (this.people.pose.tilted) {
      people.pose = 'tilted'
    }

    if (
      this.people.age.infants ||
      this.people.age.children ||
      this.people.age.teenagers ||
      this.people.age.twenties ||
      this.people.age.thirties ||
      this.people.age.forties ||
      this.people.age.fifties ||
      this.people.age.sixties ||
      this.people.age.older
    ) {
      people.age = []
    }

    for (const [key, value] of Object.entries(this.people.age)) {
      if (value) {
        people.age.push(key)
      }
    }
    return people
  }

  getPeopleFilterQuery = () => {
    var people = []

    if (this.people.number == 0) {
      people.push(t`No People`)
    }
    if (this.people.number == 1) {
      people.push(t`1 Person`)
    }
    if (this.people.number == 2) {
      people.push(t`2 People`)
    }
    if (this.people.number == 3) {
      people.push(t`3+ People`)
    }

    if (this.people.gender.male) {
      people.push(t`Male`)
    }
    if (this.people.gender.female) {
      people.push(t`Female`)
    }
    if (this.people.gender.both) {
      people.push(t`Both genders`)
    }

    if (this.people.emotion.positive) {
      people.push(t`Positive emotion`)
    }
    if (this.people.emotion.neutral) {
      people.push(t`Neutral emotion`)
    }
    if (this.people.emotion.negative) {
      people.push(t`Negative emotion`)
    }

    if (this.people.pose.straight) {
      people.push(t`Looking straight`)
    }
    if (this.people.pose.sideways) {
      people.push(t`Head sideways`)
    }
    if (this.people.pose.up) {
      people.push(t`Looking up`)
    }
    if (this.people.pose.down) {
      people.push(t`Looking down`)
    }
    if (this.people.pose.tilted) {
      people.push(t`Head tilted`)
    }

    if (this.people.age.infants) {
      people.push(t`Infants`)
    }
    if (this.people.age.children) {
      people.push(t`Children`)
    }
    if (this.people.age.teenagers) {
      people.push(t`Teenagers`)
    }
    if (this.people.age.twenties) {
      people.push(t`20s`)
    }
    if (this.people.age.thirties) {
      people.push(t`30s`)
    }
    if (this.people.age.forties) {
      people.push(t`40s`)
    }
    if (this.people.age.fifties) {
      people.push(t`50s`)
    }
    if (this.people.age.sixties) {
      people.push(t`60s`)
    }
    if (this.people.age.older) {
      people.push(t`Older`)
    }

    return people
  }

  onRemovePeopleFilter = (filterName) => {
    var people = this.people

    if (
      [t`No People`, t`1 Person`, t`2 People`, t`3+ People`].includes(
        filterName
      )
    ) {
      people.number = -1
    }
    if ([t`Male`, t`Female`, t`Both genders`].includes(filterName)) {
      people.gender = { male: false, female: false, both: false }
    }

    if (
      [t`Positive emotion`, t`Neutral emotion`, t`Negative emotion`].includes(
        filterName
      )
    ) {
      people.emotion = { positive: false, neutral: false, negative: false }
    }

    if (
      [
        t`Looking straight`,
        t`Head sideways`,
        t`Looking up`,
        t`Looking down`,
        t`Head tilted`,
      ].includes(filterName)
    ) {
      people.pose = {
        straight: false,
        sideways: false,
        up: false,
        down: false,
        tilted: false,
      }
    }

    if (filterName === t`Infants`) {
      people.age.infants = false
    }
    if (filterName === t`Children`) {
      people.age.children = false
    }
    if (filterName === t`Teenagers`) {
      people.age.teenagers = false
    }
    if (filterName === t`20s`) {
      people.age.twenties = false
    }
    if (filterName === t`30s`) {
      people.age.thirties = false
    }
    if (filterName === t`40s`) {
      people.age.forties = false
    }
    if (filterName === t`50s`) {
      people.age.fifties = false
    }
    if (filterName === t`60s`) {
      people.age.sixties = false
    }
    if (filterName === t`Older`) {
      people.age.older = false
    }

    this.onPeopleSelected(people)
  }

  isCollectionMode = () => {
    return this.selectedCollections?.length > 0
  }

  getDateFilter = () => {
    // keeping 'view' and 'update' as infra for next steps
    if (this.startDate || this.endDate) {
      const startDateFormatted = this.startDate?.toISOString()
      const endDateFormatted = this.endDate?.toISOString()
      return {
        view: {
          viewedDateStart: startDateFormatted,
          viewedDateEnd: endDateFormatted,
        },
        create: {
          createdStart: startDateFormatted,
          createdEnd: endDateFormatted,
        },
        update: {
          updatedStart: startDateFormatted,
          updatedEnd: endDateFormatted,
        },
      }['create']
    }

    return {}
  }

  onDateSelected = ({ endDate, startDate, dateRangeType }) => {
    runInAction(() => {
      this.dateRangeType = dateRangeType
      if (
        dateRangeType &&
        dateRangeType.key &&
        dateRangeType.key !== 'custom'
      ) {
        const { unit, amount } = DATE_RANGES[dateRangeType.key]
        this.startDate = moment().subtract(amount, unit)
      } else {
        this.startDate = startDate
        this.endDate = endDate
      }
      this.updateFilters(this.getDateFilter())
    })
  }

  createBookmark = async ({ name, link }, orgId, username, workspaceId) => {
    let d = {
      orgId: orgId,
      workspaces: workspaceId,
      userId: username,
    }
    try {
      const data = await this.rootStore.apis.parseBookmarkUrl(link)
      if (isEmpty(data)) {
        throw new Error("Couldn't find this website")
      } else if (data === 'empty response') {
        d.file = {
          name: name || 'Untitled',
          size: 0,
          storageType: 'link',
          preview: link,
          hasThumbnail: false,
          Thumbnail: '',
        }
        return await this.rootStore.apis.createFile(d)
      } else {
        name = name ? name : data.title
        d.file = {
          name,
          size: 0,
          storageType: 'link',
          preview: data.url,
          hasThumbnail: !!data.images[0],
          Thumbnail: data.images[0] || '',
        }
        return await this.rootStore.apis.createFile(d)
      }
    } catch (err) {
      throw err
    }
  }

  //deprecated:
  getDriveFolders = ({ refresh_token }) => {
    this.rootStore.apis
      .getDriveFolders(refresh_token)
      .then((res) => {
        runInAction(() => {
          this.driveRefreshToken = refresh_token
          this.driveFolders = res
          history.push('/drive')
        })
      })
      .catch((err) => {})
  }

  getDrives = async ({ refresh_token }) => {
    const drives = await this.rootStore.apis.getDrives(refresh_token)
    runInAction(() => {
      this.drives = drives
      //console.log("this.drives", this.drives);
    })
  }

  setAuthResponse = (data) => {
    console.log('setAuthResponse')
    console.log(data)
    runInAction(async () => {
      this.driveAuthResponse = data
      this.driveRefreshToken = data.refresh_token
      this.driveAccessToken = data.access_token
      console.log({
        driveRefreshToken: data.refresh_token,
        driveAccessToken: data.access_token,
      })
      console.log('set driveAccessToken')
      //await this.getDrives({ refresh_token: data.refresh_token })
      //history.push('/drive')
      //console.log({'driveRefreshToken': this.driveRefreshToken})
      //console.log({'driveAccessToken': this.driveAccessToken})
    })
  }

  nameFolders = async ({ folderIds }) => {
    console.log('nameFolders')
    console.log({ folderIds })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    try {
      runInAction(() => {
        this.driveFolderIds = folderIds
      })

      this.countDriveFiles({ folderIds })

      const folders = await this.rootStore.apis.nameFolders({
        refresh_token: this.driveRefreshToken,
        folderIds,
        orgId,
        workspace: workspaceId,
        scope: SCOPE,
      })

      console.log(folders)
      runInAction(() => {
        this.driveFoldersNames = folders
      })
    } catch (err) {
      throw err
    }
  }

  countDriveFiles = async ({ folderIds }) => {
    try {
      const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

      var { count } = await this.rootStore.apis.countDriveFiles({
        refresh_token: this.driveRefreshToken,
        folderIds,
        orgId,
        workspaceId,
      })

      runInAction(() => {
        this.driveFilesCount = count
      })
    } catch (err) {
      console.log(err)
    }
  }

  setDriveCategories = async (driveCategories) => {
    runInAction(() => {
      this.driveCategories = driveCategories
    })
  }

  createDriveCategories = async () => {
    console.log('createDriveCategories')
    console.log({ driveCategories: this.driveCategories })

    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    try {
      //other category
      var otherTags = this.driveCategories.otherCategory?.[0]?.tags || []

      for (var tag of otherTags) {
        const newTag = await this.rootStore.apis.createTag({
          orgId,
          workspaceId,
          tag: tag.name,
          type: 'REGULAR',
        })
      }

      //name categories
      for (var category of this.driveCategories.categoriesArrays) {
        var categoryName = category.name
        var tags = category.tags

        for (var tag of tags) {
          const newTag = await this.rootStore.apis.createTag({
            orgId,
            workspaceId,
            tag: tag.name,
            type: 'REGULAR',
          })

          await this.addTagToCategory(newTag._id, categoryName)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  SyncChosenDirectories = async ({ folderIds }) => {
    runInAction(() => {
      this.syncFiles = true
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    const { userId } = this.rootStore.storage.get(USER_TOKEN)
    try {
      const syncJob = await this.rootStore.apis.SyncChosenDirectories({
        refresh_token: this.driveRefreshToken,
        folderIds,
        orgId,
        userId,
        workspace: workspaceId,
        scope: SCOPE,
      })

      runInAction(() => {
        this.syncJob = syncJob
      })

      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
      }
      let syncStatus = 'started'
      while (syncStatus !== 'finished') {
        await sleep(2000)
        const res = await this.rootStore.apis.SyncEnded(syncJob._id)
        runInAction(() => {
          this.syncJob = res
        })
        syncStatus = res.status
      }
      runInAction(() => {
        //return drive sync paramters to default
        this.syncFiles = false
        this.driveFolderIds = []
        this.driveFoldersNames = null
        this.driveFilesCount = 0
        this.driveCategories = []
        this.driveAuthResponse = {}
        this.driveRefreshToken = ''
        this.driveAccessToken = ''
        this.syncJob = null
      })

      //refresh files and tags
      this.updateFilesAndTags()

      return 'done'
    } catch (err) {
      //throw err

      runInAction(() => {
        this.syncFiles = false
      })
      this.updateFilesAndTags()
    }
  }

  SyncChosenFiles = async () => {
    runInAction(() => {
      this.syncFiles = true
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    var folderIds = this.driveFolderIds

    if (folderIds.length === 0) {
      console.log('No Folders are selected')
      runInAction(() => {
        this.syncFiles = false
      })
      return
    }

    try {
      const syncJob = await this.rootStore.apis.SyncChosenFiles({
        refresh_token: this.driveRefreshToken,
        folderIds,
        orgId,
        workspaceId,
        scope: SCOPE,
      })

      console.log({ syncJob })

      runInAction(() => {
        this.syncJob = syncJob
      })

      function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
      }
      let syncStatus = 'started'
      while (syncStatus === 'started') {
        await sleep(2000)
        const res = await this.rootStore.apis.SyncEnded(syncJob._id)
        runInAction(() => {
          this.syncJob = res
        })
        console.log({ sync: res })
        syncStatus = res.status
      }
      runInAction(() => {
        //return drive sync paramters to default
        this.syncFiles = false
        this.driveFolderIds = []
        this.driveFoldersNames = null
        this.driveFilesCount = 0
        this.driveCategories = []
        this.driveAuthResponse = {}
        this.driveRefreshToken = ''
        this.driveAccessToken = ''
        this.syncJob = null
      })

      //refresh files and tags
      this.updateFilesAndTags()
      return 'done'
    } catch (err) {
      runInAction(() => {
        //return drive sync paramters to default
        this.syncFiles = false
        this.driveFolderIds = []
        this.driveFoldersNames = []
        this.driveFilesCount = 0
        this.driveCategories = []
        this.driveAuthResponse = {}
        this.driveRefreshToken = ''
        this.driveAccessToken = ''
        this.syncJob = null
      })
    }
  }

  SplitTags = async (tags) => {
    runInAction(() => {
      this.syncFiles = true
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    try {
      await this.rootStore.apis.SplitTags({
        orgId,
        workspaceId,
        tags,
      })
      runInAction(() => {
        this.syncFiles = false
      })
      return 'done'
    } catch (err) {
      throw err
    }
  }

  MergeTags = async (tags) => {
    runInAction(() => {
      this.syncFiles = true
    })
    const { orgId } = this.rootStore.orgsWorkspacesStore
    try {
      await this.rootStore.apis.MergeTags({
        orgId: orgId,
        tags,
      })
      runInAction(() => {
        this.syncFiles = false
      })
      return 'done'
    } catch (err) {
      throw err
    }
  }

  renameTag = async ({ id, name, force = false }) => {
    const res = await this.rootStore.apis.renameTag({ id, name, force })

    runInAction(() => {
      //console.log("addFileTag")
      this.updateFilesAndTags()
    })
  }

  DeleteTags = async (ids) => {
    runInAction(() => {
      this.syncFiles = true
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    try {
      await this.rootStore.apis.DeleteTags({
        orgId: orgId,
        workspace: [workspaceId],
        ids: ids,
      })
      runInAction(() => {
        this.syncFiles = false
      })
      return 'done'
    } catch (err) {
      throw err
    }
  }

  getSplitSuggestions = async () => {
    runInAction(() => {
      this.gettingSuggestions = true
    })
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    try {
      let newTags = await this.getAllTags({})
      newTags = newTags.filter((tag) => tag.type === 'REGULAR')
      newTags.forEach((tag) => {
        tag.id = tag._id
      })
      const splitSuggestions = await this.rootStore.apis.getSplitSuggestions({
        orgId: orgId,
        workspace: workspaceId,
        tags: newTags,
      })
      runInAction(() => {
        this.gettingSuggestions = false
      })
      return splitSuggestions
    } catch (err) {
      throw err
    }
  }

  getMergeSuggestions = async () => {
    runInAction(() => {
      this.gettingSuggestions = true
    })
    try {
      let newTags = await this.getAllTags({})
      newTags = newTags.filter((tag) => tag.type === 'REGULAR')
      newTags.forEach((tag) => (tag.id = tag._id))
      const mergeSuggestions = await this.rootStore.apis.getMergeSuggestions({
        tags: newTags,
      })
      runInAction(() => {
        this.gettingSuggestions = false
      })
      return mergeSuggestions
    } catch (err) {
      throw err
    }
  }

  finishFileUpload = async (id) => {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms))

    var file = await this.rootStore.apis.getFile(id)

    if (file.thumbnailStatus !== 'complete') {
      for (let i = 0; i < 60 * 2 * 10; i++) {
        //wait 10 minutes
        console.log('checking thumbanil')
        await timer(1000)
        file = await this.rootStore.apis.getFile(id)
        if (file.thumbnailStatus == 'complete') {
          console.log('breaking')
          break
        }
      }
    }

    file = await this.rootStore.apis.finishFileUpload(id)
    for (let i = 0; i < 60 * 15; i++) {
      //wait 15 minutes
      console.log('checking analysis')
      await timer(1000)
      file = await this.rootStore.apis.getFile(id)
      if (file.status === 'complete') {
        console.log('completed')
        return file
      }
    }

    console.log("didn't finish in time, continue...")
    return file
  }

  ////////////////////////////////////////////////////////////////
  // COLLECTIONS   //////////////////////////////////
  ////////////////////////////////////////////////////////////////

  createCollection = async ({ name, description = '', type = 'regular' }) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const collection = await this.rootStore.apis.createCollection({
      orgId,
      workspaceId,
      name,
      description,
      type,
    })
    return collection
  }

  getAllCollections = async ({ skip = 0 }) => {
    console.log('getAllCollections')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const { collections, count } = await this.rootStore.apis.getCollections({
      orgId,
      workspaceId,
      skip,
      limit: this.COLLECTIONS_IN_PAGE,
    })

    runInAction(() => {
      if (!skip) {
        this.collections = collections
        this.collectionsCount = count
      } else {
        this.collections = this.collections.concat(collections)
        this.collectionsCount = count
      }
    })

    return collections
  }

  searchCollections = async ({ text, skip = 0, limit = 200 }) => {
    console.log('searchCollections')
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const { collections, count } = await this.rootStore.apis.getCollections({
      orgId,
      workspaceId,
      text,
      skip,
      limit,
    })

    runInAction(() => {
      this.collections = collections
      this.collectionsCount = count
      this.textToSearch = text
    })

    return collections
  }

  getCollection = async (id) => {
    this.textToSearch = ''
    this.textToPreSearch = ''
    const collection = await this.rootStore.apis.getCollection({ id })
    return collection
  }

  editCollection = async ({ id, name, description }) => {
    const collection = await this.rootStore.apis.editCollection({
      id,
      name,
      description,
    })
    return collection
  }

  updateCollectionCover = async ({ id, coverImage }) => {
    const collection = await this.rootStore.apis.updateCollectionCover({
      id,
      coverImage,
    })
    return collection
  }

  updateCollectionPrivacy = async ({ id, publicMode }) => {
    const collection = await this.rootStore.apis.updateCollectionPrivacy({
      id,
      publicMode,
    })
    return collection
  }

  updateCollectionLocation = async ({ id, location }) => {
    const collection = await this.rootStore.apis.updateCollectionLocation({
      id,
      location,
    })
    return collection
  }

  updateCollectionType = async ({ id, type }) => {
    const collection = await this.rootStore.apis.updateCollectionType({
      id,
      type,
    })
    return collection
  }

  updateCollectionFMP = async ({ id, isFMP }) => {
    const collection = await this.rootStore.apis.updateCollectionFMP({
      id,
      isFMP,
    })
    return collection
  }

  collectionsSimpleTagsToRules = ({ tagIds, personsIds, anyOrAll }) => {
    var rules = []

    if (anyOrAll === 'All') {
      rules.push({ tags: tagIds, persons: personsIds })
      return rules
    }
    if (anyOrAll === 'Any') {
      for (const tagId of tagIds) {
        rules.push({ tags: [tagId] })
      }
      for (const personId of personsIds) {
        rules.push({ persons: [personId] })
      }
      return rules
    }

    //else
    return rules
  }

  tagIdToTag = (tagId) => {
    const tag = this.allTags.find(
      (tag) => tag._id.toString() === tagId.toString()
    )
    return tag
  }

  personIdToPerson = (personId) => {
    const person = this.allPersons.find(
      (person) => person._id.toString() === personId.toString()
    )
    return person
  }

  collectionsRulesToSimpleTags = ({ rules, populateRules }) => {
    if (populateRules.length == 0) {
      return {}
    }
    if (populateRules.length == 1) {
      //let tags = rules[0].tags?.map(tagId => {return this.tagIdToTag(tagId)}).filter((tag) => tag !== undefined) || []
      //let persons = rules[0].persons?.map(personId => {return this.personIdToPerson(personId)}).filter((person) => person !== undefined) || []
      return {
        tags: populateRules[0].tags || [],
        persons: populateRules[0].persons || [],
        anyOrAll: 'all',
      }
    } else {
      var tags = []
      var persons = []
      for (const rule of populateRules) {
        if (rule.tags?.length > 0) {
          tags = tags.concat(rule.tags)
        }
        if (rule.persons?.length > 0) {
          persons = persons.concat(rule.persons)
        }
      }

      return { tags, persons, anyOrAll: 'any' }

      /*
      var tagIds = []
      var personsIds = []
      for (const rule of rules) {
        if(rule.tags?.length > 0){
          tagIds.push(rule.tags[0])
        }
        if(rule.persons?.length > 0){
          personsIds.push(rule.persons[0])
        }
      }
      var tags = tagIds.map(tagId => {return this.tagIdToTag(tagId)}).filter((tag) => tag !== undefined);
      var persons = personsIds.map(personId => {return this.personIdToPerson(personId)}).filter((person) => person !== undefined);
      return {tags, persons, anyOrAll: "any"}
      */
    }
  }

  updateCollectionRules = async ({ id, tagIds, personsIds, anyOrAll }) => {
    const rules = this.collectionsSimpleTagsToRules({
      tagIds,
      personsIds,
      anyOrAll,
    })
    console.log({ rules })
    const collection = await this.rootStore.apis.updateCollectionRules({
      id,
      rules,
    })
    return collection
  }

  runCollectionRules = async ({ id }) => {
    await this.rootStore.apis.runCollectionRules({ id })
  }

  deleteCollection = async (id) => {
    const collection = await this.rootStore.apis.deleteCollection({ id })
    this.resetCurrentCollection()
    return collection
  }

  addCollectionToFile = async ({ fileId, collectionId }) => {
    var file = await this.rootStore.apis.addCollections({
      fileId,
      collectionsIds: [collectionId],
    })
    this.updateFilesAndTags()

    runInAction(() => {
      this.currentFile.collections = file.collections
    })
  }

  removeCollectionToFile = async ({ fileId, collectionId }) => {
    var file = await this.rootStore.apis.removeCollections({
      fileId,
      collectionsIds: [collectionId],
    })
    this.updateFilesAndTags()
    runInAction(() => {
      this.currentFile.collections = file.collections
    })
  }

  bulkAddCollections = async ({ fileIds, collectionsIds }) => {
    await this.rootStore.apis.bulkAddCollections({ fileIds, collectionsIds })
    this.updateFilesAndTags()
  }

  bulkRemoveCollections = async ({ fileIds, collectionsIds }) => {
    await this.rootStore.apis.bulkRemoveCollections({ fileIds, collectionsIds })
    this.updateFilesAndTags()
  }

  onCollectionSelected = async (collection) => {
    //only one can be selected
    runInAction(async () => {
      this.selectedCollections = [collection]
      const collections = this.selectedCollections.map(({ _id }) => _id)
      await this.updateFilters({ collections })
    })
  }

  getCurrentCollection = async (id) => {
    const collection = await this.getCollection(id)
    await this.onCollectionSelected(collection)
    runInAction(() => {
      this.currentCollection = collection
      this.rootStore.orgsWorkspacesStore.collectionRole =
        collection.role || 'collectionViewer'
    })
  }

  checkIfCollectionIsPublic = async (id) => {
    const collection = await this.getCollection(id)
    return collection.publicMode
  }

  resetCurrentCollection = () => {
    runInAction(() => {
      this.currentCollection = null
      this.rootStore.orgsWorkspacesStore.collectionRole = ''
    })
  }

  inviteToCollection = async ({ collectionId, usernames, role }) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const invitedUsers = usernames.map((username) => {
      return { email: username, role }
    })

    const data = await this.rootStore.apis.inviteToCollection({
      id: collectionId,
      invitedUsers,
      orgId,
      workspaceId,
    })
    return data
  }

  removeFromCollection = async ({ collectionId, usernames }) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const data = await this.rootStore.apis.removeFromCollection({
      id: collectionId,
      removedUsers: usernames,
      orgId,
      workspaceId,
    })
    return data
  }

  updateUserRoleInCollection = async ({ collectionId, username, role }) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore

    const data = await this.rootStore.apis.updateUserRoleInCollection({
      id: collectionId,
      updatedUser: { email: username, role },
      orgId,
      workspaceId,
    })
    return data
  }

  ////////////////////////////////////////////////////////////////
  //  CATEGORIES   //////////////////////////////////
  ////////////////////////////////////////////////////////////////

  createCategory = async (name) => {
    const { orgId, workspaceId } = this.rootStore.orgsWorkspacesStore
    const catId = await this.rootStore.apis.createCategory({
      orgId,
      workspaceId,
      name,
    })
    return catId
  }

  // if deleteType is 'delete-all' then delete all tags in category
  deleteCategory = async (id, deleteType = 'misc') => {
    await this.rootStore.apis.deleteCategory({ id })
    if (deleteType === 'delete-all') {
      const tags = this.categories.categoriesArrays.find(
        (cat) => cat._id === id
      ).tags
      await this.DeleteTags(tags.map((tag) => tag._id))
    }
    this.updateFilesAndTags()
  }

  reorderCategory = async (id, order) => {
    await this.rootStore.apis.reorderCategory({ id, order })
    this.updateFilesAndTags()
  }

  resortCategory = async (id, sortBy, sortOrder) => {
    await this.rootStore.apis.resortCategory({ id, sortBy, sortOrder })
    this.getAllTags({})
  }

  addTagToCategory = async (tagId, name /* categoriesIds */) => {
    let categoryId = ''

    const cat = this.categories.categoriesArrays.find(
      (cat) => cat.name.toLowerCase() === name.toLowerCase()
    )

    if (cat) {
      categoryId = cat._id
      await this.rootStore.apis.addCategories({
        tagId,
        categoriesIds: [categoryId],
      })
    } else {
      categoryId = await this.createCategory(name)
      await this.rootStore.apis.addCategories({
        tagId,
        categoriesIds: [categoryId],
      })
    }
  }

  addTagToCategoryById = async (tagId, categoryId) => {
    await this.rootStore.apis.addCategories({
      tagId,
      categoriesIds: [categoryId],
    })
  }

  toggleTagCategory = async (tagId, catId) => {
    const fullCat = this.categories.categoriesArrays.find(
      (cat) => cat._id === catId
    )
    const tagInCat = fullCat.tags.find((tag) => tag._id === tagId)

    if (!tagInCat) {
      await this.rootStore.apis.addCategories({ tagId, categoriesIds: [catId] })
    } else {
      await this.rootStore.apis.removeCategories({
        tagId,
        categoriesIds: [catId],
      })
    }
  }

  renameCategory = async ({ id, name }) => {
    const res = await this.rootStore.apis.renameCategory({ id, name })
    this.updateFilesAndTags()
  }

  deleteFile = async (id) => {
    this.rootStore.apis.deleteFile(id).then(() => {
      this.getFilesFiltered()
      this.getTags({})
      this.getAllPersons({})
      this.rootStore.orgsWorkspacesStore.getOrgLimits()
    })
    this.deleteFileFromQ(id)
  }

  deleteBulkFiles = async (filesId) => {
    this.rootStore.apis.deleteBulkFiles(filesId).then(() => {
      this.getFilesFiltered()
      this.getTags({})
      this.getAllPersons({})
      this.rootStore.orgsWorkspacesStore.getOrgLimits()
    })
    filesId.forEach((id) => {
      this.deleteFileFromQ(id)
    })
  }

  mapCategories = ({ allCategories, allTags, tags }) => {
    const categoriesIds = allCategories?.map((category) =>
      category._id.toString()
    )
    const categoriesArrays = allCategories?.map((category) => {
      return { ...category, tags: [] }
    })
    const otherCategory = []

    //map tags to real count
    const tagMap = {}
    tags.map((tag) => {
      tagMap[tag._id.toString()] = tag.count
      return
    })

    allTags
      .filter((tag) => tag.type === 'REGULAR')
      .forEach((tag) => {
        if (tag.categories.length === 0) {
          let realCount = tagMap[tag._id.toString()] || 0
          otherCategory.push({ realCount, ...tag })
        } else {
          tag.categories.forEach((category) => {
            var index = categoriesIds.indexOf(category.toString())
            if (index >= 0) {
              let realCount = tagMap[tag._id.toString()] || 0
              categoriesArrays[index].tags.push({ realCount, ...tag })
            }
          })
        }
      })

    return { categoriesArrays, otherCategory }
  }

  setTranscription = (transcription) => {
    this.transcription = transcription
  }

  setTablePage = (newpage) => {
    runInAction(() => {
      this.currentFilesPage = newpage
    })
  }

  toggleFileViewMode = () => {
    runInAction(() => {
      if (this.fileViewMode === 'grid') {
        //set url query to table
        history.push({ search: '?view=table' })
      } else {
        //set url query to grid
        history.push({ search: '?view=grid' })
      }
      //intercom toggle lancher
      update('last_request_at', Date.now())

      this.fileViewMode = this.fileViewMode === 'table' ? 'grid' : 'table'
    })
  }

  setFileViewMode = (mode) => {
    runInAction(() => {
      this.fileViewMode = mode
    })
  }

  setMainView = (mode) => {
    runInAction(() => {
      this.mainView = mode
    })
  }

  setSettingsDialogView = (mode) => {
    runInAction(() => {
      this.settingsDialogView = mode
    })
  }
}

export default FilesStore
