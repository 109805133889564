import React, { useState, useEffect, useStyle } from 'react'
import { t, Trans } from '@lingui/macro'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { MyButton } from '../_MyComponents'
import Switch from '@material-ui/core/Switch'
import Snackbar from '@material-ui/core/Snackbar'

const ShareFile = ({ open, setOpen, fileType, fileId, updateFileType }) => {
  const [urlSnackbarOpen, setUrlSnackbarOpen] = useState(false)
  const [shareToggle, setShareToggle] = useState(null)

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggleShare = (e) => {
    setShareToggle((shareToggle) => !shareToggle)
    updateFileType({ fileId, type: shareToggle ? 'private' : 'public' })
  }

  const removeParamsFromUrl = () => {
    // Get the current URL
    const currentUrl = new URL(window.location.href)

    // List of parameters to remove
    const paramsToRemove = ['collections', 'collectionId']

    // Remove unwanted parameters
    paramsToRemove.forEach((param) => {
      currentUrl.searchParams.delete(param)
    })

    // Construct the new URL
    const newUrl = currentUrl.toString()

    // return the new url
    return newUrl
  }

  const copyUrl = () => {
    setUrlSnackbarOpen(true)
    navigator.clipboard.writeText(removeParamsFromUrl())
  }

  useEffect(() => {
    setShareToggle(fileType === 'private' ? false : true)
  }, [fileType])

  const closeUrlSnackbar = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setUrlSnackbarOpen(false)
  }

  const texts = {
    title: t`Share to web`,
    description: t`Anyone with the link will be able to view this file.`,
  }

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'xs'}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="form-dialog-title">{texts.title}</DialogTitle>
      <DialogContent>
        <div className="share-content-wrapper">
          <div className="content-item">
            <p className={'p'}>{texts.description}</p>
            <Switch
              className={'share-toggle'}
              checked={shareToggle}
              color="primary"
              onChange={handleToggleShare}
            />
            <div className={'copy-url ' + (!shareToggle ? 'disabled' : '')}>
              <MyButton
                className={'url'}
                disableRipple
                onClick={copyUrl}
                disabled={!shareToggle}
              >
                {removeParamsFromUrl()}
              </MyButton>
              <MyButton
                onClick={copyUrl}
                disabled={!shareToggle}
                variant="contained"
                color="secondary"
              >
                <Trans>Copy</Trans>
              </MyButton>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={urlSnackbarOpen}
                autoHideDuration={4000}
                onClose={closeUrlSnackbar}
                message="Link copied!"
              />
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <MyButton onClick={handleClose} color="primary" variant="contained">
          <Trans>Done</Trans>
        </MyButton>
      </DialogActions>
    </Dialog>
  )
}

export default ShareFile
