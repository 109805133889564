import React, { useState, useEffect, useStyle } from 'react'
import './CollectionFMPDialog.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook
import { t, Trans } from '@lingui/macro'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import ES from '../../assets/FMP-empty-state.jpg'
import UPLOAD from '../../assets/upload.svg'
import CAMERA from '../../assets/camera-icon.svg'
import { MyButton } from '../_MyComponents'
import { useDropzone } from 'react-dropzone'
import LinearProgress from '@mui/material/LinearProgress'
import IMAGE from '../../assets/image.svg'

const CollectionFMPDialog = observer(
  ({
    // from parent
    open,
    setOpen,
    setShowingFMP,
    setFMPPerson,
    setFMPAvatar,
  }) => {
    const { filesStore } = useStore()

    const {
      simpleUploadFileToS3,
      searchFaceFromImage,
      filesInQ,
      isUploadFinished,
      cleanUploadQueue,
    } = filesStore

    const [newFileResult, setNewFileResult] = useState(null)
    const [progress, setProgress] = useState(0)
    const [uploadError, setUploadError] = useState(false)

    const formatFileSize = (size) => {
      const newSize = size / 1000
      if (newSize < 1000) {
        return `${newSize.toFixed(2)}KB`
      } else {
        return `${(newSize / 1000).toFixed(2)}MB`
      }
    }

    const handleClose = () => {
      setOpen(false)
    }

    /////////////////////
    ///  FIND MY PHOTOS
    /////////////////////

    const onDrop = (acceptedFiles) => {
      simpleUploadFileToS3(acceptedFiles[0]).then((res) => {
        setNewFileResult(res)
      })
    }

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
      onDrop,
    })

    const handleUpload = async () => {
      const fileInput = document.getElementById('upload-input')
      const selectedFiles = [...fileInput.files]

      const res = await simpleUploadFileToS3(selectedFiles[0])
      setFMPAvatar(res)

      const person = await searchFaceFromImage(res.s3location)

      if (!!person.error) {
        setUploadError(person.error)
      } else {
        setFMPPerson(person)
        setOpen(false)
        setShowingFMP(true)
      }
      cleanUploadQueue()
    }

    const files = filesInQ.map((file, idx) => (
      <div className="uploading-file-container" key={file.name}>
        <img src={IMAGE} />
        <div className="content">
          <div className="text">
            <p className="file-name light">{file.name}</p>
            <p className="file-size">{formatFileSize(file.size)}</p>
          </div>
          <LinearProgress
            variant="determinate"
            style={{ color: '#FBC02D' }}
            color="inherit"
            value={isUploadFinished ? 100 : progress}
          />
        </div>
      </div>
    ))

    /////////////////////
    ///  main component
    /////////////////////

    return (
      <Dialog
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        className="FMP-container"
      >
        <DialogTitle id="customized-dialog-title" className="FMP-title">
          <Trans>Find my photos</Trans>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="FMP-content">
          <div className="FMP-section hero">
            <img src={ES} alt="background"></img>
          </div>

          <div className="FMP-section guide">
            {!uploadError && (
              <p className="p">
                <Trans>
                  Upload or take a photo of yourself, so we can find your images
                </Trans>
              </p>
            )}
            {uploadError && <p className="p error">{uploadError}</p>}
          </div>

          <div className={'dropzone-area'}>
            <div className="flex-row">
              <img src={UPLOAD} alt="upload" />
              <div className="dropzone-text">
                <p className="p">
                  <Trans>Upload photo or drag and drop here</Trans>
                </p>
                <p className="p light">
                  <Trans>JPG or PNG (no size limit)</Trans>
                </p>
              </div>
            </div>
            <label htmlFor="upload-input">
              <MyButton
                variant="contained"
                component="span"
                color="primary"
                startIcon={<img src={CAMERA} style={{ width: '24px' }} />}
                sx={[
                  {
                    '.MuiButton-startIcon': {
                      margin: '0',
                    },
                  },
                ]}
              >
                <Trans>Take a photo</Trans>
              </MyButton>
            </label>
          </div>

          <div className="FMP-section disclaimer">
            <div className="uploading-files-container">{files}</div>
          </div>

          <div className="FMP-section disclaimer">
            <p className="h3">
              <Trans>
                The photo will be permanently deleted after the search
              </Trans>
            </p>
          </div>
        </DialogContent>
        <DialogContent>
          <Divider flexItem style={{ width: '100%', height: '1px' }} />
        </DialogContent>
        <input
          accept="image/png, image/jpeg"
          capture="user"
          style={{ display: 'none' }}
          id="upload-input"
          name="file"
          type="file"
          onClick={(e) => {
            e.target.value = ''
          }}
          onChange={(e) => handleUpload(e.target.files)}
        />
      </Dialog>
    )
  }
)

export default CollectionFMPDialog
