import React, { useState, useEffect, useRef, useMemo } from 'react'
import './omnisearch.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { t, Trans } from '@lingui/macro'
import { i18n } from '@lingui/core'

import Avatar from '@material-ui/core/Avatar'
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp'
import COLORS from '../Colors/colors'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import SearchIcon from '@material-ui/icons/Search'
import { EXT } from '../SearchByFileType/filesExt'
import Paper from '@mui/material/Paper'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import IconButton from '@material-ui/core/IconButton'
import FiltersBar from '../FiltersBar/FiltersBar.js'
import { MyButton } from '../_MyComponents'
import CAMERA from '../../assets/find-my-photo-icon.svg'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'

import Filters from './Filters/Filters.js'

const MySearch = observer((props) => {
  const { authenticationStore } = useStore()
  const { userState } = authenticationStore

  const [placeholder, setPlaceholder] = useState('')

  useEffect(() => {
    const placeholders = [
      t`Search for assets by what's in them or by tags`,
      t`You can search in any language`,
      t`Try searching for image style or camera angles`,
      t`Try searching for activities or emotions`,
      t`Use search together with tags and filters`,
    ]
    setPlaceholder(
      placeholders[Math.floor(Math.random() * placeholders.length)]
    )
  }, [userState?.language])

  const ref = useRef(null)

  const hideFilters = props.hideFilters

  return (
    <div className={'global-input'}>
      <div className="input-body">
        <SearchIcon />
        <input
          placeholder={placeholder}
          type="text"
          autoComplete="off"
          onClick={props.onClick}
          onChange={props.onChange}
          value={props.value}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          id="search-input"
          ref={ref}
          inputMode="search"
        />
      </div>

      {props.closeIcon && (
        <IconButton
          size="small"
          style={{
            color: 'rgba(0,0,0,.2)',
            marginRight: '16px',
          }}
          onClick={() => {
            ref.current.value = ''
            props.onReset()
          }}
        >
          <HighlightOffIcon fontSize="inherit" />
        </IconButton>
      )}
      {props.isFMP && (
        <MyButton
          onClick={() => props.handleFMPInSearch()}
          size="small"
          variant="contained"
          component="span"
          color="primary"
          startIcon={
            window.mobileCheck() ? null : (
              <img src={CAMERA} style={{ width: '24px' }} />
            )
          }
          style={{
            borderRadius: '100px',
            backgroundColor: '#E9E9E7',
            flexShrink: 0,
          }}
          sx={[{ '.MuiButton-startIcon': {} }]}
        >
          <Trans>Find My Photos</Trans>
        </MyButton>
      )}
      {!window.mobileCheck() &&
        !hideFilters &&
        props.isAllowed(RULES.SHOW_FILTERS) && (
          <Filters handleShowAll={props.handleShowAll} />
        )}
    </div>
  )
})

const OmniSearch = observer(
  ({
    // from parent
    previewDialog,
    hideFilters = false,
    isFMP = false, // find my photos from CollectionHeader
    handleFMPInSearch,
  }) => {
    const { authenticationStore, filesStore, orgsWorkspacesStore } = useStore()

    const { isAnonymous } = authenticationStore

    const {
      onSearchChanged,
      preSearchResults,
      textToPreSearch,
      onTagSelected,
      onPersonSelected,
      selectedUsers,
      onUserSelected,
      addToFileViewed,
      selectedExt,
      onExtSelected,
      selectedFileType,
      getCurrentCollection,
      currentCollection,
      onPreSearchChanged,
      mainView: view,
      setMainView: setView,
      searchCollections,
    } = filesStore

    const { workspaceType, isAllowed } = orgsWorkspacesStore

    const [data, setData] = useState({})
    const [isResults, setIsResults] = useState(false)
    const [isOmniOpen, setIsOmniOpen] = useState(false)

    const handleShowAll = () => {
      setView('persons')
      setIsOmniOpen(false)
      document.querySelector('.search-container input').blur()
    }

    useEffect(() => {
      if (textToPreSearch) {
        setData(preSearchResults)
        setIsResults(
          preSearchResults?.files?.length ||
            preSearchResults?.tags?.length ||
            preSearchResults?.people?.length ||
            preSearchResults?.exts?.length ||
            preSearchResults?.collections?.length ||
            preSearchResults?.persons?.length
        )
      } else {
        setData({})
        setIsResults(false)
      }
    }, [
      textToPreSearch,
      preSearchResults,
      preSearchResults?.files,
      preSearchResults?.tags,
      preSearchResults?.people,
      preSearchResults?.exts,
      preSearchResults?.collections,
      preSearchResults?.persons,
    ])

    //TODO: update with onFileTypeSelected
    const filterByType = (fileType) => {
      if (selectedFileType === fileType) {
        onExtSelected([], '')
      } else {
        onExtSelected(EXT[fileType], fileType)
      }
    }

    const filterByExt = (ext) => {
      console.log({ selectedExt })
      if (selectedExt.includes(ext)) {
        onExtSelected([])
      } else {
        onExtSelected([ext])
      }
      setIsOmniOpen(false)
      onPreSearchChanged({ text: '' })
    }

    const onCollectionSelected = (collection) => {
      setView('collections')
      getCurrentCollection(collection._id)
      setIsOmniOpen(false)
      onPreSearchChanged({ text: '' })
    }

    const openFile = (id) => {
      previewDialog(true)
      addToFileViewed(id)
      setIsOmniOpen(false)
      onPreSearchChanged({ text: '' })
    }

    const selectTag = (tag) => {
      onTagSelected(tag)
      setIsOmniOpen(false)
      onPreSearchChanged({ text: '' })
    }

    const selectPerson = (person) => {
      onPersonSelected(person)
      setIsOmniOpen(false)
      onPreSearchChanged({ text: '' })
    }

    const files = data?.files?.slice(0, 3).map((file, i) => {
      return (
        <SearchResult
          key={i}
          text={file.name}
          fileType={file.ext ? file.ext : 'docs'}
          activationItem={file}
          activationFn={openFile}
        />
      )
    })

    const tags = data?.tags
      ?.slice()
      ?.sort((a, b) => (a.count > b.count ? -1 : 1))
      .slice(0, 3)
      .map((tag, i) => {
        return (
          <SearchResult
            key={i}
            chip={tag.name}
            count={tag.count}
            activationItem={tag}
            activationFn={selectTag}
          />
        )
      })

    const collections = data?.collections?.slice(0, 3).map((collection, i) => {
      return (
        <SearchResult
          key={i}
          text={collection.name}
          count={collection.count}
          activationItem={collection}
          activationFn={onCollectionSelected}
        />
      )
    })

    const people = data?.people?.slice(0, 3).map((user, i) => {
      return (
        <SearchResult
          key={i}
          avatar={user.name}
          text={user.name}
          activationFn={() => {
            onUserSelected(
              selectedUsers.find((user) => user.username === user.username)
                ? selectedUsers.filter(
                    (user) => user.username !== user.username
                  )
                : selectedUsers.concat(user)
            )
            setIsOmniOpen(false)
            onPreSearchChanged({ text: '' })
          }}
        />
      )
    })

    const persons = data?.persons?.slice(0, 3).map((person, i) => {
      return (
        <SearchResult
          key={i}
          avatar={person.name}
          src={person.defaultFaceLocation}
          text={person.name}
          activationFn={() => selectPerson(person)}
        />
      )
    })

    const exts = data?.exts?.slice(0, 3).map((ext, i) => {
      return (
        <SearchResult
          key={i}
          fileType={EXT['links'][0]}
          text={ext}
          activationItem={ext}
          activationFn={filterByExt}
        />
      )
    })

    /*
  const keywords = data?.keywords?.slice(0, 3).map((keyword, i) => {
    return (<SearchResult key={i} chip={keyword.name} count={keyword.count} activationItem={keyword} activationFn={onKeywordSelected}/>)
  })

    This was in line 122
        {!!keywords?.length && <div className={'search-results-section'}>
        <div className={'title-2'}>KEYWORDS</div>
        {keywords}
      </div>}

  */

    const onSearch = () => {
      onSearchChanged()
      setIsOmniOpen(false)
      onPreSearchChanged({ text: '' })
    }

    const activateSearch = (e) => {
      if (e.key === 'Enter') {
        if (view === 'tags') {
          setView('home')
        } else if (view === 'collections' && currentCollection === null) {
          searchCollections({ text: textToPreSearch })
          setIsOmniOpen(false)
          return
        }
        onSearchChanged(e.target.value)
        // resets search on enter
        setIsOmniOpen(false)
        onPreSearchChanged({ text: '' })
        setData({})
      }
    }

    return (
      <div className={'search-container'}>
        <MySearch
          value={textToPreSearch}
          hideFilters={hideFilters}
          handleShowAll={handleShowAll}
          closeIcon={textToPreSearch}
          onReset={() => onPreSearchChanged({ text: '' })}
          //autoComplete="off"
          onFocus={() => setIsOmniOpen(true)}
          onBlur={() => setIsOmniOpen(false)}
          onChange={(e) => {
            onPreSearchChanged({ text: e.target.value })
            setIsOmniOpen(true)
          }}
          onKeyDown={(e) => {
            activateSearch(e)
          }}
          className={'search-input'}
          isFMP={isFMP}
          handleFMPInSearch={handleFMPInSearch}
          isAllowed={isAllowed}
          RULES={RULES}
        />

        {window.mobileCheck() &&
          !hideFilters &&
          isAllowed(RULES.SHOW_FILTERS) && (
            <Filters handleShowAll={handleShowAll} />
          )}

        <Paper
          variant="elevation"
          className={'omnisearch-container ' + (!!isOmniOpen && 'omni-open')}
          onMouseDown={(e) => e.preventDefault()}
        >
          {!textToPreSearch && (
            <EmptyState state={'empty-search'} handleShowAll={handleShowAll} />
          )}

          {textToPreSearch && !isResults && <EmptyState state={'no-results'} />}

          {!!files?.length && (
            <div className={'search-results-section'}>
              <div className={'h2'}>
                <Trans>Assets</Trans>
              </div>
              {files}
            </div>
          )}

          {!!tags?.length && (
            <div className={'search-results-section'}>
              <div className={'h2'}>
                <Trans>Tags</Trans>
              </div>
              {tags}
            </div>
          )}

          {!!collections?.length && (
            <div className={'search-results-section'}>
              <div className={'h2'}>
                <Trans>Collections</Trans>
              </div>
              {collections}
            </div>
          )}

          {!!people?.length && (
            <div className={'search-results-section'}>
              <div className={'h2'}>
                <Trans>Uploaded by</Trans>
              </div>
              {people}
            </div>
          )}

          {!!persons?.length && (!isAnonymous || workspaceType == 'public') && (
            <div className={'search-results-section'}>
              <div className={'h2'}>
                <Trans>People in images</Trans>
              </div>
              {persons}
            </div>
          )}

          {!!exts?.length && (
            <div className={'search-results-section'}>
              <div className={'h2'}>
                <Trans>File types</Trans>
              </div>
              {exts}
            </div>
          )}

          <Divider />
          {!!isResults && (
            <MenuItem onClick={onSearch} className={'go-to-results'}>
              <div className="flex-row">
                <SearchIcon />
                <Trans>See all files</Trans>
              </div>
              <div style={{ color: '#bdbdbd' }}>
                <Trans>Press ENTER</Trans>
              </div>
            </MenuItem>
          )}
        </Paper>
      </div>
    )
  }
)

export default OmniSearch

////////////////

const SearchResult = (data) => {
  const {
    avatar,
    src,
    fileType,
    text,
    chip,
    count,
    activationItem,
    activationFn,
  } = data

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(([part]) => part)
      .join('')
  }

  const handleClick = () => {
    activationFn(activationItem)
  }

  return (
    <MenuItem className={'search-results-row'} onClick={handleClick}>
      {avatar && (
        <Avatar src={src} alt={avatar} className={'avatar'}>
          {avatar && getInitials(avatar)}
        </Avatar>
      )}
      {fileType && (
        <InsertDriveFileSharpIcon
          className="file-type-icon"
          style={{ color: COLORS[fileType] }}
        />
      )}
      {text && (
        <div className={'result-text'}>
          {text + (count ? ' (' + count + ')' : '')}
        </div>
      )}
      {chip && (
        <Chip
          key={chip}
          label={chip + ' (' + count + ')'}
          className={'dark-chip'}
          size="small"
        />
      )}
    </MenuItem>
  )
}

////////////////

const EmptyState = ({ state, handleShowAll }) => {
  return (
    <div className={'empty-search filters-wrapper'}>
      {state === 'empty-search' ? (
        <FiltersBar handleShowAll={handleShowAll} />
      ) : (
        <div>
          <Trans>No results found...</Trans>
        </div>
      )}
    </div>
  )
}
