import { makeAutoObservable } from 'mobx'
import logger from '../../services/logger'
import { dispatch, subscribe } from '../../services/pubsub'
import Actions from '../../util/actions'

class RootStore {
  constructor({
    apis,
    router,
    storage,
    window,
    TOKEN_KEYWORD,
    authenticationStore,
    thirdPartyStore,
    filesStore,
    registerStore,
    dropboxImportStore,
    orgsWorkspacesStore,
  }) {
    this.apis = apis
    this.router = router
    this.storage = storage
    this.window = window
    this.TOKEN_KEYWORD = TOKEN_KEYWORD

    this.authenticationStore = authenticationStore
    this.thirdPartyStore = thirdPartyStore
    this.filesStore = filesStore
    this.registerStore = registerStore
    this.dropboxImportStore = dropboxImportStore
    this.orgsWorkspacesStore = orgsWorkspacesStore

    // Assign rootStore to each store
    this.authenticationStore.rootStore = this
    this.thirdPartyStore.rootStore = this
    this.filesStore.rootStore = this
    this.registerStore.rootStore = this
    this.dropboxImportStore.rootStore = this
    this.orgsWorkspacesStore.rootStore = this

    this.width = 0
    this.height = 0

    makeAutoObservable(this)

    this.appMounted = this.appMounted.bind(this)
    this.appWillUnmount = this.appWillUnmount.bind(this)
    this.logout = this.logout.bind(this)
    this.register = this.register.bind(this)
    this.resize = this.resize.bind(this)
    this.loggedIn = this.loggedIn.bind(this)

    subscribe(Actions.USER_LOGGED_IN, this.loggedIn)
    router.init()
  }

  async loggedIn() {
    //await this.authenticationStore.getUserState();
    this.orgsWorkspacesStore.reset()
    this.orgsWorkspacesStore.verifyOrganization()
  }

  logout() {
    //Notify others
    localStorage.removeItem('USER_TOKEN')
    //redundancy?
    //this.storage.clear();
    this.orgsWorkspacesStore.reset()
  }

  register() {}

  resize() {
    const width = window.innerWidth
    const height = window.innerHeight
    this.width = width
    this.height = height
    dispatch(Actions.WINDOW_RESIZED, { width, height })
  }

  appMounted() {
    window.addEventListener('resize', this.resize)
    this.resize()
    dispatch(Actions.APPLICATION_MOUNTED, this.window)
    console.log('appMounted, Dispatched Actions.GET_CURRENT_USER')
    dispatch(Actions.GET_CURRENT_USER)
    logger.log('App mounted')
    console.log('App mounted')
  }

  // eslint-disable-next-line class-methods-use-this
  appWillUnmount() {
    dispatch(Actions.APPLICATION_UNMOUNTED)

    // Put here code that needs to know when the app will unmount
    logger.log('App will unmount')
  }
}

export default RootStore
