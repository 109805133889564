import React, { useState, useEffect } from 'react'
import { t, Trans } from '@lingui/macro'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: '10px',
    minWidth: '100%',
    boxSizing: 'border-box',
  },
  comment: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontFamily: 'Ubuntu sans-serif',
    color: '#757575',
  },
  avatar: {
    height: '30px',
    width: '30px',
  },
}))

const Comment = ({ comment, avatar, toggleDialog, editable }) => {
  const { name, image } = avatar
  const classes = useStyles()
  const [parsedComment, setParsedComment] = useState('')
  const [parsedDate, setParsedDate] = useState('')

  const parseDate = async () => {
    const res = moment(
      moment(comment.createdAt).subtract(1, 'seconds')
    ).fromNow()
    setParsedDate(res)
  }
  const parseComment = async () => {
    let options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase()
        const data = entity.getData()
        if (entityType === 'mention') {
          return {
            element: 'span',
            attributes: { class: 'mentionBefore' },
            style: {
              color: '#00ABEB',
              borderRadius: '5px',
              padding: '3px',
              fontWeight: 'bold',
            },
          }
        } else if (entityType === '#mention') {
          return {
            element: 'a',
            attributes: {
              href: data.mention.link,
            },
            style: {
              color: 'green',
            },
          }
        }
      },
    }
    const res = stateToHTML(
      convertFromRaw(JSON.parse(comment.content)),
      options
    )
    setParsedComment(res)
  }

  useEffect(() => {
    parseComment()
    parseDate()
  }, [])

  return (
    <div className={classes.root}>
      <Tooltip
        title={name}
        placement="bottom-start"
        enterDelay={500}
        enterNextDelay={500}
      >
        <Avatar className={classes.avatar}>
          <AccountCircleIcon />
        </Avatar>
      </Tooltip>
      <div className={'commentContainer'}>
        <div className={'comment-owner'}>
          <Tooltip
            title={name}
            arrow
            placement="top"
            enterDelay={500}
            enterNextDelay={500}
          >
            <p className={'commentName'}>{name}</p>
          </Tooltip>
        </div>
        <div className={'commentBackground'}>
          <div
            className={classes.comment + ' commentformat'}
            dangerouslySetInnerHTML={{ __html: parsedComment }}
          ></div>
        </div>
        <div className="comment-actions">
          <Tooltip
            title={moment(
              moment(comment.createdAt).subtract(1, 'seconds')
            ).format('MMMM Do YYYY, h:mm a')}
            arrow
            placement="top"
            enterDelay={500}
            enterNextDelay={500}
          >
            <p className="commentDate">{parsedDate}</p>
          </Tooltip>
          {editable && (
            <p className="delete-comment" onClick={toggleDialog}>
              <Trans>delete</Trans>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Comment
