import React from 'react'
import { AddCardLink } from './MyGlobalStyle'
import ADD from '../../assets/add.svg'
import './TagsManagment.scss'
import { t, Trans } from '@lingui/macro'

export default ({ onClick, t, laneId }) => {
  return (
    <AddCardLink onClick={onClick}>
      <img src={ADD} className="addButton" alt="add tag button" />
      <p className="card-link-text">
        <Trans>Add a Tag</Trans>
      </p>
    </AddCardLink>
  )
}
