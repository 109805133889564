import React, { useState, useEffect } from 'react'
import './Collections.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook
import { t, Trans } from '@lingui/macro'

import { MyButton, MyKeyword } from '../_MyComponents'
import CreateCollectionDialog from '../CreateCollectionDialog/CreateCollectionDialog.js'
import Collection from '../Collection/Collection.js'
import COLLECTIONS from '../../assets/collections-icon.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import CheckIcon from '@mui/icons-material/Check'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import InfiniteScroll from 'react-infinite-scroll-component'
import Dialog from '@material-ui/core/Dialog'
import ActionDialog from '../ActionDialog/ActionDialog.js'

const Collections = observer(
  ({
    // from parent
    setIsSettingsDialogOpen,
  }) => {
    const { filesStore, orgsWorkspacesStore } = useStore()

    const {
      collections,
      getAllCollections,
      collectionsCount,
      textToSearch,
      searchCollections,
    } = filesStore

    const { isAllowed, orgPlanLimits } = orgsWorkspacesStore

    const [showDialog, setShowDialog] = useState(false)
    const [introVideo, setIntroVideo] = useState(false)
    const [collectionsLimitDialog, setCollectionsLimitDialog] = useState(false)

    const location = useLocation()
    const historyDOM = useHistory()

    const closeDialog = () => {
      setShowDialog(false)
    }

    const createButton = (
      <MyButton
        onClick={() => handleCreateCollection()}
        className="es-card-button"
        variant="contained"
        color="primary"
      >
        <Trans>+ Create Collection</Trans>
      </MyButton>
    )

    useEffect(() => {
      if (collections) {
        const queryParams = new URLSearchParams(location.search)

        if (!queryParams.has('collections')) {
          queryParams.append('collections', 'open')
          historyDOM.replace({
            search: queryParams.toString(),
          })
        }
      }
    }, [collections])

    const introPopup = (
      <Dialog
        onClose={() => setIntroVideo(false)}
        maxWidth={'md'}
        aria-labelledby="simple-dialog-title"
        open={introVideo}
      >
        <div className="walkthrough-container">
          <div className="dialog-content">
            <div className="dialog-text">
              <div className="h1">
                <Trans>Share your assets using collections</Trans>
              </div>
              <div>
                <div className="p">
                  <CheckIcon fontSize="small" />
                  <Trans>Choose assets to share with specific people</Trans>
                </div>
                <div className="p">
                  <CheckIcon fontSize="small" />
                  <Trans>Create publically available galleries</Trans>
                </div>
                <div className="p">
                  <CheckIcon fontSize="small" />
                  <Trans>
                    Customize the look & feel for a branded experience
                  </Trans>
                </div>
              </div>
              <MyButton
                variant="contained"
                color="primary"
                onClick={() => setIntroVideo(false)}
                sx={{ alignSelf: 'flex-end' }}
                endIcon={<ArrowForwardIcon />}
              >
                <Trans>Let's start</Trans>
              </MyButton>
            </div>
            <div className="dialog-video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/8Xizzr8FyWU"
                title="YouTube video player"
                frameorder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </Dialog>
    )

    const nextPage = () => {
      console.log('nextPage')
      getAllCollections({ skip: collections.length })
    }

    const handleCreateCollection = () => {
      if (
        orgPlanLimits?.collectionCountLimit > 0 &&
        collectionsCount >= orgPlanLimits?.collectionCountLimit
      ) {
        setCollectionsLimitDialog(true)
      } else {
        setShowDialog(true)
      }
    }

    return (
      <div className="collections-container" id="scollableCollections">
        {/* no collections */}
        {collectionsCount === 0 && (
          <div className="collections-empty-state">
            <img src={COLLECTIONS} />
            <div className="h1">
              <Trans>No Collections here yet</Trans>
            </div>
            <div className="h2 light">
              <Trans>
                Use collections to share groups of assets with your team or
                publically
              </Trans>
            </div>
            {!!isAllowed(RULES.CREATE_COLLECTION) && createButton}
            {
              <p
                className="p light"
                style={{ cursor: 'pointer' }}
                onClick={() => setIntroVideo(true)}
              >
                <Trans>Watch explainer video</Trans>
              </p>
            }
          </div>
        )}
        {/* collections */}
        {collectionsCount > 0 && (
          <div className="collections-content-container">
            <div className="collections-wrapper">
              <div className="collections-header">
                <h1 className="h1">
                  <Trans>Collections ({collectionsCount || 0})</Trans>
                </h1>
                {!!isAllowed(RULES.CREATE_COLLECTION) && createButton}
              </div>
              {textToSearch && (
                <div className="collections-search">
                  <MyKeyword
                    text={textToSearch}
                    selected
                    onClick={() => searchCollections('')}
                  />
                </div>
              )}
            </div>
            <div className="collections-content">
              <InfiniteScroll
                className="collections-grid"
                dataLength={collections?.length}
                scrollThreshold={0.5}
                next={nextPage}
                hasMore={collectionsCount > collections?.length}
                loader={
                  <p className="infinite-scroll-message">
                    <Trans>Loading...</Trans>
                  </p>
                }
                /*endMessage={
								collections?.length > COLLECTIONS_IN_PAGE && (
									<p className="infinite-scroll-message">
										Yay! You have seen it all
									</p>
								)
							}*/
                scrollableTarget="scollableCollections"
                style={{ overflow: 'hidden' }}
              >
                {collections.map((collection, idx) => (
                  <Collection fullCollection={collection}></Collection>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        )}
        <div></div>
        <CreateCollectionDialog
          isOpen={showDialog}
          close={closeDialog}
          isMobile={window.mobileCheck()}
        />
        {introPopup}
        <ActionDialog
          actionName={t`Go to Settings`}
          action={() => setIsSettingsDialogOpen('account')}
          cancel={() => setCollectionsLimitDialog(false)}
          question={t`Plan limit reached`}
          comment={t`Your current plan is limited to ${orgPlanLimits.collectionCountLimit} collections. Upgrade to create more.`}
          isOpen={collectionsLimitDialog}
        />
      </div>
    )
  }
)

export default Collections
