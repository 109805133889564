import React, { useState } from 'react'
import Board from 'react-trello'
import { t, Trans } from '@lingui/macro'

import TagCard from './TagCard/TagCard'
import MyLaneHeader from './MyLaneHeader/MyLaneHeader'
import MyAddCardLink from './MyAddCardLink'
import MyNewLaneSection from './MyNewLaneSection'
import MyNewCardForm from './MyNewCardForm'
import MyNewLane from './MyNewLaneForm'
import './TagsManagment.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import {
  GlobalStyle,
  Section,
  BoardWrapper,
  ScrollableLane,
} from './MyGlobalStyle'
import ActionDialog from '../ActionDialog/ActionDialog.js'
import { sortCategory } from '../../util/tags'

const TagsManagment = observer(
  ({
    // from parent
    walkthrough,
    setWalkthrough,
  }) => {
    const { filesStore } = useStore()

    const {
      getAllTags,
      DeleteTags,
      renameTag,
      toggleTagCategory,
      addTagToCategoryById,
      categories,
      renameCategory,
      createCategory,
      deleteCategory,
      addOrgTag,
      reorderCategory,
      resortCategory,
    } = filesStore

    const [showDialogDelete, setShowDialogDelete] = useState(false)
    const [showDialogRename, setShowDialogRename] = useState(false)
    const [showDialogMisc, setShowDialogMisc] = useState(false)
    const [deleteCardId, setDeleteCardId] = useState(null)
    const [renameCardId, setRenameCardId] = useState(null)
    const [renameName, setRenameName] = useState(null)

    const dismissWalkthrough = () => {
      setWalkthrough({ ...walkthrough, tagMgmt: false })
    }

    const components = {
      GlobalStyle,
      Section,
      BoardWrapper,
      ScrollableLane,
      Card: TagCard,
      LaneHeader: MyLaneHeader,
      AddCardLink: MyAddCardLink,
      NewLaneSection: MyNewLaneSection,
      NewCardForm: MyNewCardForm,
      NewLaneForm: MyNewLane,
      //Loader,
      //LaneFooter,
    }

    const OTHER_CATEGORY_LANE_ID = '1'
    const WALKTHROUGH_CATEGORY_LANE_ID = '0'
    const FORBIDDEN_IDS = [OTHER_CATEGORY_LANE_ID, WALKTHROUGH_CATEGORY_LANE_ID]

    const { categoriesArrays = [], otherCategory = [] } = categories

    var walkthroughLane = {
      id: WALKTHROUGH_CATEGORY_LANE_ID,
      title: t`Tutorial`,
      cards: [
        { walkthrough: true, dismissWalkthrough, id: '0', title: 'none' },
      ],
      order: -2,
      isRemovable: false,
      disallowAddingCard: true,
      style: {
        backgroundColor: 'rgb(253, 244, 230)',
        color: '#fff',
        width: 280,
      },
    }

    /*
    categoriesArrays = [
        {
            _id,
            name,
            order,
            tags : [
                {
                    _id,
                    name,
                    count
                }
            ]
        }
    ]
    */

    //map categoriesArrays to board layout
    var data = {}

    //map other tags:
    var otherLane = {
      id: OTHER_CATEGORY_LANE_ID,
      title: 'Miscellaneous',
      cards: [],
      order: -1,
      isRemovable: false,
    }
    otherLane.cards = otherCategory
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => b.count - a.count)
      .map((tag) => {
        const { _id, name, count } = tag
        return { id: _id, title: name, count }
      })
      .slice(0, 100)

    //map real Categories
    var realCategoriesLanes = categoriesArrays.map((category) => {
      const { _id, name, order, sortOrder, sortBy } = category

      /* var sortArgument = {}
        if(sortBy == "count"){
            sortArgument = { "count": sortOrder, "name": 'asc' }
        }
        else if(sortBy == "name"){
            sortArgument = { "name": sortOrder, "count": 'desc' }
        }
        else{
            sortArgument = { "count": 'desc', "name": 'asc' }
        } */

      const onDeleteCategory = async (id, deleteType) => {
        if (
          id != OTHER_CATEGORY_LANE_ID &&
          id != WALKTHROUGH_CATEGORY_LANE_ID
        ) {
          //not other category
          await deleteCategory(id, deleteType)
          refreshCategories()
        }
        //TODO: disable lane delete
      }

      const categoryTagsSorted = sortCategory({ category })

      const cards = categoryTagsSorted.map((tag) => {
        const { _id, name, count } = tag
        return { id: _id, title: name, count }
      })

      return {
        id: _id,
        title: name,
        cards,
        sortOrder,
        sortBy,
        resortCategory,
        onDeleteCategory,
        order: Number(order['$numberDecimal']),
        isRemovable: true,
      }
    })

    //make sure it's always on the most left
    otherLane.order = realCategoriesLanes.length
      ? realCategoriesLanes[0].order - 1
      : -1

    if (walkthrough.tagMgmt) {
      data.lanes = [walkthroughLane, otherLane].concat(realCategoriesLanes)
    } else {
      data.lanes = [otherLane].concat(realCategoriesLanes)
    }

    const onCreateCategory = async (name) => {
      await createCategory(name)
      refreshCategories()
    }

    const onRenameCategory = async ({ id, name }) => {
      if (id != OTHER_CATEGORY_LANE_ID && id != WALKTHROUGH_CATEGORY_LANE_ID) {
        //not other category
        await renameCategory({ id, name })
        refreshCategories()
      } else {
        setShowDialogMisc(true)
      }
      //TODO: disable lane rename
    }

    const onCreateTagOnCategory = async ({ tagName, categoryId }) => {
      const tag = await addOrgTag({ tag: tagName })
      if (categoryId != OTHER_CATEGORY_LANE_ID) {
        //not other category
        await addTagToCategoryById(tag._id, categoryId)
      }
      refreshCategories()
    }

    const onChangeTagCategory = async ({
      cardId,
      sourceLaneId,
      targetLaneId,
      position,
      cardDetails,
    }) => {
      //don't do this on walktrough lane
      if (
        sourceLaneId === WALKTHROUGH_CATEGORY_LANE_ID ||
        targetLaneId === WALKTHROUGH_CATEGORY_LANE_ID
      ) {
        refreshCategories()
        return
      }

      if (sourceLaneId != targetLaneId) {
        if (
          sourceLaneId != OTHER_CATEGORY_LANE_ID &&
          targetLaneId != OTHER_CATEGORY_LANE_ID
        ) {
          //handle change category:

          //remove from sourceLane(category)
          await toggleTagCategory(cardId, sourceLaneId)
          //and add to new targetLane(category)
          await addTagToCategoryById(cardId, targetLaneId)
        } else if (
          sourceLaneId == OTHER_CATEGORY_LANE_ID &&
          targetLaneId != OTHER_CATEGORY_LANE_ID
        ) {
          //just add to a category
          await addTagToCategoryById(cardId, targetLaneId)
        } else if (
          sourceLaneId != OTHER_CATEGORY_LANE_ID &&
          targetLaneId == OTHER_CATEGORY_LANE_ID
        ) {
          //just need to remove from categoryId
          await toggleTagCategory(cardId, sourceLaneId)
        }
        refreshCategories() // Guy: I think this is causing a UI glitch and is actually not needed, but keeping just in case.
      } else {
        //TODO handle reorder tag in the same category
      }
    }

    const handleMoveCat = async ({ removedIndex, addedIndex, categoryId }) => {
      var newOrder

      if (addedIndex == 0) {
        //first category
        newOrder = data.lanes[addedIndex].order - 1
      } else if (addedIndex == data.lanes.length - 1) {
        //last category
        newOrder = data.lanes[addedIndex].order + 1
      } else {
        //somewhere in between - be in the middle
        if (removedIndex > addedIndex) {
          //left
          newOrder =
            (data.lanes[addedIndex].order + data.lanes[addedIndex - 1].order) /
            2
        } else {
          //right
          newOrder =
            (data.lanes[addedIndex].order + data.lanes[addedIndex + 1].order) /
            2
        }
      }

      //this is the other category and walktrough category
      if (
        FORBIDDEN_IDS.includes(data.lanes[addedIndex].id) ||
        FORBIDDEN_IDS.includes(data.lanes[removedIndex].id)
      ) {
        setShowDialogMisc(true)
      } else {
        await reorderCategory(categoryId, newOrder)
      }
      refreshCategories()
    }

    const onDeleteTag = async (id) => {
      //TODO add a pop-up for deleting
      setShowDialogDelete(false)
      await DeleteTags([id])
      setDeleteCardId(null)
      refreshCategories()
    }

    const onRenameTag = async () => {
      setShowDialogRename(false)
      await renameTag({ id: renameCardId, name: renameName })
      setRenameCardId(null)
      setRenameName(null)
      refreshCategories()
    }

    const refreshCategories = () => {
      getAllTags({})
    }

    return (
      <div className="tag-mgmt-container">
        <div className="tag-mgmt-header">
          <h1 className="h1">
            {' '}
            <Trans>Manage tags</Trans>
          </h1>
          {/* <p className="p light"></p> */}
          <p className="p">
            <Trans>
              Group your tags and organize categories using drag and drop.
            </Trans>
          </p>
        </div>
        <div className="tag-mgmt-content-wrapper">
          <Board
            components={components}
            //style={{backgroundColor: "#F1EFEE"}}
            addCardLink="ADD CARD"
            data={data}
            draggable
            //laneDraggable={false}
            editable
            canAddLanes
            editLaneTitle
            onLaneAdd={(event) => onCreateCategory(event.title)}
            onLaneUpdate={(laneId, data) =>
              onRenameCategory({ id: laneId, name: data.title })
            }
            onCardAdd={(card, laneId) =>
              onCreateTagOnCategory({ tagName: card.title, categoryId: laneId })
            }
            handleDragEnd={(
              cardId,
              sourceLaneId,
              targetLaneId,
              position,
              cardDetails
            ) =>
              onChangeTagCategory({
                cardId,
                sourceLaneId,
                targetLaneId,
                position,
                cardDetails,
              })
            } //check if it's a new lane and update categories
            handleLaneDragEnd={(removedIndex, addedIndex, payload) =>
              handleMoveCat({
                removedIndex,
                addedIndex,
                categoryId: payload.id,
              })
            }
            onCardDelete={(cardId, laneId) => {
              setShowDialogDelete(true)
              setDeleteCardId(cardId)
            }}
            onCardUpdate={(laneId, card) => {
              setShowDialogRename(true)
              setRenameCardId(card.id)
              setRenameName(card.title)
            }}
          ></Board>
        </div>

        {showDialogDelete === true && (
          <ActionDialog
            actionName={t`Delete`}
            action={() => onDeleteTag(deleteCardId)}
            cancel={() => setShowDialogDelete(false)}
            question={t`Delete Tag?`}
            comment={t`You're about to permanently delete this tag, it will be permanently remove from all related files.`}
            color={'warning'}
            isOpen={showDialogDelete}
          />
        )}
        {showDialogRename === true && (
          <ActionDialog
            actionName={t`Rename tag`}
            action={() => onRenameTag()}
            cancel={() => setShowDialogRename(false)}
            question={t`Rename Tag?`}
            comment={t`Please note that renaming a tag will apply to all files tagged with that tag.`}
            isOpen={showDialogRename}
          />
        )}
        <ActionDialog
          actionName={t`Okay`}
          action={() => setShowDialogMisc(false)}
          //cancel={() => setShowDialogMisc(false)}
          question={t`Cannot change "Miscellaneous"`}
          comment={t`This column will hold any new tag you create until you assign it to a category. If empty, it will not show up in the filters.`}
          isOpen={showDialogMisc}
        />
      </div>
    )
  }
)

export default TagsManagment
